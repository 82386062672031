import { Publisher } from "../declarations/pages";
import { getAxiosInstance } from "./api";

export const getPublishers = async (
  value,
  type,
  limit = 10,
  start = 0,
  sort = ""
) => {
  type === "" ? (type = "name") : type;
  const axios = getAxiosInstance();
  const query = value && type ? `&q=${type}:${value}` : "";

  try {
    const { data } = await axios({
      method: "GET",
      url: `/publishers?limit=${limit}&start=${start}${query}&sort=${sort}`,
    });
    return data;
  } catch (error) {
    console.error("[getPublishers] error", error);
    return error.response.status;
  }
};

export const getCurrentPublisher = async () => {
  const axios = getAxiosInstance();

  const response = await axios.get<Publisher>("/publishers/me");
  return response.data;
};

export const createPublisher = async (data) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "POST",
      url: `/publishers`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    console.error("[createPublisher] error", error.response);
    return error.response.status;
  }
};

export const editPublisher = async (data) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "PUT",
      url: `/publishers/${data.id}`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    console.error("[editPublisher] error", error.response.data);
    return error.response.status;
  }
};

export const deletePublishers = async (data) => {
  const axios = getAxiosInstance();

  try {
    await axios({
      method: "DELETE",
      url: `/publishers/${data.id}`,
    });
    return true;
  } catch (error) {
    console.error("[deletePublishers] error", error.response.data);
    return error.response.status;
  }
};

export const getPublisher = async (id) => {
  const axios = getAxiosInstance();

  try {
    const { data } = await axios({
      method: "GET",
      url: `/publishers/${id}`,
    });
    return data;
  } catch (error) {
    console.error("[getPublisher] error", error.response);
    return error.response.status;
  }
};

export const getChannels = async (id, value, limit = 10, start = 0, sort) => {
  const axios = getAxiosInstance();
  const query = value ? `&q=name:${value}` : "";

  try {
    const { data } = await axios({
      method: "GET",
      url: `channels/publisher/${id}?limit=${limit}&start=${start}${query}&sort=${sort}`,
    });
    return data;
  } catch (error) {
    console.error("[getChannels] error", error);
    return error.response.status;
  }
};

export const getOwnChannels = async (value, limit = 10, start = 0) => {
  const axios = getAxiosInstance();
  const query = value ? `&q=name:${value}` : "";

  try {
    const { data } = await axios({
      method: "GET",
      url: `channels/publisher-own-channel?limit=${limit}&start=${start}${query}`,
    });
    return data;
  } catch (error) {
    console.error("[getOwnChannels] error", error);
    return error.response.status;
  }
};
