import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { RootState } from "../../init/rootReducer";
import MuiAlert from "@material-ui/lab/Alert";
import { getLanguage } from "../../languages";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { RotateIcon } from "../../assets/image/rotate-icon";
import { SaveIcon } from "../../assets/image/save-icon";
import { createChannel } from "../../api/chanels";
import { getRights } from "../../api/rights";
import { getPublishers } from "../../api/publishers";
import { getErrorsHandler } from "../../errors/channels";
import { useStyles } from "./hooks/useStyles";
import { MISSING_FIELD } from "../../init/constants";

export const CreateChannel = () => {
  const classes = useStyles();
  const history = useHistory();
  const [urlLng, setUrlLng] = useState("/en");
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);
  const { location } = useHistory();
  const [values, setValues] = useState({
    name: "",
    description: "",
    language: "en",
  });
  const dispatch = useDispatch();
  const { isAdmin } = useSelector((state: RootState) => state.user);

  const languages = [
    { value: "ar", title: "Arabic" },
    { value: "nl", title: "Dutch" },
    { value: "en", title: "English" },
    { value: "fr", title: "French" },
    { value: "de", title: "German" },
    { value: "it", title: "Italian" },
    { value: "pt", title: "Portugese" },
    { value: "es", title: "Spanish" },
    { value: "es-mx", title: "Spanish (LATAM)" },
  ];

  useEffect(() => {
    getDataRights();
    getDataPublishers();
    dispatch(userActions.setLink("channels"));
    if (!isAdmin) history.push(`${urlLng}/channels`);
  }, []);

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  const onSubmit = async () => {
    if (!values.name) {
      setDisable(true);
    } else {
      const response = await createChannel({
        ...values,
        publisherCreator: null, // TODO waiting for the confirmation
        rights: rightRights,
        publishers: rightPublishers,
      });
      if (typeof response === "number") {
        setOpenAlert(true);
        setError(getErrorsHandler(response).title);
      } else {
        history.push(`${urlLng}/channels`);
      }
    }
  };

  //------------------------

  function not(a, b) {
    return a.filter(
      (value) => b.findIndex((item) => item.id === value.id) === -1
    );
  }

  function intersection(a, b) {
    return a.filter(
      (value) => b.findIndex((item) => item.id === value.id) !== -1
    );
  }

  const [checkedRights, setCheckedRights] = useState([]);
  const [checkedPublishers, setCheckedPublishers] = useState([]);
  const [leftRights, setLeftRights] = useState([]);
  const [rightRights, setRightRights] = useState([]);

  const [leftPublishers, setLeftPublishers] = useState([]);
  const [rightPublishers, setRightPublishers] = useState([]);

  const leftCheckedRights = intersection(checkedRights, leftRights);
  const rightCheckedRights = intersection(checkedRights, rightRights);

  const leftCheckedPublishers = intersection(checkedPublishers, leftPublishers);
  const rightCheckedPublishers = intersection(
    checkedPublishers,
    rightPublishers
  );

  const handleToggleRights = (value) => () => {
    const currentIndex = checkedRights.findIndex(
      (item) => item.id === value.id
    );
    const newChecked = [...checkedRights];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedRights(newChecked);
  };

  const handleTogglePublishers = (value) => () => {
    const currentIndex = checkedPublishers.findIndex(
      (item) => item.id === value.id
    );
    const newChecked = [...checkedPublishers];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedPublishers(newChecked);
  };

  const handleCheckedRightRights = () => {
    setRightRights(rightRights.concat(leftCheckedRights));
    setLeftRights(not(leftRights, leftCheckedRights));
    setCheckedRights(not(checkedRights, leftCheckedRights));
  };

  const handleCheckedRightPublishers = () => {
    setRightPublishers(rightPublishers.concat(leftCheckedPublishers));
    setLeftPublishers(not(leftPublishers, leftCheckedPublishers));
    setCheckedPublishers(not(checkedPublishers, leftCheckedPublishers));
  };

  const handleCheckedLeftRights = () => {
    setLeftRights(leftRights.concat(rightCheckedRights));
    setRightRights(not(rightRights, rightCheckedRights));
    setCheckedRights(not(checkedRights, rightCheckedRights));
  };

  const handleCheckedLeftPublishers = () => {
    setLeftPublishers(leftPublishers.concat(rightCheckedPublishers));
    setRightPublishers(not(rightPublishers, rightCheckedPublishers));
    setCheckedPublishers(not(checkedPublishers, rightCheckedPublishers));
  };

  const handleAllRightPublishers = () => {
    setRightPublishers(rightPublishers.concat(leftPublishers));
    setLeftPublishers([]);
  };

  const getDataRights = async () => {
    const response = await getRights(null, null, 1000000);
    if (typeof response !== "number") {
      setLeftRights(response.rights);
    } else {
      const error = await getErrorsHandler(response);
      console.error(error);
    }
  };

  const getDataPublishers = async () => {
    const response = await getPublishers(null, null, 1000);
    if (typeof response !== "number") {
      setLeftPublishers(response.publishers);
    } else {
      const error = await getErrorsHandler(response);
      console.error(error);
    }
  };
  const resetData = async () => {
    const resetValues = {
      name: "",
      description: "",
      language: "en",
    };
    setRightRights([]);
    setRightPublishers([]);
    setValues({ ...resetValues });
  };

  const deleteAndBack = () => {
    resetData();
    history.push(`${urlLng}/channels`);
  };

  const customList = (items, name, cheked, handleToggle) => (
    <>
      <InputLabel
        className={classes.labelPaper}
        id="demo-simple-select-outlined-label"
      >
        {name}
      </InputLabel>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value.id}-label`;
            const isChecked =
              cheked.findIndex((item) => item.id === value.id) !== -1;
            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={isChecked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  className={isChecked ? classes.active : ""}
                  id={labelId}
                  primary={value.name ? value.name : value.title}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </>
  );

  //------------------------

  return (
    <section className={classes.playlist}>
      <Link to={`${urlLng}/channels`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to channels
      </Link>
      <div className={classes.heading}>
        <h1 className={classes.title}>Create Channel</h1>
        <div>
          <Button className={`button-white`} onClick={resetData}>
            <RotateIcon />
          </Button>

          <Button className={`button-white`} onClick={deleteAndBack}>
            <DeleteIcon />
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.inputs}>
          <TextField
            className={classes.input}
            id="title"
            label="Title *"
            variant="outlined"
            onChange={(e) => handleChange("name", e)}
            value={values.name}
            error={disable && !values.name}
            helperText={disable && !values.name ? MISSING_FIELD : null}
            inputProps={{ maxLength: 40 }}
          />
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Language
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Language"
              onChange={(e) => handleChange("language", e)}
              value={values.language}
            >
              {languages.map((language) => (
                <MenuItem key={language.value} value={language.value}>
                  {language.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <TextField
          id="short-description"
          label="Short Description"
          multiline
          rows={4}
          variant="outlined"
          onChange={(e) => handleChange("description", e)}
          className={classes.input}
          inputProps={{ maxLength: 250 }}
          value={values.description}
        />
        <h1 className={classes.subTitle}>Rights</h1>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid className={classes.grid} item>
            {customList(leftRights, "Available Rights", checkedRights, (e) =>
              handleToggleRights(e)
            )}
          </Grid>

          <Grid className={classes.grid} item>
            {customList(rightRights, "Selected Rights", checkedRights, (e) =>
              handleToggleRights(e)
            )}
          </Grid>

          <Button
            variant="outlined"
            size="small"
            className={`${classes.mediumButton} button`}
            onClick={handleCheckedRightRights}
          >
            Add Selected
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={`${classes.mediumButton}  button-white`}
            onClick={handleCheckedLeftRights}
          >
            Remove
          </Button>
        </Grid>
        <h1 className={classes.subTitle}>Publishers</h1>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid className={classes.grid} item>
            {customList(
              leftPublishers,
              "Available Publishers",
              checkedPublishers,
              (e) => handleTogglePublishers(e)
            )}
          </Grid>

          <Grid className={classes.grid} item>
            {customList(
              rightPublishers,
              "Selected Publishers",
              checkedPublishers,
              (e) => handleTogglePublishers(e)
            )}
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              size="small"
              className={`${classes.mediumSeparateButton} button`}
              onClick={handleCheckedRightPublishers}
            >
              Add Selected
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={`${classes.mediumSeparateButton} button-white`}
              onClick={handleAllRightPublishers}
              aria-label="move all right"
            >
              Select All
            </Button>
          </div>
          <Button
            variant="outlined"
            size="small"
            className={`${classes.mediumButton} button-white`}
            onClick={handleCheckedLeftPublishers}
          >
            Remove
          </Button>
        </Grid>

        <div className={classes.root}>
          <Button
            className={`${classes.bigButton} button-white`}
            onClick={() => history.push(`${urlLng}/channels`)}
          >
            Cancel
          </Button>
          <Button
            className={`${classes.bigButton} button`}
            onClick={() => onSubmit()}
          >
            Save
          </Button>
        </div>
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
    </section>
  );
};
