import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { getLanguage } from "../../languages";

import { useStyles } from "./hooks/useStyles";

export const NotFoundPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { location } = history;

  const [urlLng, setUrlLng] = useState("/en");

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  return (
    <section className={classes.content}>
      <h1 className={classes.title}>404</h1>
      <h4 className={classes.subTitle}>That page doesn&apos;t exist</h4>
      <span className={classes.text}>
        Sorry, the page you were looking for could not be found.
      </span>
      <Link to={urlLng} className={`button-white`}>
        Go to Content Page{" "}
      </Link>
    </section>
  );
};
