import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  playlist: {
    padding: "33px 44px",
  },
  snackbar: {
    display: "block",
    top: 15,
  },
  heading: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  searching: {
    display: "flex",
  },
  select: {
    width: "256px",
    margin: "0 25px",
  },
  button: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    display: "flex",
    justifyContent: "space-between",
    width: "100px",

    "&:hover": {
      borderRadius: "0px",
    },
  },
  img: {
    width: 71,
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  "@media screen and (min-width: 768px)": {
    playlist: {
      padding: "33px 44px",
    },
  },
}));
