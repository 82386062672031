// @ts-ignore
// @ts-nocheck
import React, { useImperativeHandle, useRef } from "react";
import { DragSource, DropTarget } from "react-dnd";
import ItemTypes from "./ItemTypes";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { DeleteIcon } from "../../assets/image/delete-icon";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { SMALL_DEFAULT_IMAGE } from "../../init/constants";
import { EditIcon } from "../../assets/image/edit-icon";
import moment from "moment";
// eslint-disable-next-line react/display-name
const Card = React.forwardRef(
  (
    {
      cardInfo,
      isDragging,
      connectDragSource,
      connectDropTarget,
      index,
      isAdmin,
      handleClick,
      deletePlaylistVideo,
      setId,
      setOpenPlaylistModal,
      getPlayList,
      anchorEl,
      openEmbedCodeModal,
      handleClose,
      urlLng,
      setVideo,
    },
    ref
  ) => {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));

    return (
      <TableRow ref={elementRef} styles={opacity}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          <img src={SMALL_DEFAULT_IMAGE} alt="error" />
        </TableCell>
        <TableCell>{cardInfo.name}</TableCell>
        <TableCell>{cardInfo.status ? cardInfo.status : "Active"}</TableCell>
        <TableCell>{cardInfo.id}</TableCell>
        <TableCell>
          {moment(cardInfo.createdDate).format("DD/MM/yyyy, h:mm A")}
        </TableCell>
        <TableCell>
          {moment(cardInfo.updatedDate).format("DD/MM/yyyy, h:mm A")}
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() =>
              window.location.assign(
                `${window.location.origin}${urlLng}/all-videos/${cardInfo.id}/edit`
              )
            }
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        {isAdmin && (
          <TableCell>
            <IconButton onClick={() => deletePlaylistVideo(cardInfo)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
        <TableCell>
          <IconButton
            onClick={(e) => {
              handleClick(e, cardInfo), setId(cardInfo.id), setVideo(cardInfo);
            }}
          >
            <MoreVertIcon
              aria-controls={"simple-menu-" + index}
              aria-haspopup="true"
            />
          </IconButton>
          <Menu
            id={"simple-menu-" + index}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setOpenPlaylistModal(true), getPlayList("", "", 5, 0);
              }}
            >
              Add to a playlist
            </MenuItem>
            <MenuItem onClick={() => openEmbedCodeModal()}>
              Create embed code
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    );
  }
);

export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
    drop(props, monitor) {
      const hoverIndex = props.index;
      props.onDrop(hoverIndex);
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(Card)
);
