import React from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import { DeleteIcon } from "../../assets/image/delete-icon";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../init/rootReducer";

import { SMALL_DEFAULT_IMAGE } from "../../init/constants";

export const SemanticArticlesPlaylistTable = ({ onDeleteVideo }: any) => {
  const classes = useStyles();

  const { videos } = useSelector((state: RootState) => state.semanticArticle);

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>Pos</TableCell>
              <TableCell className={classes.title}> </TableCell>
              <TableCell className={classes.title}>Name</TableCell>
              <TableCell className={classes.title}>Status</TableCell>
              <TableCell className={classes.title}>ID</TableCell>
              <TableCell className={classes.title}>Published</TableCell>
              <TableCell className={classes.title}>Created</TableCell>
              <TableCell className={classes.title}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {videos &&
              videos.map((row, index) => (
                <TableRow key={index}>
                  <TableCell width={"3%"} className={classes.row}>
                    {index + 1}
                  </TableCell>
                  <TableCell width="5%" className={classes.row}>
                    <img
                      className={classes.img}
                      src={SMALL_DEFAULT_IMAGE}
                      alt="error"
                    />
                  </TableCell>
                  <TableCell width="30%" className={classes.row}>
                    {row.name}
                  </TableCell>
                  <TableCell width="10%" className={classes.row}>
                    {row.status}
                  </TableCell>
                  <TableCell width="10%" className={classes.row}>
                    {row.id}
                  </TableCell>
                  <TableCell width="15%" className={classes.row}>
                    {moment(row.publishedTime).format("DD/MM/yyyy, h:mm A")}
                  </TableCell>
                  <TableCell width="15%" className={classes.row}>
                    {moment(row.createdDate).format("DD/MM/yyyy, h:mm A")}
                  </TableCell>

                  <TableCell width="5%" className={classes.row}>
                    <IconButton onClick={() => onDeleteVideo(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
