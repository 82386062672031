import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ChipInput from "material-ui-chip-input";
import {
  IconButton,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { MISSING_FIELD } from "../../init/constants";
import { useStyles } from "./hooks/useStyles";

interface State {
  name: string;
  tags: Array<string>;
  tagsType: string;
  type: string;
  description: string;
}

const initialState = {
  name: "",
  tags: [],
  tagsType: "",
  type: "MANUAL",
  description: "",
};

export const PlaylistsModal = ({
  open,
  setOpen,
  edit = false,
  playlistType,
  createPlaylist,
  data = initialState,
  onEdit,
}: any) => {
  const classes = useStyles();
  const [values, setValues] = useState<State>({
    name: "",
    tags: [],
    tagsType: "",
    type: "MANUAL",
    description: "",
  });
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [type, setType] = useState("MANUAL");

  useEffect(() => {
    setType(playlistType);
  }, [playlistType]);

  useEffect(() => {
    setValues({
      ...data,
      tags: typeof data.tags === "string" ? data.tags.split(",") : data.tags,
    });
  }, [data, open]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      isError && setIsError(false);
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleAddChip = (chip) => {
    setValues({ ...values, tags: [...values.tags, chip] });
  };

  const handleDeleteChip = (chip, index) => {
    setValues({ ...values, tags: values.tags.filter((item) => item !== chip) });
  };

  const onSubmit = async (data) => {
    if (!values.name) {
      setDisable(true);
    } else {
      const res = await createPlaylist({ ...data, tags: data.tags.join() });
      if (res.isError) {
        setOpen(false);
        setValues(initialState);
      } else {
        setError(res.error);
        setIsError(true);
      }
    }
  };

  const editPlaylist = async () => {
    if (!values.name) {
      setDisable(true);
    } else {
      onEdit(values);
    }
  };

  const onClose = () => {
    setOpen(false);
    !edit && setValues(initialState);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          {edit ? "Edit" : "Create"} Playlist
          <IconButton aria-label="close" onClick={() => onClose()}>
            <HighlightOffIcon />
          </IconButton>
        </h2>
        {edit && (
          <>
            <p className={classes.tagBold}>Type</p>
            <p className={classes.tag}>{values.type}</p>
          </>
        )}
        <form noValidate autoComplete="off" className={classes.form}>
          {!edit && (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Type"
                name="type"
                value={values.type}
                onChange={handleChange("type")}
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value="MANUAL"
                  control={<Radio />}
                  label="Manual"
                />
                <FormControlLabel
                  value="SMART"
                  control={<Radio />}
                  label="Smart (based on tags)"
                />
              </RadioGroup>
            </FormControl>
          )}

          {values.type === "MANUAL" ? (
            <div>
              <TextField
                className={classes.input}
                variant="outlined"
                id="name"
                label="Name *"
                fullWidth
                onChange={handleChange("name")}
                value={values.name}
                inputProps={{ maxLength: 255 }}
                error={disable && !values.name}
                helperText={disable && !values.name ? MISSING_FIELD : null}
              />
              <TextField
                id="short-description"
                label="Short Description"
                multiline
                rows={4}
                variant="outlined"
                className={classes.input}
                onChange={handleChange("description")}
                value={values.description}
                inputProps={{ maxLength: 255 }}
              />
            </div>
          ) : (
            <div>
              <TextField
                className={classes.input}
                id="name"
                label="Name *"
                value={values.name}
                onChange={handleChange("name")}
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                error={disable && !values.name}
                helperText={disable && !values.name ? MISSING_FIELD : null}
              />
              <TextField
                id="short-description"
                label="Short Description"
                multiline
                rows={4}
                variant="outlined"
                value={values.description}
                onChange={handleChange("description")}
                className={classes.input}
                inputProps={{ maxLength: 255 }}
              />
              <h4 className={classes.subTitle}>Tags</h4>
              <FormControl variant="outlined" className={classes.input}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Relationship
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Relationship"
                  onChange={handleChange("tagsType")}
                  value={values.tagsType}
                >
                  <MenuItem value="CONTAINS_ONE_OR_MORE">
                    Contains one or more
                  </MenuItem>
                  <MenuItem value="CONTAINS_ALL">Contains all</MenuItem>
                </Select>
              </FormControl>
              <ChipInput
                className={classes.input}
                fullWidth
                variant="outlined"
                label="Tags"
                value={typeof values.tags === "string" ? [] : values.tags}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
              />
            </div>
          )}
          {isError && <Alert severity="error">{error}</Alert>}
          <div className={classes.buttons}>
            <Button className={classes.buttonCancel} onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              className={`${classes.button} button`}
              onClick={() => (edit ? editPlaylist() : onSubmit(values))}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
