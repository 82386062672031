import React, { useState, useEffect, FC } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import IconButton from "@material-ui/core/IconButton";
import { Link, useHistory } from "react-router-dom";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { PublishIcon } from "../../assets/image/publish-icon";
import { SortArray } from "../../components/sorter/sorter";
import { getLanguage } from "../../languages";
import moment from "moment";
import { Playlist } from "../../declarations/pages";
import { SORT_DEFAULT_ICON_NAME } from "../../init/constants";
interface Props {
  data: Playlist[];
  modalDelete: any;
  search?: string;
  onDelete: any;
  onSubmit: any;
  isPublisher: boolean;
  resetShort?: string;
  updateData?: (value: string) => void;
}

export const PlaylistsTable: FC<Props> = ({
  data,
  modalDelete,
  onDelete,
  onSubmit,
  isPublisher,
  updateData,
  resetShort = "",
}) => {
  const classes = useStyles();
  const [sort, setSort] = useState(resetShort);
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT_ICON_NAME);

  const { location } = useHistory();
  const [urlLng, setUrlLng] = useState("/en");

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.title}>
              Name
              <SortArray
                sort={sort}
                type="name"
                setSort={(e) => {
                  updateData(e && `name:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}>
              Type
              <SortArray
                sort={sort}
                type="type"
                setSort={(e) => {
                  updateData(e && `type:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}>ID</TableCell>
            <TableCell className={classes.title}>
              Updated
              <SortArray
                sort={sort}
                type="updatedDate"
                setSort={(e) => {
                  updateData(e && `updatedDate:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}></TableCell>
            <TableCell className={classes.title}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.line}>
          {data.map((row: Playlist, index) => (
            <TableRow key={index}>
              <TableCell className={classes.row} width="30%">
                <Link
                  className={classes.link}
                  to={`${urlLng}/playlists/${row.id}`}
                >
                  {row.name}
                </Link>
              </TableCell>
              <TableCell className={classes.row} width="15%">
                {row.type}
              </TableCell>
              <TableCell className={classes.row} width="20%">
                {row.id}
              </TableCell>
              <TableCell className={classes.row} width="20%">
                {row.updatedDate
                  ? moment(row.updatedDate).format("DD/MM/yyyy, LT")
                  : ""}
              </TableCell>
              <TableCell className={classes.row} width="5%">
                {isPublisher && (
                  <IconButton onClick={() => onSubmit(row)}>
                    <PublishIcon />
                  </IconButton>
                )}
              </TableCell>
              <TableCell className={classes.row} width="5%">
                <IconButton
                  onClick={() => {
                    onDelete(row), modalDelete(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
