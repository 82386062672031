import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton, Button, TextField } from "@material-ui/core";

import { useStyles } from "./hooks/useStyles";

export const RevertChanges = ({ open, setOpen, onSubmit, data }: any) => {
  const classes = useStyles();

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          Discard Changes for {data.name}
          <IconButton aria-label="close" onClick={() => onClose()}>
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <form noValidate autoComplete="off" className={classes.form}>
          <div>
            Are you sure you want to discard your changes? All unpublished
            changes will be lost.
          </div>
          <div className={classes.buttons}>
            <Button className={classes.button} onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              className={`${classes.button} button`}
              variant="contained"
              onClick={() => onSubmit()}
            >
              Discard
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
