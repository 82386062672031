import React, { useEffect, useState } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import BlockIcon from "@material-ui/icons/Block";
import { EditIcon } from "../../assets/image/edit-icon";
import IconButton from "@material-ui/core/IconButton";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { SortArray } from "../../components/sorter/sorter";
import { codeLangToStrDic } from "../../languages";
const SORT_DEFAULT = "ASCgiven_name";
export const UserTable = ({
  data,
  openModalType,
  handleBlockUser,
  handleDeleteUser,
  updateData,
  resetShort = "",
}: any) => {
  const classes = useStyles();
  const [sort, setSort] = useState(resetShort);
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT);

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.title} width="30%">
              Name
              <SortArray
                sort={sort}
                type="given_name"
                setSort={(e) => {
                  updateData(e && `given_name:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title} width="20%">
              Publisher
              <SortArray
                sort={sort}
                type="publisher"
                setSort={(e) => {
                  updateData(e && `publisher:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title} width="25%">
              Email
            </TableCell>
            <TableCell className={classes.title} width="10%">
              Language
              <SortArray
                sort={sort}
                type="language"
                setSort={(e) => {
                  updateData(e && `language:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title} width="5%"></TableCell>
            <TableCell className={classes.title} width="5%"></TableCell>
            <TableCell className={classes.title} width="5%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.line}>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.row}>
                {row.givenName} {row.familyName}
              </TableCell>
              <TableCell className={classes.row}>{row.publisher}</TableCell>
              <TableCell className={classes.row}>{row.email}</TableCell>
              <TableCell className={classes.row}>
                {codeLangToStrDic[row.language]}
              </TableCell>
              <TableCell className={classes.row}>
                <IconButton onClick={() => openModalType(row)}>
                  <EditIcon />
                </IconButton>
              </TableCell>
              <TableCell className={classes.row}>
                <IconButton onClick={() => handleBlockUser(row)}>
                  <BlockIcon
                    className={classes.btn}
                    style={{ color: row.blocked ? "red" : "black" }}
                  />
                </IconButton>
              </TableCell>
              <TableCell className={classes.row}>
                <IconButton onClick={() => handleDeleteUser(row)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
