import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
    marginTop: "30px",
    marginBottom: "40px",
  },
  line: {
    "& tr:nth-child(odd)": {
      backgroundColor: "#F5F5F5",
    },
  },
  btn: {
    color: "#000",
  },
});
