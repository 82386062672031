import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Paper, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { getLanguage } from "../../languages";
import { getErrorsHandler } from "../../errors/rights";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { EditIcon } from "../../assets/image/edit-icon";
import { deleteRights } from "../../api/rights";
import { useStyles } from "./hooks/useStyles";
import { getRight } from "../../api/rights";
import { DeleteModal } from "../../components/delete-modal";
import countriesList from "../../assets/countries/countries.json";

import moment from "moment";

function PaperComponent(props) {
  return <Paper {...props} />;
}

export const RightReadOnly = () => {
  const classes = useStyles();
  const [urlLng, setUrlLng] = useState("/en");
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    allowToOverride: false,
    availability: {
      publishDate: null,
      publishDateOffsetHours: 0,
      takedownDate: null,
      takedownOffsetDays: 0,
    },
    blacklistedDomains: [],
    brightcoveRestrictionId: "0",
    countries: [],
    filtering: 0,
    id: "",
    rightsHolder: { id: "", title: "" },
    rightsHolderId: "",
    title: "",
    whitelistedDomains: [],
  });
  const [filter, setFilter] = useState([
    "Unrestricted",
    "Allow viewing from the following countries",
    "Prevent viewing from the following countries",
  ]);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { location } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getData(id);

    dispatch(userActions.setLink("rights"));
  }, []);

  useEffect(() => {
    const language = getLanguage(location.pathname);

    setUrlLng(language);
  }, [location]);

  const getData = async (id) => {
    const response = await getRight(id);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setData(response);
    }
  };

  const deleteData = async (data) => {
    const response = await deleteRights(id);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenDialog(false);
      setOpenAlertSuccess(true);
      history.push(`${urlLng}/rights`);
    }
  };

  return (
    <section className={classes.playlist}>
      <Link to={`${urlLng}/rights`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Rights
      </Link>
      <div className={classes.heading}>
        <div className={classes.info}>
          <h1 className={classes.title}>{data.title}</h1>
        </div>
        <div className={classes.buttons}>
          <Link
            className={classes.link}
            to={`${urlLng}/rights/${data.id}/edit`}
          >
            <EditIcon />
          </Link>
          <Button
            className={`button-white`}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.block}>
          <span className={classes.information}>
            <strong>Media Right Title*</strong>
            {data.title}
          </span>
          <span className={classes.information}>
            <strong>Rightsholder</strong>
            {data.rightsHolder?.title || "None"}
          </span>
          <span className={classes.information}>
            <strong>Allow Superadmin to override</strong>
            {data.allowToOverride ? "Yes" : "No"}
          </span>
        </div>
        <h2 className={classes.subTitle}>Publish</h2>
        <div className={classes.block}>
          <span className={classes.information}>
            <strong>Scheduled Start Date</strong>
            {data.availability.publishDate
              ? moment(data.availability.publishDate).format(
                  "MMMM Do YYYY, h:mm a"
                )
              : "Same as created date"}
          </span>
          <span className={classes.information}>
            <strong>Scheduled End Date</strong>
            {data.availability.takedownDate
              ? moment(data.availability.takedownDate).format(
                  "MMMM Do YYYY, h:mm a"
                )
              : "No End Date"}
          </span>
        </div>
        <h2 className={classes.subTitle}>Geo Filtering</h2>
        <div className={classes.block}>
          <span className={classes.information}>
            <strong>Filtering Options</strong>
            {filter[data.filtering]}
          </span>
          <span className={classes.information}>
            <strong>Countries</strong>
            {data?.countries
              .sort()
              .map((el) => countriesList[el])
              .filter((el) => el)
              .join(", ")}
          </span>
        </div>
        <h2 className={classes.subTitle}>Domain Restriction</h2>
        <span className={classes.information}>
          Domain restrictions allow the user to limit video playback based upon
          domain.
        </span>
        <div className={classes.block}>
          {data.blacklistedDomains && (
            <span className={classes.information}>
              <strong>Blacklisted Domains</strong>
              {data.blacklistedDomains.join(", ")}
            </span>
          )}
          {data.whitelistedDomains && (
            <span className={classes.information}>
              <strong>Whitelisted Domains</strong>
              {data.whitelistedDomains.join(", ")}
            </span>
          )}
        </div>
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
      >
        <MuiAlert severity="success">Right deleted.</MuiAlert>
      </Snackbar>
      <DeleteModal
        open={openDialog}
        setOpen={setOpenDialog}
        data={data}
        onDelete={(data) => deleteData(data)}
        header="Right"
        right="this right"
      />
    </section>
  );
};
