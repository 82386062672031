import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  table: {
    marginTop: 30,
    marginBottom: "25px",
    boxShadow: "none",
  },
  shroud: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: 1000,
    background: "#FFFFFF",
    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.25)",
    padding: "32px 32px 48px",

    "& > * + *": {
      marginTop: 20,
    },
  },
  buttonReset: {
    "&:hover": {
      background: "#fff",
    },
    fontSize: 16,
    "& > span": {
      marginTop: 10,
    },
    "& svg": {
      marginRight: 10,
    },
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0",
  },
  button: {
    width: 160,
    marginRight: 32,
    color: "#000 !important",
    backgroundColor: "#E4E4E4 !important",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  buttonBlack: {
    width: 160,
    marginRight: 32,
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    border: "none",
  },
  row: {
    fontFamily: "Arial",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "15px",
    color: "#000000",
    border: "none",
  },
  line: {
    "& tr:nth-child(odd)": {
      backgroundColor: "#F5F5F5",
    },
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowActive: {
    cursor: "pointer",
    background: "#c3c3c3 !important",
  },
});
