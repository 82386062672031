import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import { getLanguage, languageRightList } from "../../languages";
import { SaveIcon } from "../../assets/image/save-icon";
import { RotateIcon } from "../../assets/image/rotate-icon";
import { getRightSholders } from "../../api/rightsholders";
import { createRights } from "../../api/rights";
import { getErrorsHandler } from "../../errors/rights";
import { GeoFiltering } from "../../init/constants";
import { DeleteModal } from "../../components/delete-modal";
import { useStyles } from "./hooks/useStyles";
import data from "../../assets/countries/countries.json";
import { getCountryShortName } from "../../utils/countriesHelper";

const initalSateValues = {
  description: "",
  allowToOverride: false,
  blacklistedDomains: [],
  brightcoveRestrictionId: "0",
  countries: [],
  filtering: GeoFiltering.Unrestricted,
  rightsHolderId: "",
  language: { id: "", name: "" },
  title: "",
  whitelistedDomains: [],
  takedownOffsetDays: undefined,
};

const initialAvailability = {
  allowToOverrideAvailability: false,
  publishDate: null,
  publishDateOffsetHours: 0,
  takedownDate: null,
  takedownOffsetDays: undefined,
};

export const RightCreate = () => {
  const classes = useStyles();
  const [urlLng, setUrlLng] = useState("/en");
  const { location } = useHistory();
  const history = useHistory();
  const [disable, setDisable] = useState(false);

  const dispatch = useDispatch();
  const [values, setValues] = useState(initalSateValues);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState<string>("");
  const [rightHolders, setRightHolders] = useState([]);
  const [availability, setAvailability] = useState(initialAvailability);
  const [publish, setPublish] = useState(2);
  const [takedown, setTakedown] = useState(2);
  const [id, setId] = useState("");
  const [event, setEvent] = useState({
    title: "",
    isError: false,
  });

  useEffect(() => {
    getDataRightsholders();
    dispatch(userActions.setLink("rights"));
  }, []);

  useEffect(() => {
    const language = getLanguage(location.pathname);

    setUrlLng(language);
  }, [location]);

  const undo = () => {
    setValues(initalSateValues);
    setAvailability(initialAvailability);
    setPublish(2);
    setTakedown(2);
  };
  const handleAddChip = (chip, prop) => {
    setValues({ ...values, [prop]: [...values[prop], chip] });
  };

  const handleChangeCountries = (chip, prop) => {
    setValues({ ...values, [prop]: chip });
  };

  const handleDeleteChip = (chip, prop) => {
    setValues({
      ...values,
      [prop]: values[prop].filter((item) => item !== chip),
    });
  };

  const getDataRightsholders = async () => {
    const response = await getRightSholders(null, null, 1000000);

    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setRightHolders(response.rightHolders);
    }
  };

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event });
  };

  const handleChangeAvailability = (prop, event) => {
    setAvailability({ ...availability, [prop]: event });
  };

  const onSubmit = async () => {
    if (!values.title) {
      setDisable(true);
      return;
    } else {
      setDisable(false);
      const rightsHolder = rightHolders.filter(
        (item) => item.id === values.rightsHolderId
      )[0];

      if (values.language.id) {
        const response = await createRights({
          ...values,
          availability,
          rightsHolder,
          countries: values.countries.map((el) => getCountryShortName(el)),
        });
        if (typeof response === "number") {
          setOpenAlert(true);
          setError(getErrorsHandler(response).title);
        } else {
          setOpenAlert(true);
          setEvent({ title: "Right saved", isError: false });
          setId(response?.id);
        }
      } else {
        setOpenAlert(true);
        setEvent({ title: "Try again", isError: true });
      }
    }
  };

  const editPublish = (event) => {
    setAvailability({
      ...availability,
      publishDate: event === 0 ? availability.publishDate : null,
      publishDateOffsetHours:
        event === 1 ? availability.publishDateOffsetHours : 0,
    });
    setPublish(event);
  };

  const editTakedown = (event) => {
    setAvailability({
      ...availability,
      takedownDate: null,
      takedownOffsetDays: 0,
    });
    setTakedown(event);
  };

  return (
    <section className={classes.playlist}>
      <Link to={`${urlLng}/rights`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Rights
      </Link>
      <div className={classes.heading}>
        <div className={classes.info}>
          <h1 className={classes.title}>Create Rights</h1>
        </div>
        <div>
          <Button className={`button-white`} onClick={() => undo()}>
            <RotateIcon />
          </Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
            className={`button-white`}
          >
            <SaveIcon />
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.inputs}>
          <TextField
            className={classes.input}
            id="title"
            label="Media Right Title"
            variant="outlined"
            onChange={(e) => handleChange("title", e.target.value)}
            value={values.title}
            required
            error={disable && !values.title}
          />
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Rightsholder
            </InputLabel>
            <Select
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Holdback (days)"
              onChange={(e) => handleChange("rightsHolderId", e.target.value)}
              value={values.rightsHolderId}
            >
              {rightHolders.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleChange("allowToOverride", e.target.checked)
                }
                checked={values.allowToOverride}
                color="default"
                name="Allow Superadmin to override"
                inputProps={{ "aria-label": "checkbox with default color" }}
              />
            }
            label="Allow Superadmin to override"
          />
        </div>
        <TextField
          id="short-description"
          label="Short Description"
          multiline
          onChange={(e) => handleChange("description", e.target.value)}
          value={values.description}
          rows={4}
          variant="outlined"
          className={classes.description}
        />
        <FormControl variant="outlined" className={classes.language}>
          <InputLabel>Language</InputLabel>
          <Select
            MenuProps={{ classes: { paper: classes.menuPaper } }}
            autoWidth
            value={values?.language?.id}
            onChange={(e) => handleChange("language", { id: e.target.value })}
          >
            {languageRightList.map((language) => (
              <MenuItem key={language.id} value={language.id}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <h2 className={classes.subTitle}>Publish</h2>
        <div className={classes.inputs}>
          <FormControl component="fieldset" className={classes.radioGroup}>
            <RadioGroup
              aria-label="Type"
              name="type"
              className={classes.group}
              value={publish}
              onChange={(e) => editPublish(Number(e.target.value))}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      className={classes.input}
                      inputVariant="outlined"
                      label="Publish Date*"
                      onChange={(e) =>
                        e > new Date() &&
                        handleChangeAvailability("publishDate", e)
                      }
                      value={availability.publishDate}
                      onError={console.log}
                      disablePast
                      format="yyyy/MM/dd, HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                }
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={
                  <TextField
                    className={classes.input}
                    id="title"
                    type="number"
                    label="Hours after upload"
                    variant="outlined"
                    onChange={(e) => {
                      Number(e.target.value) <= 24 &&
                      Number(e.target.value) >= 0
                        ? handleChangeAvailability(
                            "publishDateOffsetHours",
                            Number(e.target.value)
                          )
                        : null;
                    }}
                    value={availability.publishDateOffsetHours}
                  />
                }
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Immediately"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <h2 className={classes.subTitle}>Takedown</h2>
        <div className={classes.inputs}>
          <FormControl component="fieldset" className={classes.radioGroup}>
            <RadioGroup
              aria-label="Type"
              name="type"
              className={classes.group}
              value={takedown}
              onChange={(e) => editTakedown(Number(e.target.value))}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      className={classes.input}
                      inputVariant="outlined"
                      label="Takedown Date*"
                      onChange={(e) =>
                        e > new Date() &&
                        handleChangeAvailability("takedownDate", e)
                      }
                      value={availability.takedownDate}
                      onError={console.log}
                      disablePast
                      format="yyyy/MM/dd, HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                }
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={
                  <FormControl variant="outlined" className={classes.input}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Hours/Days after upload
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Hours/Days after upload"
                      onChange={(e) =>
                        handleChangeAvailability(
                          "takedownOffsetDays",
                          e.target.value
                        )
                      }
                      value={
                        availability.takedownOffsetDays
                          ? Number(availability.takedownOffsetDays)
                          : null
                      }
                    >
                      <MenuItem value={1}>24 hours</MenuItem>
                      <MenuItem value={2}>48 hours</MenuItem>
                      <MenuItem value={5}>5 days</MenuItem>
                      <MenuItem value={7}>7 days</MenuItem>
                      <MenuItem value={14}>14 days</MenuItem>
                      <MenuItem value={30}>30 days</MenuItem>
                    </Select>
                  </FormControl>
                }
              />
              <FormControlLabel value={2} control={<Radio />} label="None" />
            </RadioGroup>
          </FormControl>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) =>
                handleChangeAvailability(
                  "allowToOverrideAvailability",
                  e.target.checked
                )
              }
              checked={availability.allowToOverrideAvailability}
              color="default"
              name="Allow Superadmin to override"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
          }
          label="Allow override"
        />
        <h2 className={classes.subTitle}>Geo Filtering</h2>
        <div className={classes.inputs}>
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Filtering Options
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Filtering Options"
              onChange={(e) => handleChange("filtering", e.target.value)}
              value={values.filtering}
            >
              <MenuItem value={GeoFiltering.Unrestricted}>
                Unrestricted
              </MenuItem>
              <MenuItem value={GeoFiltering.AllowViewing}>
                Allow viewing from the following countries
              </MenuItem>
              <MenuItem value={GeoFiltering.PreventViewing}>
                Prevent viewing from the following countries
              </MenuItem>
            </Select>
          </FormControl>

          <Autocomplete
            multiple
            className={classes.input}
            options={Object.values(data).sort()}
            getOptionLabel={(data) => data}
            onChange={(_, e) => handleChangeCountries(e, "countries")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Countries"
                placeholder="Countries"
              />
            )}
          />
        </div>

        <h2 className={classes.subTitle}>Domain Restriction</h2>
        <span className={classes.information}>
          Domain restrictions allow the user to limit video playback based upon
          domain.
        </span>
        <div className={classes.inputs}>
          <ChipInput
            className={classes.input}
            fullWidth
            variant="outlined"
            label="Blacklisted Domains"
            value={values.blacklistedDomains}
            onAdd={(chip) => handleAddChip(chip, "blacklistedDomains")}
            onDelete={(chip, index) =>
              handleDeleteChip(chip, "blacklistedDomains")
            }
          />

          <ChipInput
            className={classes.input}
            fullWidth
            variant="outlined"
            label="Whitelisted Domains"
            value={values.whitelistedDomains}
            onAdd={(chip) => handleAddChip(chip, "whitelistedDomains")}
            onDelete={(chip, index) =>
              handleDeleteChip(chip, "whitelistedDomains")
            }
          />
        </div>
        <div className={classes.buttons}>
          <Button
            className={`button-white`}
            onClick={() => {
              history.push(`${urlLng}/rights`);
            }}
          >
            Cancel
          </Button>
        </div>
        <Snackbar
          open={openAlert}
          autoHideDuration={1500}
          onClose={() => {
            setOpenAlert(false),
              !event.isError && history.push(`${urlLng}/rights/${id}/edit`);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert severity={event.isError ? "error" : "success"}>
            {error || event.title}
          </MuiAlert>
        </Snackbar>
        <DeleteModal
          open={openDialog}
          setOpen={setOpenDialog}
          data={values}
          onDelete={() => history.push(`${urlLng}/rights`)}
          header="Right"
          right="this right"
        />
      </div>
    </section>
  );
};
