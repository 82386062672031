import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ReplayIcon from "@material-ui/icons/Replay";
import {
  IconButton,
  Snackbar,
  Input,
  InputAdornment,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { useStyles } from "./hooks/useStyles";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";
import { RenderPagination } from "../../components/pagination/index";
import { EmbedCodesTable } from "../../components/embed-codes-table";
import { EmbedCodeModal } from "../../components/video-embed-modal";
import {
  EmbedCode,
  EmbedCodesP,
  EmbedCodeTypes,
} from "../../declarations/pages";
import { deleteEmbed, getEmbed } from "../../api/embed-codes";
import { PAGINATION_PER_PAGE, SORT_DEFAULT_CREATE } from "../../init/constants";
import { DeleteModal } from "../../components/delete-modal";
import { getErrorsHandler } from "../../errors/embed";
import { ROLE_ADMIN } from "../../init/constants";
import { RootState } from "../../init/rootReducer";

export const SemanticEmbedCodes = () => {
  const classes = useStyles();

  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT_CREATE);
  const [embedData, setEmbedData] = useState<EmbedCodesP>();
  const [isLoaded, setInitialData] = useState(false);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dataDelete, setdataDelete] = useState({});
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [error, setError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentEmbedCode, setCurrentEmbedCode] = useState<EmbedCode>();
  const [currentPage, setCurrentPage] = useState(0);
  const [resetShort, setResetShort] = useState("");
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );
  const dispatch = useDispatch();
  const { role } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    dispatch(userActions.setLink("semanticEmbedCodes"));
  }, []);

  useEffect(() => {
    if (!displaySearch) {
      getSemanticEmbedData();
    }
  }, [displaySearch, role]);

  const getSemanticEmbedData = async (
    page = 0,
    perPage = PAGINATION_PER_PAGE,
    orderDefault = SORT_DEFAULT_CREATE
  ) => {
    const data = await getEmbed({
      type: EmbedCodeTypes.SEMANTIC,
      perPage,
      page: Number(page),
      name: displaySearch as unknown as string,
      sort: !order ? order : orderDefault,
    });
    setEmbedData(data);
    setQueryPaginationNumber(page);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const reset = async () => {
    if (displaySearch) {
      setDisplaySearch("");
    }
    getSemanticEmbedData();
    setCurrentPage(0);
    setOrder(SORT_DEFAULT_CREATE);
    setResetShort("reset");
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  const hadleSearchChange = (e) => {
    setDisplaySearch(e.target.value);
  };

  const onCreateEmbedCode = async () => {
    setCurrentEmbedCode(null);
    setIsOpenModalEmbed(true);
  };
  const editEmbedCode = (index) => {
    setCurrentEmbedCode(embedData.codes[index]);
    setIsOpenModalEmbed(true);
  };

  const deleteData = async (data) => {
    const response = await deleteEmbed(data);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      await getSemanticEmbedData(currentPage);
    }
  };

  const loadData = async (value) => {
    setCurrentPage(value);
    await getSemanticEmbedData(value);
  };

  const onSaveCode = async () => {
    await getSemanticEmbedData(currentPage);
  };

  const searchVideo = () => {
    getSemanticEmbedData();
  };

  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      searchVideo();
    }
  };

  return (
    <section className={classes.playlist}>
      <div className={classes.heading}>
        <h1 className={classes.title}>Semantic Embed Codes</h1>
        {role !== ROLE_ADMIN && (
          <Button className={`button`} onClick={onCreateEmbedCode}>
            New Semantic Embed Code
          </Button>
        )}
      </div>
      <div className={classes.searching}>
        <FormControl className={classes.input}>
          <InputLabel htmlFor="search-embed-codes">Search by Title</InputLabel>
          <Input
            id="search-embed-codes"
            type="text"
            value={displaySearch}
            onChange={hadleSearchChange}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <IconButton>
                <InputAdornment position="end" onClick={searchVideo}>
                  <SearchIcon />
                </InputAdornment>
              </IconButton>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={reset}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      {!isLoaded && (
        <div className={"spinner"}>
          <div className={`lds-hourglass`}></div>
        </div>
      )}
      {isLoaded && embedData && !embedData?.total ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <EmbedCodesTable
            embedCode
            data={embedData?.codes}
            onEditEmbedCode={editEmbedCode}
            onDelete={(e) => setdataDelete(e)}
            setModal={setOpenDeleteModal}
            resetShort={resetShort}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT_CREATE),
                getSemanticEmbedData(
                  Number(querypaginationNumber),
                  10,
                  e || SORT_DEFAULT_CREATE
                );
            }}
          />
          <RenderPagination
            limit={null}
            count={embedData?.total}
            loadData={(value) =>
              getSemanticEmbedData(value, 10, SORT_DEFAULT_CREATE)
            }
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}
      <EmbedCodeModal
        open={isOpenModalEmbed}
        setOpen={setIsOpenModalEmbed}
        type={EmbedCodeTypes.SEMANTIC}
        data={currentEmbedCode}
        onSave={onSaveCode}
        key={currentEmbedCode?.id}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={dataDelete}
        onDelete={(data) => deleteData(data)}
        header="Semantic Embed Codes"
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
      >
        <MuiAlert severity="success">Semantic Embed Codes deleted.</MuiAlert>
      </Snackbar>
    </section>
  );
};
