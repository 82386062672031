// @ts-ignore
// @ts-nocheck
import React, { ReactElement, useEffect, useState } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import { useStyles } from "./hooks/useStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../init/rootReducer";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { EmbedCodeModal } from "../video-embed-modal";
import { getAllPlaylists } from "../../api/all-playlists";
import { AddPlaylistModal } from "../../components/add-playlist-modal";
import { SMALL_DEFAULT_IMAGE, SORT_DEFAULT } from "../../init/constants";
import { EditIcon } from "../../assets/image/edit-icon";

export const PlaylistSmartVideos = ({ data, urlLng }: any): ReactElement => {
  const classes = useStyles();
  const { isAdmin } = useSelector((state: RootState) => state.user);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [video, setVideo] = useState(null);
  const [id, setId] = useState("");
  const [openPlaylistModal, setOpenPlaylistModal] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    data;
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getPlayList = async (query, name = "name", perPage = 10, page = 0) => {
    const { playlists: result } = await getAllPlaylists(
      query,
      name,
      perPage,
      page,
      SORT_DEFAULT,
      "type=MANUAL"
    );
    setPlaylists(result);
  };
  const openEmbedCodeModal = async () => {
    handleClose();
    setIsOpenModalEmbed(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pos</TableCell>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              {isAdmin && <TableCell />}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {data.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>
                  <img src={SMALL_DEFAULT_IMAGE} alt="error" />
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.status ? row.status : "Active"}</TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  {moment(row.createdDate).format("DD/MM/yyyy, h:mm A")}
                </TableCell>
                <TableCell>
                  {moment(row.updatedDate).format("DD/MM/yyyy, h:mm A")}
                </TableCell>
                <TableCell className={classes.row}>
                  <IconButton
                    onClick={() =>
                      window.location.assign(
                        `${window.location.origin}${urlLng}/all-videos/${row.id}/edit`
                      )
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={(e) => {
                      handleClick(e, row), setId(row.id), setVideo(row);
                    }}
                  >
                    <MoreVertIcon
                      className={classes.btn}
                      aria-controls={"simple-menu-" + i}
                      aria-haspopup="true"
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <Menu
              id={"simple-menu-"}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setOpenPlaylistModal(true), getPlayList("", "", 5, 0);
                }}
              >
                Add to a playlist
              </MenuItem>
              <MenuItem onClick={() => openEmbedCodeModal()}>
                Create embed code
              </MenuItem>
            </Menu>
          </TableBody>
        </Table>
      </TableContainer>
      <AddPlaylistModal
        open={openPlaylistModal}
        setOpen={setOpenPlaylistModal}
        playlists={playlists}
        videoId={id}
        setSearchPlaylist={getPlayList}
      />
      <EmbedCodeModal
        open={isOpenModalEmbed}
        video={video}
        setOpen={setIsOpenModalEmbed}
      />
    </>
  );
};
