import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  cardVideo: {
    display: "flex",
    width: "100%",
    height: "71px",
    marginBottom: "15px",
    marginRight: "35px",
    cursor: "pointer",
    padding: 0,
    "&:hover": {
      background: "#E4E4E4",
    },
  },
  cardVideoActive: {
    display: "flex",
    width: "100%",
    background: "#c3c3c3",
    height: "71px",
    marginBottom: "15px",
    marginRight: "35px",
    cursor: "pointer",
    boxShadow: "10px 10px 10px #80808014",
    borderRadius: "3px",
  },
  image: {
    width: "112px",
    marginRight: "16px",
  },
  head: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    order: 0,
    flexGrow: 1,
    margin: 0,
    marginBottom: "5px",
  },
  date: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    opacity: "0.5",
  },
  icon: {
    minWidth: "15px",
  },
  text: {
    padding: "10px 5px",
  },
  "@media screen and (min-width: 1440px)": {
    cardVideo: {
      maxWidth: "100%",
    },
    cardVideoActive: {
      maxWidth: "100%",
    },
  },
}));
