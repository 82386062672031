import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import MuiAlert from "@material-ui/lab/Alert";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { EditIcon } from "../../assets/image/edit-icon";
import { DocumentIcon } from "../../assets/image/document-icon";
import { RootState } from "../../init/rootReducer";
import { Channel } from "../../declarations/pages";
import { SortArray } from "../../components/sorter/sorter";
import { DeleteModal } from "../delete-modal";
import { deleteChannel } from "../../api/chanels";
import { getErrorsHandler } from "../../errors/video";
import { ROLE_ADMIN } from "../../init/constants";
import {
  SMALL_DEFAULT_IMAGE,
  SORT_DEFAULT_ICON_NAME,
} from "../../init/constants";
interface Props {
  data: Channel[];
  urlLng: string;
  resetShort: string;
  onDelete?: () => void;
  updateData: (data: string) => void;
}

export const ChannelsTable = ({
  data = [],
  urlLng,
  onDelete,
  updateData,
  resetShort = "",
}: Props): ReactElement => {
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const [userData, setData] = useState({});
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [error, setError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sort, setSort] = useState(resetShort);
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT_ICON_NAME);

  const { isAdmin } = useSelector((state: RootState) => state.user);
  const { role } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);

  const deleteData = async (userData) => {
    const response = await deleteChannel(userData);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      onDelete();
    }
  };
  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title} />
              <TableCell className={classes.title}>
                Name
                <SortArray
                  sort={sort}
                  type="name"
                  setSort={(e) => {
                    updateData(e && `name:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title}>ID</TableCell>
              <TableCell className={classes.title}>
                Language
                <SortArray
                  sort={sort}
                  type="language"
                  setSort={(e) => {
                    updateData(e && `language:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title} />
              {isAdmin && role === ROLE_ADMIN && (
                <>
                  <TableCell className={classes.title} />
                  <TableCell className={classes.title} />
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell width="7%" className={classes.row}>
                  <Link to={`${urlLng}/channels/${row.id}`}>
                    <img
                      src={SMALL_DEFAULT_IMAGE}
                      className={classes.img}
                      alt=""
                    />
                  </Link>
                </TableCell>
                <TableCell width="35%" className={classes.row}>
                  <Link to={`${urlLng}/channels/${row.id}`}>{row.name}</Link>
                </TableCell>
                <TableCell width="20%" className={classes.row}>
                  {row.id}
                </TableCell>
                <TableCell width="20%" className={classes.row}>
                  {row?.language?.name}
                </TableCell>
                <TableCell width="1%" className={classes.row}>
                  <Link to={`${urlLng}/channels/${row.id}`}>
                    <IconButton>
                      <DocumentIcon />
                    </IconButton>
                  </Link>
                </TableCell>
                {isAdmin && role === ROLE_ADMIN && (
                  <>
                    <TableCell width="1%" className={classes.row}>
                      <Link to={`${urlLng}/channels/${row.id}/edit`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell width="1%" className={classes.row}>
                      <IconButton
                        onClick={() => {
                          setOpenDeleteModal(true), setData(row);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={userData}
        onDelete={(data) => deleteData(data)}
        header="Channel"
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
      >
        <MuiAlert severity="success">Channel deleted.</MuiAlert>
      </Snackbar>
    </>
  );
};
