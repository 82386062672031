import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontStyle: "normal",
    fontWeight: "bold",

    "& a": {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "14px",
      width: 256,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textDecoration: "none",
      color: "#000000",
    },
  },
  title: {
    fontSize: "32px",
    lineHeight: "36px",
    margin: 0,
  },
  subTitle: {
    fontSize: "24px",
    lineHeight: "24px",
    margin: 24,
  },
  text: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: 24,
  },
}));
