import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  singIn: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  window: {
    display: "flex",
    width: "353px",
    height: "170px",
    flexDirection: "column",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
  },
  logo: {
    width: "356px",
    margin: "0 auto",
  },
}));
