import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  playlist: {
    marginLeft: "32px",
    marginTop: "38px",
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  group: {
    flexDirection: "row",
    flexWrap: "nowrap",

    "& .Mui-checked": {
      color: "#000",
    },

    "& .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "rgba(0,0,0, .04)",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    // width: "60%",
    marginBottom: "48px",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    margin: "16px 0",
    width: "100%",

    "& strong": {
      fontWeight: "bold",
      marginBottom: "13px",
    },
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    textDecoration: "none",
  },
  heading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "32px",
    paddingRight: 22,
    boxSizing: "border-box",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  subTitle: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    marginTop: "48px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    marginTop: "29px",
    "& span": {
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "22px",
      marginTop: 24,
    },
  },
  inputs: {
    display: "flex",
    width: "100%",

    "& .MuiFormControl-root": {
      width: "352px",
      marginRight: 32,
    },
  },

  language: {
    maxWidth: "352px",
    margin: "12px 0",

    "& [data-shrink=true]": {
      color: "#000000",
      fontWeight: "bold",
    },

    "& .MuiInputBase-root": {
      borderRadius: 0,
    },

    "& .Mui-focused": {
      color: "#000",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
  },

  menuPaper: {
    maxHeight: "300px",
  },

  description: {
    margin: "12px 0",
    maxWidth: 736,
    width: "100%",

    "& [data-shrink=true]": {
      color: "#000000",
      fontWeight: "bold",
    },

    "& .MuiInputBase-root": {
      borderRadius: 0,
    },

    "& .Mui-focused": {
      color: "#000",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
  },

  radioGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    marginTop: "24px",

    "& button": {
      width: "352px",
      height: "48px",
      margin: 0,
      marginRight: "30px",
    },

    "& .Mui-disabled": {
      color: "#fff",
      opacity: ".7",
    },
  },
  input: {
    margin: "12px 0",
    width: "100%",

    "& [data-shrink=true]": {
      color: "#000000",
      fontWeight: "bold",
    },

    "& .MuiInputBase-root": {
      borderRadius: 0,
    },

    "& .Mui-focused": {
      color: "#000",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
  },
});
