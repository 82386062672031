import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  channels: {
    marginLeft: "32px",
    marginTop: "38px",
    marginBottom: "38px",
  },
  link: {
    textDecoration: "none",
    textTransform: "capitalize",
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    marginBottom: "29px",
    textDecoration: "none",
  },
  heading: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",

    "& button": {
      padding: "0 56px",
    },
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  searching: {
    display: "flex",
  },
  select: {
    width: "256px",
    margin: "0 25px",
  },
  button: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    display: "flex",
    justifyContent: "space-between",
    width: "100px",

    "&:hover": {
      borderRadius: "0px",
    },
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  input: {
    width: "350px",
    marginRight: "29px",
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  snackbar: {
    "& .MuiPaper-root": {
      borderRadius: "30px",
      backgroundColor: "#fff",
      color: "red",
      padding: "5px 30px",

      "& .MuiSnackbarContent-message": {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  },
  "@media screen and (min-width: 768px)": {
    channels: {
      marginLeft: "20px",
    },
  },
}));
