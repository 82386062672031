import React, { useState, useEffect } from "react";
import CardVideo from "../cardVideo/cardVideo";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import { useStyles } from "../hooks/useStyles";
import { getLanguage, getTranslate } from "../../../languages";

const VideoBlock = ({
  title,
  isLinkAllVideo = false,
  count,
  data,
  changePage,
  handleAddVideos = null,
}: any) => {
  const classes = useStyles();
  const { location } = useHistory();
  const [urlLng, setUrlLng] = useState("/en");
  useEffect(() => {
    const language = getLanguage(location.pathname);

    setUrlLng(language);
  }, [location]);

  return (
    <section className={classes.videoBlock}>
      <h1 className={classes.title}>
        {title}
        {isLinkAllVideo ? (
          <Link className={classes.link} to={`${urlLng}/all-videos`}>
            View All
          </Link>
        ) : (
          ""
        )}
      </h1>
      <div className={classes.cards}>
        {data?.map((item, index) => {
          return <CardVideo key={index} data={item} />;
        })}
      </div>
      <div className={classes.buttonsWrapper}>
        {count !== data.length ? (
          <Button
            className={`${
              handleAddVideos ? classes.buttonGrey : classes.button
            } button`}
            variant="contained"
            onClick={changePage}
          >
            {getTranslate().showMore}
          </Button>
        ) : null}
        {handleAddVideos && (
          <Button
            className={`button ${classes.button}`}
            onClick={() => handleAddVideos()}
          >
            Add videos
          </Button>
        )}
      </div>
    </section>
  );
};

export default VideoBlock;
