import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton, Button, TextField } from "@material-ui/core";
import { MISSING_FIELD } from "../../init/constants";
import { useStyles } from "./hooks/useStyles";

interface State {
  name: string;
  description: string;
}

export const PlayersModal = ({ open, setOpen, createPlayer }: any) => {
  const classes = useStyles();
  const [disable, setDisable] = useState(false);
  const [values, setValues] = useState<State>({
    name: "",
    description: "",
  });

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const savePublisher = () => {
    if (!values.name) setDisable(true);
    else createPlayer(values), setOpen(false);
  };
  const closeModal = () => {
    setOpen(false);
    setDisable(false);
    setValues({
      name: "",
      description: "",
    });
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          Create player
          <IconButton aria-label="close" onClick={closeModal}>
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <form noValidate autoComplete="off" className={classes.form}>
          <div>
            <TextField
              className={classes.input}
              id="name"
              label="Name *"
              variant="outlined"
              value={values.name}
              onChange={(e) => handleChange("name", e)}
              error={disable && !values.name}
              helperText={disable && !values.name ? MISSING_FIELD : null}
              inputProps={{ maxLength: 200 }}
            />
            <TextField
              id="short-description"
              label="Short Description"
              multiline
              rows={4}
              variant="outlined"
              className={classes.input}
              value={values.description}
              inputProps={{ maxLength: 250 }}
              onChange={(e) => handleChange("description", e)}
            />
          </div>
          <div className={classes.buttons}>
            <Button className={classes.buttonCancel} onClick={closeModal}>
              Cancel
            </Button>
            <Button
              className={`${classes.button} button`}
              onClick={savePublisher}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
