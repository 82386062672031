import React, { ReactElement, useState, useEffect } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { EditIcon } from "../../assets/image/edit-icon";
import IconButton from "@material-ui/core/IconButton";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { EmbedCodeModal } from "../video-embed-modal";
import { DeleteModal } from "../delete-modal";
import moment from "moment";
import { deleteVideo } from "../../api/videos";
import { getErrorsHandler } from "../../errors/video";
import { Link } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import { RootState } from "../../init/rootReducer";
import { AllVideos } from "../../declarations/pages";
import { AddPlaylistModal } from "../add-playlist-modal";
import { getAllPlaylists } from "../../api/all-playlists";
import { SortArray } from "../sorter/sorter";
import {
  ROLE_PUBLISHER,
  ADDITIONAL_TEXT,
  SORT_DEFAULT_ICON_CREATE,
} from "../../init/constants";

interface Props {
  data?: AllVideos[];
  onDelete: (data: AllVideos) => void;
  updateData: (data: string) => void;
  urlLng: string;
  role: string;
  resetShort: string;
}
export const AllVideoTable = ({
  data,
  onDelete,
  urlLng,
  role,
  updateData,
  resetShort = "",
}: Props): ReactElement => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [video, setModalVideo] = useState(null);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [error, setError] = useState("");
  const [videoDelete, setVideoDelete] = useState();
  const [sort, setSort] = useState("");
  const [playlists, setPlaylists] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const { isAdmin } = useSelector((state: RootState) => state.user);
  const [openPlaylistModal, setOpenPlaylistModal] = useState(false);
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT_ICON_CREATE);

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);

  const handleClick = (event, row) => {
    setModalVideo(row);
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClick = (_, row) => {
    setError("");
    setVideoDelete(row);
    setOpenDeleteModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getPlayList = async (query, name = "name", perPage = 10, page = 0) => {
    const { playlists: result } = await getAllPlaylists(
      query,
      name,
      perPage,
      page
    );
    setPlaylists(result);
  };
  const handleDeleteConfirm = async (data) => {
    // eslint-disable-next-line react/prop-types
    const response = await deleteVideo(data.id);
    if (typeof response == "boolean") {
      setOpenDeleteModal(false);
      onDelete(data);
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
    } else {
      setOpenDeleteModal(true);
      setError(getErrorsHandler(response).title);
    }
  };

  const openEmbedCodeModal = async () => {
    handleClose();
    setIsOpenModalEmbed(true);
  };

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title} />
              <TableCell className={classes.title}>
                Name
                <SortArray
                  sort={sort}
                  type="name"
                  setSort={(e) => {
                    updateData(e && `name:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title}>Status</TableCell>
              <TableCell className={classes.title}>ID</TableCell>
              <TableCell className={classes.title}>
                Created
                <SortArray
                  sort={sort}
                  type="createdDate"
                  setSort={(e) => {
                    updateData(e && `createdDate:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title}>
                Updated
                <SortArray
                  sort={sort}
                  type="updatedDate"
                  setSort={(e) => {
                    updateData(e && `updatedDate:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title} />
              {isAdmin && <TableCell className={classes.title} />}
              <TableCell className={classes.title} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell width="8%" className={classes.row}>
                  <img className={classes.img} src={row.img} alt="error" />
                </TableCell>
                <TableCell width="20%" className={classes.row}>
                  {row.name}
                </TableCell>
                <TableCell width="10%" className={classes.row}>
                  {row.status}
                </TableCell>
                <TableCell width="11%" className={classes.row}>
                  {row.id}
                </TableCell>
                <TableCell width="18%" className={classes.row}>
                  {moment(row.created).format("DD/MM/yyyy, h:mm A")}
                </TableCell>
                <TableCell width="18%" className={classes.row}>
                  {moment(row.updated).format("DD/MM/yyyy, h:mm A")}
                </TableCell>
                <TableCell width="5%" className={classes.row}>
                  <Link to={`${urlLng}/all-videos/${row.id}/edit`}>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Link>
                </TableCell>
                {isAdmin && (
                  <TableCell width="5%" className={classes.row}>
                    <IconButton onClick={(e) => handleDeleteClick(e, row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell width="5%" className={classes.row}>
                  <IconButton>
                    <MoreVertIcon
                      className={classes.btn}
                      onClick={(e) => handleClick(e, row)}
                      aria-controls={"simple-menu-" + index}
                      aria-haspopup="true"
                    />
                  </IconButton>
                  <Menu
                    id={"simple-menu-" + index}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => setOpenPlaylistModal(true)}>
                      Add to a playlist
                    </MenuItem>
                    {role === ROLE_PUBLISHER && (
                      <MenuItem onClick={() => openEmbedCodeModal()}>
                        Create embed code
                      </MenuItem>
                    )}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <EmbedCodeModal
        open={isOpenModalEmbed}
        video={video}
        setOpen={setIsOpenModalEmbed}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={videoDelete}
        header="Video"
        onDelete={(data) => handleDeleteConfirm(data)}
        additionalText={ADDITIONAL_TEXT}
      />
      <AddPlaylistModal
        open={openPlaylistModal}
        setOpen={setOpenPlaylistModal}
        playlists={playlists}
        videoId={video?.id}
        setSearchPlaylist={getPlayList}
      />
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="success">Video Deleted</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
    </>
  );
};
