import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ReplayIcon from "@material-ui/icons/Replay";
import { SemanticArticlesTable } from "../../components/semantic-articles-table";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useStyles } from "./hooks/useStyles";
import SearchIcon from "@material-ui/icons/Search";
import { RenderPagination } from "../../components/pagination/index";
import { getSemanticArticles } from "../../api/semantic-article";
import { PAGINATION_PER_PAGE, SORT_DEFAULT_CREATE } from "../../init/constants";
import { SemanticArticlesP } from "../../declarations/pages";
import { RootState } from "../../init/rootReducer";
import {
  getSemanticArticleById,
  updateSemanticArticle,
} from "../../api/semantic-article";
import { semanticArticleActions } from "../../bus/semantic-article/actions";

interface Props {
  urlLng: string;
}

export const SemanticArticles = ({ urlLng }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const { location } = history;
  const urlParams = new URLSearchParams(history.location.search);
  const orderParam = urlParams.get("order");
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT_CREATE);
  const [articles, setArticles] = useState<SemanticArticlesP>();
  const [isLoaded, setInitialData] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);
  const { publisher } = useSelector((state: RootState) => state.user);
  const [values, setValues] = useState({
    dateFrom: null,
    dateTo: null,
    embed_name: "",
    searchBy: "url",
    search: "",
    q_type: "url",
    isReset: false,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [resetShort, setResetShort] = useState("");
  const handleDateChange = (date, prop) => {
    setValues({ ...values, [prop]: date });
  };

  const handleChangeEmbed = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeSearch = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeSearchBy = (prop, event) => {
    setValues({
      ...values,
      q_type: event.target.value,
      [prop]: event.target.value,
    });
  };

  const handleSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getData(0, PAGINATION_PER_PAGE, values.q_type, values.search);
      setCurrentPage(0);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("order", order);
      history.push({ search: params.toString() });
    }
  }, [history, isLoaded, order]);

  useEffect(() => {
    dispatch(userActions.setLink("semanticArticles"));
  }, []);

  useEffect(() => {
    setInitialData(true);
  }, [publisher, values.isReset]);

  const reset = async () => {
    setResetShort("reset");
    setOrder(SORT_DEFAULT_CREATE);
    setCurrentPage(0);
    setValues({
      dateFrom: null,
      dateTo: null,
      embed_name: "",
      searchBy: "url",
      search: "",
      q_type: "url",
      isReset: !values.isReset,
    });
  };

  const onLock = async (data) => {
    const { id, locked } = data;
    const { article, videos } = await getSemanticArticleById(id);
    dispatch(semanticArticleActions.lockArticle(data));
    await updateSemanticArticle(id, { ...article, locked: !locked }, videos);
    await reset();
  };

  const onHide = async (data) => {
    const { id, hide } = data;
    const { article, videos } = await getSemanticArticleById(id);
    dispatch(semanticArticleActions.hideArticle(data));
    await updateSemanticArticle(id, { ...article, hide: !hide }, videos);
    await reset();
  };
  const getData = async (
    page = 0,
    perPage = PAGINATION_PER_PAGE,
    q_type = null,
    query = null,
    orderDefault = SORT_DEFAULT_CREATE
  ) => {
    const data = await getSemanticArticles({
      perPage,
      page,
      q_type,
      query,
      sort: !order ? order : orderDefault,
    });
    setArticles(data);

    if (data.semanticArticles.length === 0) {
      setShowNoResults(true);
    }
    if (!isLoaded) {
      setInitialData(true);
    }
  };
  return (
    <section className={classes.playlist}>
      <div className={classes.heading}>
        <h1 className={classes.title}>Semantic</h1>
        <h3 className={classes.subTitle}>Related Article Pages</h3>
        <p className={classes.subTitle2}>
          Please use the search bar to display results.
        </p>
      </div>
      <div className={classes.searching}>
        {/* <div className={classes.dates}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              className={classes.input}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date From"
              value={values.dateFrom}
              onChange={(e) => handleDateChange(e, "dateFrom")}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              autoOk
              className={classes.input}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date To"
              value={values.dateTo}
              onChange={(e) => handleDateChange(e, "dateTo")}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Embed Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Embed Name"
              onChange={(e) => handleChangeEmbed("embed_name", e)}
              value={values.embed_name}
            >
              <MenuItem value="1">None</MenuItem>
              <MenuItem value="2">UEFA Champions League 2019-2020</MenuItem>
              <MenuItem value="3">Premiere League Highlights 2020</MenuItem>
            </Select>
          </FormControl>
        </div> */}
        <div className={classes.dates}>
          <FormControl className={classes.input}>
            <InputLabel htmlFor="search-articles">Search</InputLabel>
            <Input
              id="search-articles"
              type="text"
              value={values.search}
              onKeyDown={(e) => handleSearch(e)}
              onChange={(e) => handleChangeSearch("search", e)}
              endAdornment={
                <IconButton>
                  <InputAdornment position="end">
                    <SearchIcon onClick={(e) => handleSearch(e)} />
                  </InputAdornment>
                </IconButton>
              }
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Search by
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Search by"
              onChange={(e) => handleChangeSearchBy("searchBy", e)}
              value={values.searchBy}
            >
              <MenuItem value="url">Article Url</MenuItem>
              <MenuItem value="embed_name">Embed Name</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            aria-label="reset"
            className={classes.button}
            onClick={() => reset()}
          >
            <ReplayIcon /> Reset
          </IconButton>
        </div>
      </div>
      {isLoaded && !articles?.semanticArticles.length ? (
        <div className={classes.noResults}>
          {showNoResults && <p>No results</p>}
        </div>
      ) : (
        <>
          <SemanticArticlesTable
            data={articles?.semanticArticles}
            urlLng={urlLng}
            onLock={onLock}
            onHide={onHide}
            resetShort={resetShort}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT_CREATE),
                getData(
                  Number(currentPage),
                  10,
                  values.q_type,
                  values.search,
                  e || SORT_DEFAULT_CREATE
                );
            }}
          />
          <RenderPagination
            limit={null}
            count={articles?.total}
            loadData={(value) =>
              getData(
                Number(value),
                10,
                values.q_type,
                values.search,
                SORT_DEFAULT_CREATE
              )
            }
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}
      {!isLoaded && (
        <div className={"spinner"}>
          <div className={`lds-hourglass`}></div>
        </div>
      )}
    </section>
  );
};
