import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import { PlaylistsTable } from "../../components/playlists-table";
import { PlaylistsModal } from "../../components/playlists-modal";
import ReplayIcon from "@material-ui/icons/Replay";
import { useHistory } from "react-router-dom";
import {
  getAllPlaylists,
  deletePlaylist,
  createPlaylist,
} from "../../api/all-playlists";
import {
  Button,
  Snackbar,
  IconButton,
  Input,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { EmbedCodeModal } from "../../components/video-embed-modal";
import Search from "@material-ui/icons/Search";
import { useStyles } from "./hooks/useStyles";
import { RootState } from "../../init/rootReducer";
import { RenderPagination } from "../../components/pagination/index";
import { getErrorsHandler } from "../../errors/playlists";
import { EmbedCodeTypes } from "../../declarations/pages";
import { DeleteModal } from "../../components/delete-modal";
import { getChannels } from "../../api/chanels";
import { getPlayers } from "../../api/players";
import { ROLE_PUBLISHER, SORT_DEFAULT_NAME } from "../../init/constants";

export const Playlists = () => {
  const classes = useStyles();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [playlistType, setPlaylistType] = useState("");
  const [resetShort, setResetShort] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [rows, setRows] = useState([]);
  const [channels, setChannels] = useState([]);
  const [players, setPlayers] = useState([]);
  const dispatch = useDispatch();
  const [isLoaded, setInitialData] = useState(false);
  const [lenght, setLenght] = useState(0);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [playlist, setPlaylist] = useState(null);
  const [userDelete, setUserDelete] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT_NAME);
  const [openAlert, setOpenAlert] = useState({
    text: "",
    isOpen: false,
    type: "success",
  });
  const { role } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    if (role) {
      dispatch(userActions.setLink("playlists"));
      getData("type=manual", "", 10, 0);
    }
  }, [role]);

  const getData = async (
    query,
    name = "name",
    perPage = 10,
    page = 0,
    orderDefault = SORT_DEFAULT_NAME
  ) => {
    const { total, playlists } = await getAllPlaylists(
      query,
      name,
      perPage,
      page * perPage,
      !order ? order : orderDefault,
      "type=MANUAL,SMART"
    );
    setRows(playlists);
    setQueryPaginationNumber(page);
    setLenght(total);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const reset = async () => {
    if (displaySearch) {
      setDisplaySearch("");
    }
    setOrder(SORT_DEFAULT_NAME);
    getData("type=manual", "", 10, 0);
    setCurrentPage(0);
    setResetShort("reset");
  };

  const onDelete = async (data) => {
    const response = await deletePlaylist(data);
    if (typeof response !== "number") {
      setOpenAlert({
        text: "Playlist delete",
        isOpen: true,
        type: "success",
      });
      setOpenDeleteModal(false);
    } else {
      const error = await getErrorsHandler(response);
      setOpenAlert({
        text: error.title,
        isOpen: true,
        type: "error",
      });
    }
    getData("type=manual", "", 10, 0);
  };

  const openModalEmded = async (data) => {
    setIsOpenModalEmbed(true);
    setPlaylist(data);
    setPlaylistType(data.type);
    const channelsRes = await getChannels();
    setChannels(channelsRes.channels);
    const playersRes = await getPlayers();
    setPlayers(playersRes.players);
  };

  const onSubmit = async (data) => {
    const response = await createPlaylist(data);
    if (typeof response !== "number") {
      setRows((prevState) => [response, ...prevState]);
      setOpenAlert({
        text: "Playlist Added",
        isOpen: true,
        type: "success",
      });
      return { isError: true };
    } else {
      const error = await getErrorsHandler(response);
      return { isError: false, error: error.title };
    }
  };
  const ChangeSearch = (e) => {
    setDisplaySearch(e.target.value);
  };
  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getData(displaySearch);
    }
  };
  return (
    <section className={classes.playlist}>
      <div className={classes.heading}>
        <h1 className={classes.title}>All playlists</h1>
        {role === ROLE_PUBLISHER && (
          <Button
            className="button"
            variant="contained"
            onClick={() => {
              setOpenModal(true);
              setIsEdit(false);
              setPlaylistType("manual");
            }}
          >
            New Playlist
          </Button>
        )}
      </div>
      <div className={classes.searching}>
        <FormControl>
          <InputLabel htmlFor="search-playlists">
            Search playlist by name
          </InputLabel>
          <Input
            id="search-playlists"
            type="text"
            value={displaySearch}
            onChange={(e) => ChangeSearch(e)}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => getData(displaySearch)}>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => {
            reset(), setShowSearch(false);
          }}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      {isLoaded && rows && !rows.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <PlaylistsTable
            data={rows}
            search={showSearch ? search : ""}
            onDelete={setUserDelete}
            modalDelete={setOpenDeleteModal}
            onSubmit={openModalEmded}
            isPublisher={role === ROLE_PUBLISHER}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT_NAME),
                getData(
                  displaySearch,
                  "name",
                  10,
                  Number(querypaginationNumber),
                  e || SORT_DEFAULT_NAME
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={lenght}
            loadData={(value) => getData(displaySearch, "name", 10, value)}
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}

      <PlaylistsModal
        open={openModal}
        setOpen={setOpenModal}
        playlistType={playlistType}
        edit={isEdit}
        createPlaylist={onSubmit}
      />

      <EmbedCodeModal
        open={isOpenModalEmbed}
        playlist={playlist}
        type={
          playlistType === EmbedCodeTypes.SMART
            ? EmbedCodeTypes.SMART
            : EmbedCodeTypes.MANUAL
        }
        setOpen={setIsOpenModalEmbed}
        players={players}
        channels={channels}
      />
      <Snackbar
        open={openAlert.isOpen}
        autoHideDuration={4000}
        onClose={() =>
          setOpenAlert({
            text: "",
            isOpen: false,
            type: "success",
          })
        }
        className={classes.snackbar}
      >
        {openAlert.type === "success" ? (
          <MuiAlert severity="success">{openAlert.text}</MuiAlert>
        ) : (
          <MuiAlert severity="error">{openAlert.text}</MuiAlert>
        )}
      </Snackbar>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={userDelete}
        onDelete={(data) => onDelete(data)}
        header="Playlist"
      />
    </section>
  );
};
