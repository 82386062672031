import React, { useEffect, useState, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { SaveIcon } from "../../assets/image/save-icon";
import {
  TextField,
  Button,
  Menu,
  MenuItem,
  Chip,
  Paper,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import { getLanguage } from "../../languages";
import { getErrorsHandler } from "../../errors/video";
import { useStyles } from "./hooks/useStyles";
import { getAllPlaylists } from "../../api/all-playlists";
import { PropsParamaters } from "./video.interface";
import { RootState } from "../../init/rootReducer";
import {
  getVideo,
  deleteVideo,
  updateVideo,
  updateVideoImage,
} from "../../api/videos";
import { EmbedCodeModal } from "../../components/video-embed-modal";
import { AddPlaylistModal } from "../../components/add-playlist-modal";
import { DeleteModal } from "../../components/delete-modal";
import ChipInput from "material-ui-chip-input";
import { EmbedCodeTypes } from "../../declarations/pages";
import { ROLE_PUBLISHER, GeoFiltering } from "../../init/constants";
import { extractUuid } from "../../utils/extractUuid";
import ImageField from "./ImageField";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const truncateLongString = (str: string, maxLength: number) =>
  str.length > maxLength ? str.slice(0, maxLength - 1) + "…" : str;

export const VideoDetails = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { location } = history;
  const { id } = useParams<PropsParamaters>();
  const [urlLng, setUrlLng] = useState("/en");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [openPlaylistModal, setOpenPlaylistModal] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [video, setModalVideo] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    name: "",
    thumbnailUrl: "",
    posterUrl: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [event, setEvent] = useState({
    title: "",
    isError: false,
  });
  const [customThumbnail, setCustomThumbnail] = useState(null);
  const [customPoster, setCustomPoster] = useState(null);
  const [values, setValues] = useState({
    assetUrl: null,
    createdDate: null,
    description: "",
    expireTime: null,
    id: "",
    name: "",
    playlists: [],
    publishedTime: null,
    publisherId: null,
    right: {
      allowToOverride: true,
      availability: {
        allowToOverrideAvailability: false,
        publishDate: "",
        publishDateOffsetHours: null,
        takedownDate: null,
        takedownOffsetDays: null,
      },
      blacklistedDomains: [],
      brightcoveRestrictionId: "",
      countries: [],
      description: null,
      filtering: 1,
      id: "",
      rightsHolder: {
        id: "",
        title: "",
      },
      rightsHolderId: "",
      title: "",
      whitelistedDomains: [],
    },
    status: "",
    tags: [],
    updatedDate: null,
    account_id: "",
  });
  const { isAdmin, role } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(userActions.setLink("content"));
    getData(id);
  }, []);

  const handleDelete = (playlistId: string) => () => {
    setValues({
      ...values,
      playlists: values.playlists.filter(
        (chip) => chip.playlistId !== playlistId
      ),
    });
  };

  const getPlayList = async (
    query = "MANUAL",
    name = "type",
    limit = 10,
    start = 0
  ) => {
    const res = await getAllPlaylists(query, name, limit, start);
    setPlaylists(res.playlists);
  };

  const getData = async (videoId: string) => {
    const result = await getVideo(videoId);

    setData(result);
    setValues({ ...result, tags: result.tags?.split(",") || [] });
  };

  const handleAddTag = (chip: string) =>
    setValues({ ...values, tags: [...values.tags, chip] });

  const handleDeleteTag = (chip: string) =>
    setValues({
      ...values,
      tags: values.tags.filter((item) => item !== chip),
    });

  const handleChange = <T extends keyof typeof values>(
    prop: T,
    value: (typeof values)[T]
  ) => setValues((prev) => ({ ...prev, [prop]: value }));

  const openEmbedCodeModal = async (data) => {
    setAnchorEl(false);
    setModalVideo(data);
    setIsOpenModalEmbed(true);
  };

  useEffect(() => {
    setUrlLng(getLanguage(location.pathname));
  }, [location]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const onDelete = async (data) => {
    const response = await deleteVideo(data.id);
    if (typeof response === "boolean") {
      setOpenDeleteModal(false);
      history.push(`${urlLng}/all-videos`);
    } else {
      setOpenDeleteModal(true);
      setError(getErrorsHandler(response).title);
    }
  };

  const onSubmit = async () => {
    if (customPoster) {
      await updateVideoImage(
        values.id,
        extractUuid(data.posterUrl),
        customPoster,
        "poster"
      );
    }

    if (customThumbnail) {
      await updateVideoImage(
        values.id,
        extractUuid(data.thumbnailUrl),
        customThumbnail,
        "thumbnail"
      );
    }

    const response = await updateVideo(values.id, {
      ...values,
      tags: values.tags.join(),
    });

    if (typeof response === "number") {
      setOpenAlert(true);
      setEvent({ title: getErrorsHandler(response).title, isError: true });
    } else {
      setData(response);
      setEvent({ title: "Videos updated", isError: false });
      setOpenAlert(true);
    }
  };

  const playlistsManual = values.playlists.filter(
    (e) => e.playlist.type === "MANUAL"
  );

  const thumbnailSrc = customThumbnail
    ? URL.createObjectURL(customThumbnail)
    : data.thumbnailUrl;

  const posterUrl = customPoster
    ? URL.createObjectURL(customPoster)
    : data.posterUrl;

  return (
    <section className={classes.videodetails}>
      <Link to={`${urlLng}/all-videos`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Content
      </Link>

      <Box sx={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
        <div className={classes.heading}>
          <h1 className={classes.title}>{data?.name}</h1>

          <div>
            <Button title="Save" className={`button-white`} onClick={onSubmit}>
              <SaveIcon />
            </Button>
            {isAdmin && (
              <Button
                className={`button-white`}
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon />
              </Button>
            )}
            <Button className={`button-white`} onClick={handleClick}>
              <MoreVertIcon
                aria-controls={"simple-menu-" + urlLng}
                aria-haspopup="true"
              />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.popItemMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  setOpenPlaylistModal(true);
                  getPlayList("", "", 5, 0);
                }}
                className={classes.popItem}
              >
                Add to a playlist
              </MenuItem>
              {role === ROLE_PUBLISHER && (
                <MenuItem
                  onClick={() => openEmbedCodeModal(data)}
                  className={classes.popItem}
                >
                  Create embed code
                </MenuItem>
              )}
            </Menu>
          </div>
        </div>
        <p className={classes.paragraphTitle}>
          ID <span>{id}</span>
        </p>
      </Box>

      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 2fr)" }}>
        <Box
          component="form"
          sx={{
            pr: 3,
          }}
        >
          <Box sx={{ mb: 3 }}>
            <TextField
              className={`${classes.input} ${classes.label}`}
              variant="outlined"
              id="Name"
              label="Name"
              maxRows={1}
              value={values?.name}
              fullWidth
              disabled={role === ROLE_PUBLISHER}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <TextField
              onChange={(e) => handleChange("description", e.target.value)}
              className={`${classes.input} ${classes.label}`}
              variant="outlined"
              id="short-description"
              multiline
              minRows={4}
              placeholder="Short description"
              fullWidth
              value={values?.description}
              inputProps={{ maxLength: 250 }}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <ChipInput
              className={`${classes.input} ${classes.label}`}
              fullWidth
              variant="outlined"
              label="Tags"
              value={values.tags}
              onAdd={handleAddTag}
              onDelete={handleDeleteTag}
            />
          </Box>

          <Paper component="ul" className={`${classes.paper}`}>
            <span className={classes.titleInput}>Manual playlist</span>
            {playlistsManual?.map((data) => (
              <div key={data.playlistId}>
                <Chip
                  label={truncateLongString(data.playlist.name, 50)}
                  onDelete={handleDelete(data.playlistId)}
                />
              </div>
            ))}
          </Paper>
        </Box>
        <Box
          className={classes.video}
          component="form"
          sx={{
            pr: 3,
          }}
        >
          {values?.account_id && values?.id && (
            <ReactPlayerLoader
              accountId={values?.account_id}
              videoId={values?.id}
            />
          )}
        </Box>
      </Box>
      <h4 className={classes.headImage}>Images</h4>
      <p className={classes.paragraph}>
        For best results, use JPG or PNG format with a minimum width of 640px
        for posters and 160px for
        <br /> thumbnails. Aspect ratios should match the video, generally 16:9
        or 4:3.
      </p>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 2fr)",
        }}
      >
        <Box
          component="div"
          sx={{
            pr: 3,
          }}
        >
          <ImageField
            headerText="Poster"
            src={posterUrl}
            onChange={setCustomPoster}
          />
        </Box>
        <Box
          component="div"
          sx={{
            pr: 3,
          }}
        >
          <ImageField
            headerText="Thumbnail"
            src={thumbnailSrc}
            onChange={setCustomThumbnail}
          />
        </Box>
      </Box>
      {isAdmin && values.right && (
        <>
          <h3>Availability/Ownership</h3>
          <div className={classes.paragraph}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                className={classes.input}
                id="start-date"
                inputVariant="outlined"
                label="Start Date"
                onChange={(e) => handleChange("publishedTime", e)}
                value={
                  values?.publishedTime ||
                  values?.right?.availability.publishDate ||
                  null
                }
                format="yyyy/MM/dd, HH:mm"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.paragraph}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                id="end-date"
                className={classes.input}
                inputVariant="outlined"
                label="End Date"
                onChange={(e) => handleChange("expireTime", e)}
                value={
                  values?.expireTime ||
                  values?.right?.availability?.takedownDate ||
                  null
                }
                format="yyyy/MM/dd, HH:mm"
              />
            </MuiPickersUtilsProvider>
          </div>
          {values?.right?.countries?.join(", ") ? (
            <>
              <h4 className={classes.head}>
                {values?.right?.filtering === GeoFiltering.AllowViewing
                  ? "Allow viewing from the following countries"
                  : "Prevent viewing from the following countries"}
              </h4>
              <p className={classes.paragraph}>
                {values?.right?.countries?.join(", ")}
              </p>{" "}
            </>
          ) : (
            ""
          )}

          <h4 className={classes.head}>Allowed Domains</h4>
          <p className={classes.paragraph}>
            {values?.right?.whitelistedDomains?.length
              ? values?.right?.whitelistedDomains?.join(", ")
              : "None"}
          </p>

          <h4 className={classes.head}>Prevented Domains</h4>
          <p className={classes.paragraph}>
            {values?.right?.blacklistedDomains?.length
              ? values?.right?.blacklistedDomains?.join(", ")
              : "None"}
          </p>

          <h4 className={classes.head}>Rights</h4>
          <p className={classes.paragraph}>{values?.right?.title}</p>

          <h4 className={classes.head}>Rightsholder</h4>
          <p className={classes.paragraph}>
            {values?.right?.rightsHolder?.title || "None"}
          </p>
        </>
      )}
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity={event.isError ? "error" : "success"}>
          {event.title}
        </MuiAlert>
      </Snackbar>
      <EmbedCodeModal
        open={isOpenModalEmbed}
        video={video}
        type={EmbedCodeTypes.SINGLE}
        setOpen={setIsOpenModalEmbed}
      />
      <AddPlaylistModal
        open={openPlaylistModal}
        setOpen={setOpenPlaylistModal}
        playlists={playlists}
        videoId={id}
        setSearchPlaylist={getPlayList}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={data}
        error={error}
        onDelete={onDelete}
        header="Video"
      />
    </section>
  );
};
