import React, { useEffect, useState, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import ReplayIcon from "@material-ui/icons/Replay";
import { Link, useHistory } from "react-router-dom";
import { getVideos } from "../../api/videos";
import { RenderPagination } from "../../components/pagination";
import { AllVideoTable } from "../../components/all-video-table";
import { getLanguage } from "../../languages";
import { SMALL_DEFAULT_IMAGE, SORT_DEFAULT_CREATE } from "../../init/constants";
import moment from "moment";

import { useStyles } from "./hooks/useStyles";
import Search from "@material-ui/icons/Search";
import { Video, DBVideo } from "../../declarations/pages";
import { RootState } from "../../init/rootReducer";

export const AllVideos = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = history;
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [urlLng, setUrlLng] = useState("/en");
  const [rows, setRows] = useState<Video[]>([]);
  const [isLoaded, setInitialData] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [resetShort, setResetShort] = useState("");
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT_CREATE);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { role } = useSelector((state: RootState) => state.user);
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );
  useEffect(() => {
    dispatch(userActions.setLink("content"));
  }, []);

  const onDelete = async (data) => {
    if (data) {
      const result = rows.filter((item) => item.id !== data.id);
      setRows(result);
    }
  };

  const reset = async () => {
    if (displaySearch) {
      setDisplaySearch("");
    }
    getData("");
    setCurrentPage(0);
    setOrder(SORT_DEFAULT_CREATE);
    setResetShort("reset");
  };

  const searchVideo = async (query) => {
    getData(query);
  };

  const hadleSearchChange = (e) => {
    setDisplaySearch(e.target.value);
  };

  const getData = async (
    query,
    name = "name",
    limit = 10,
    start = 0,
    orderDefault = SORT_DEFAULT_CREATE
  ) => {
    const array = [];
    const data = await getVideos(
      start * 10,
      query,
      name,
      limit,
      !order ? order : orderDefault
    );
    data.videos.forEach((item: DBVideo) => {
      array.push({
        img: SMALL_DEFAULT_IMAGE,
        name: item.name,
        status: item.status,
        id: item.id,
        created: item.createdDate,
        updated: item.updatedDate,
      });
    });
    setQueryPaginationNumber(start);
    setRows(array);
    setCount(data.total);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  useEffect(() => {
    getData(displaySearch);
  }, [role]);

  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getData(displaySearch);
    }
  };

  return (
    <section className={classes.playlist}>
      <Link to={`${urlLng}`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Content
      </Link>
      <div className={classes.heading}>
        <h1 className={classes.title}>All Videos</h1>
      </div>
      <div className={classes.searching}>
        <FormControl className={classes.input}>
          <InputLabel htmlFor="search-videos">Search videos</InputLabel>
          <Input
            id="search-videos"
            type="text"
            value={displaySearch}
            onChange={hadleSearchChange}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <InputAdornment
                position="end"
                onClick={() => searchVideo(displaySearch)}
              >
                <IconButton>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={reset}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      {isLoaded && rows && !rows.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <AllVideoTable
            data={rows}
            urlLng={urlLng}
            onDelete={(data) => onDelete(data)}
            role={role}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT_CREATE),
                getData(
                  displaySearch,
                  "name",
                  10,
                  Number(querypaginationNumber),
                  e || SORT_DEFAULT_CREATE
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={count}
            loadData={(value) => getData(displaySearch, "name", 10, value)}
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}
    </section>
  );
};
