import { types } from "./types";
import { SemanticArticle, Video } from "../../declarations/pages";

interface SemanticArticlePlaylist {
  article: SemanticArticle;
  videos: Video[];
}

const initialState = {
  article: {},
  videos: [],
};

export const semanticArticleReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case types.DELETE_VIDEO:
      return {
        videos: state.videos.filter((el) => el.id !== payload),
        article: { ...state.article, locked: true },
      };
    case types.LOAD_DATA:
      return { ...payload };
    case types.HIDE:
      return {
        videos: state.videos,
        article: { ...state.article, hide: payload },
      };
    case types.LOCK:
      return {
        videos: state.videos,
        article: { ...state.article, locked: payload },
      };
    default:
      return state;
  }
};
