const errorsHandler = {
  500: {
    title: "we have a problem, try again",
  },
  404: {
    title: "Page Not found",
  },
};

export const getErrorsHandler = (id) => {
  return errorsHandler[id] ? errorsHandler[id] : "Internal Server Error";
};
