// @ts-nocheck
var { ParamParser } = require("./param-parser");
var { postJson } = require("./post-json");
var { VideoUpload } = require("./video-upload");
var { UIRoot } = require("./components/root");
var defaultPreviewAction =
  require("./components/default-preview").defaultPreviewAction;

var { deleteVideo, updateVideo } = require("../api/videos");

var noop = function () {};

function BCUploader(params) {
  // Protect against forgetting the new keyword when instantiating objects
  if (!(this instanceof BCUploader)) {
    return new BCUploader(params);
  }

  var param = ParamParser("BCUploader", params);

  // required parameters
  this.urls = {
    createVideoEndpoint: param.required("createVideoEndpoint"),
    signUploadEndpoint: param.required("signUploadEndpoint"),
    ingestUploadEndpoint: param.required("ingestUploadEndpoint"),
  };
  this.root = param.required("root");

  // optional callbacks
  this.callbacks = {
    onProgress: param.optional("onProgress", noop),
    onStarted: param.optional("onStarted", noop),
    onComplete: param.optional("onComplete", noop),
    onUploadInitiated: param.optional("onUploadInitiated", noop),
    onError: param.optional("onError", noop),

    // onFileSelected MUST be a promise
    onFileSelected: param.optional("onFileSelected", function () {
      return Promise.resolve();
    }),
  };

  // video settings
  this.publishedTime = param.optional("publishedTime", null);
  this.expireTime = param.optional("expireTime", null);
  this.tags = param.optional("tags", []);
  this.right = param.optional("right", null);

  // wire up the UI and wait for user interaction
  this.landingText = param.optional(
    "landingText",
    "Videos are available to edit in the <b>Content Section</b> when the upload is complete. Please only use the following formats: <b>mp4</b>, <b>m4a</b>, <b>m4v</b>, <b>f4v</b>, <b>f4a</b>, <b>m4b</b>, <b>m4r</b>, <b>f4b</b>"
  );
  this.uploadText = param.optional("uploadText", "Upload Videos");
  this.orText = param.optional("orText", "Or");
  this.createVideoFailureText = param.optional(
    "createVideoFailureText",
    "Oops! We weren't able to upload your video to our servers. Try a different video or try again later."
  );
  this.cancelUploadVideoFailureText = param.optional(
    "cancelUploadVideoFailureText",
    "Oops! We weren't able to cancel video upload"
  );
  this.saveVideoFailureText = param.optional(
    "saveVideoFailureText",
    "Oops! We weren't able to save video"
  );
  this.setupUI();

  // Video UI config
  this.videoUI = {
    previewText: param.optional("preivewText", "Preview"),
    updatePreview: this.ui.preview.update.bind(this.ui.preview),
    onDelete: this.onDelete.bind(this),
    onSave: this.onSave.bind(this),
    onPreview: param.optional("onPreview", defaultPreviewAction),
    playerId: param.optional("playerId", "default"),
    rootElement: this.rootElement,
    transcodingDelayMS: param.optional("transcodingDelayMS", 10000),
    transcodingText: param.optional("transcodingText", "Transcoding"),
  };

  // optional evaporate overrides
  this.logging = param.optional("logging", false);
  this.overrides = param.optional("evaporate", {});
}

BCUploader.prototype.setupUI = function setupUI() {
  var videoFormatsWhiteList = [
    "mp4",
    "m4a",
    "m4v",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
  ];
  var self = this;
  this.rootElement = document.getElementById(this.root);
  this.ui = new UIRoot({
    landingText: this.landingText,
    uploadText: this.uploadText,
    orText: this.orText,
    onFileSelected: function (file) {
      const videoFormat = file.name.slice(
        (Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1
      );
      if (!videoFormatsWhiteList.includes(videoFormat)) {
        self.ui.showError(self.createVideoFailureText);
      } else {
        self.callbacks.onFileSelected(file).then(function () {
          self.ui.error.hidden = true;
          self.createVideo(file);
        });
      }
    },
  });

  this.render();
};

BCUploader.prototype.render = function render() {
  this.rootElement.innerHTML = "";
  this.rootElement.appendChild(this.ui.render());
};

BCUploader.prototype.onDelete = function onDelete(data) {
  var self = this;
  deleteVideo(data.videoId, data.accountId).then(function (response) {
    if (typeof response == "boolean") {
      self.ui.removeVideo(data);
    } else {
      self.ui.showError(self.cancelUploadVideoFailureText);
    }
  });
};

BCUploader.prototype.onSave = function onSave(data, params) {
  var self = this;

  params.tags = self.tags.join(",");
  params.publishedTime = self.publishedTime;
  params.expireTime = self.expireTime;
  params.right = self.right;
  updateVideo(data.videoId, params).then(function (response) {
    if (response && response.id) {
      self.ui.removeVideo(data);
    } else {
      self.ui.showError(self.saveVideoFailureText);
    }
  });
};

BCUploader.prototype.createVideo = function createVideo(file) {
  var self = this;
  return postJson(this.urls.createVideoEndpoint, {
    name: file.name,
    right: self.right,
  })
    .catch(function () {
      self.ui.showError(self.createVideoFailureText);
    })
    .then(function (response) {
      var params = Object.assign(response, self.callbacks, self.urls, {
        logging: self.logging,
        file: file,
        ui: self.videoUI,
        overrides: self.overrides,
        tags: self.tags,
      });
      var video = new VideoUpload(params);
      self.ui.addVideo(video.ui);
      return video;
    });
};

export default BCUploader;
