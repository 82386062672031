import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { useStyles } from "./hooks/useStyles";

export const PopupPosition = ({ value, setValue }: any) => {
  const classes = useStyles();
  const handleChange = (event) => {
    setValue(event, "popupPosition");
  };

  function StyledRadio(props) {
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)}>
            <CheckIcon />
          </span>
        }
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  return (
    <div>
      <h3>Popup Position</h3>
      <FormControl component="fieldset">
        <RadioGroup
          value={value}
          className={classes.radioGroup}
          aria-label="gender"
          name="customized-radios"
          onChange={handleChange}
        >
          <FormControlLabel
            className={`${classes.label} ${
              value === "topLeft" ? classes.active : ""
            }`}
            value="topLeft"
            control={<StyledRadio />}
            label="Top Left"
          />
          <FormControlLabel
            className={`${classes.label} ${
              value === "topRight" ? classes.active : ""
            }`}
            value="topRight"
            control={<StyledRadio />}
            label="Top Right"
          />
          <FormControlLabel
            className={`${classes.label} ${
              value === "topFullWidth" ? classes.active : ""
            }`}
            value="topFullWidth"
            control={<StyledRadio />}
            label="Top Full Width"
          />
          <FormControlLabel
            className={`${classes.label} ${
              value === "bottomLeft" ? classes.active : ""
            }`}
            value="bottomLeft"
            control={<StyledRadio />}
            label="Bottom Left"
          />
          <FormControlLabel
            className={`${classes.label} ${
              value === "bottomRight" ? classes.active : ""
            }`}
            value="bottomRight"
            control={<StyledRadio />}
            label="Bottom Right"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
