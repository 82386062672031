import React, { ReactElement, useState } from "react";
import { IconButton } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import HeightIcon from "@material-ui/icons/Height";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { PropsSorter } from "./sorter.interface";

export const SortArray = ({
  sort,
  setSort,
  type,
  setSortOwn,
  sortOwn,
}: PropsSorter): ReactElement => {
  const handleSortClick = (sortType) => {
    if (sortOwn && !sortType) {
      setSortOwn("");
      return;
    }
    if (!sortOwn && !sortType) {
      setSortOwn(`ASC${type}`);
      setSort(`ASC`);
      return;
    }

    if (`${sortOwn}` === `DESC${type}`) {
      setSortOwn(`ASC${type}`);
      setSort(`ASC`);
      return;
    }

    if (`${sortOwn}` === `ASC${type}`) {
      setSortOwn(`DESC${type}`);
      setSort(`DESC`);
      return;
    }
  };
  return (
    <div style={{ cursor: "pointer" }}>
      {sortOwn === `ASC${type}` ? (
        <ArrowUpwardIcon onClick={() => handleSortClick("ASC")} />
      ) : sortOwn === `DESC${type}` ? (
        <ArrowDownwardIcon onClick={() => handleSortClick("DESC")} />
      ) : (
        <HeightIcon onClick={() => handleSortClick("")} />
      )}
    </div>
  );
};
