import { combineReducers } from "redux";

import { userReducer as user } from "../bus/user/reducer";
import { semanticArticleReducer as semanticArticle } from "../bus/semantic-article/reducer";

export const rootReducer = combineReducers({
  user,
  semanticArticle,
});

export type RootState = ReturnType<typeof rootReducer>;
