import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
    color: "#000000",
    marginBottom: "32px",
  },
  button: {
    width: "100%",
    maxWidth: "425px",
  },
  block: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttonBlack: {
    width: "100%",
    maxWidth: "425px",
    marginLeft: "32px",
  },
  pagination: {
    "& .Mui-selected": {
      background: "black",
      color: "white",
      borderRadius: 0,
    },
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  "@media screen and (min-width: 1440px)": {
    button: {
      maxWidth: "256px",
      marginBottom: "20px",
      backgroundColor: "#E4E4E4 !important",
      color: "#000 !important",
    },
    buttonBlack: {
      maxWidth: "256px",
      marginBottom: "20px",
    },
    pagination: {
      marginBottom: 20,
    },
  },
}));
