import { Box, Button } from "@material-ui/core";
import { OpticIcon } from "../../assets/image/optic-icon";
import { UploadIcon } from "../../assets/image/upload-icon";
import { useStyles } from "./hooks/useStyles";

const ImageField = ({
  onChange,
  src,
  headerText,
}: {
  headerText: string;
  onChange: (file: File) => void;
  src: string;
}) => {
  const classes = useStyles();

  return (
    <>
      <p className={classes.paragraph}>{headerText}</p>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <img src={src} alt={headerText} className={classes.img} />

        <div className={classes.contentIcons}>
          <Button type="button" className={`button-white`}>
            <label htmlFor={`${headerText}-upload`}>
              <UploadIcon />
            </label>
          </Button>

          <input
            id={`${headerText}-upload`}
            name={`${headerText}-upload`}
            type="file"
            aria-label={"Upload " + headerText}
            style={{ display: "none" }}
            accept="image/png, image/jpeg, image/jpg"
            onChange={(e) => {
              onChange(e.target.files[0]);
            }}
          />

          <a href={src} target="_blank" rel="noreferrer noopener">
            <Button className={`button-white`}>
              <OpticIcon />
            </Button>
          </a>
        </div>
      </Box>
    </>
  );
};

export default ImageField;
