import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { book } from "./book";
import { Users } from "../pages/users";
import { Base } from "../views/base";
import { Players } from "../pages/players";
import { Playlists } from "../pages/playlists";
import { SignIn } from "../pages/singIn";
import { Content } from "../pages/content";
import { Publishers } from "../pages/publishers";
import { PublishersDetails } from "../pages/publishers-details";
import { Playlist } from "../pages/playlist";
import { AllVideos } from "../pages/all-videos";
import { VideoDetails } from "../pages/video-details";
import { PlayerDetails } from "../pages/player-details";
import { EmbedCodes } from "../pages/embed-codes";
import { SemanticEmbedCodes } from "../pages/sematic-embed-codes";
import { Channels } from "../pages/channels";
import { CreateChannel } from "../pages/create-channel";
import { ChannelReadOnly } from "../pages/channel";
import { Rights } from "../pages/rights";
import { RightReadOnly } from "../pages/right";
import { RightCreate } from "../pages/create-rights";
import { Reporting } from "../pages/reporting";
import { VideoUpload } from "../pages/video-upload";
import { Rightsholders } from "../pages/rightsholders/index";
import { NotFoundPage } from "../pages/404";
import { SemanticArticlesPlaylist } from "../pages/semantic-articles-playlist/index";
import { SemanticArticles } from "../pages/semantic-articles";
import { EditChannel } from "../pages/edit-channel";
import { RightUpdate } from "../pages/edit-rights";
import { useHistory } from "react-router-dom";
import { getLanguage } from "../languages";
import { useSelector } from "react-redux";
import { RootState } from "../init/rootReducer";

export const Routes = () => {
  const history = useHistory();
  const { location } = useHistory();
  const [urlLng, setUrlLng] = useState("/en");
  const { isAdmin } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const language = getLanguage(location.pathname);

    if (language === null) {
      Object.values(book).filter((item) => item === location.pathname).length
        ? history.push(`/en${location.pathname}`)
        : history.push(`/en`);
    }

    setUrlLng(language);
  }, [location, history]);

  return (
    <>
      <Switch>
        <Route path={`${urlLng}${book.players}`} exact>
          <Base>
            <Players />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.playlists}`} exact>
          <Base>
            <Playlists />
          </Base>
        </Route>
        {isAdmin && (
          <Route path={`${urlLng}${book.users}`} exact>
            <Base>
              <Users />
            </Base>
          </Route>
        )}
        <Route path={`${urlLng}${book.content}`} exact>
          <Base>
            <Content />
          </Base>
        </Route>
        {isAdmin && (
          <Route path={`${urlLng}${book.publishers}`} exact>
            <Base>
              <Publishers />
            </Base>
          </Route>
        )}
        <Route path={`${urlLng}${book.publishersDetails}`} exact>
          <Base>
            <PublishersDetails />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.playlist}`} exact>
          <Base>
            <Playlist />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.allVideos}`} exact>
          <Base>
            <AllVideos />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.videosDetails}`} exact>
          <Base>
            <VideoDetails />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.playerDetails}`} exact>
          <Base>
            <PlayerDetails />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.embedCodes}`} exact>
          <Base>
            <EmbedCodes />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.semanticArticlesPlaylist}`} exact>
          <Base>
            <SemanticArticlesPlaylist />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.semanticArticles}`} exact>
          <Base>
            <SemanticArticles urlLng={urlLng} />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.semanticEmbedCodes}`} exact>
          <Base>
            <SemanticEmbedCodes />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.channels}`} exact>
          <Base>
            <Channels />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.createChannel}`} exact>
          <Base>
            <CreateChannel />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.editChannel}`} exact>
          <Base>
            <EditChannel />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.channelReadOnly}`} exact>
          <Base>
            <ChannelReadOnly />
          </Base>
        </Route>
        {isAdmin && (
          <Route path={`${urlLng}${book.rights}`} exact>
            <Base>
              <Rights />
            </Base>
          </Route>
        )}
        <Route path={`${urlLng}${book.rightUpdate}`} exact>
          <Base>
            <RightUpdate />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.rightReadOnly}`} exact>
          <Base>
            <RightReadOnly />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.rightCreate}`} exact>
          <Base>
            <RightCreate />
          </Base>
        </Route>
        {isAdmin && (
          <Route path={`${urlLng}${book.rightsholders}`} exact>
            <Base>
              <Rightsholders />
            </Base>
          </Route>
        )}
        <Route path={`${urlLng}${book.videoUpload}`} exact>
          <Base>
            <VideoUpload />
          </Base>
        </Route>
        <Route path={`${urlLng}${book.signIn}`} exact>
          <SignIn />
        </Route>
        <Route path={`${urlLng}${book.reporting}`} exact>
          <Base>
            <Reporting />
          </Base>
        </Route>
        <Route path="*">
          <Base>
            <NotFoundPage />
          </Base>
        </Route>
      </Switch>
    </>
  );
};
