import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import { PublishersTable } from "../../components/publishers-table";
import { PublishersModal } from "../../components/publisher-modal";
import { Button, Snackbar } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReplayIcon from "@material-ui/icons/Replay";
import { editPublisher, getPublishers } from "../../api/publishers";
import { useStyles } from "./hooks/useStyles";
import Search from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { DeleteModal } from "../../components/delete-modal";
import { getErrorsHandler } from "../../errors/publishers";
import { createPublisher, deletePublishers } from "../../api/publishers";
import { RenderPagination } from "../../components/pagination/index";
import { Publisher } from "../../declarations/pages";
import { RootState } from "../../init/rootReducer";
import { SORT_DEFAULT_NAME } from "../../init/constants";

export const Publishers = () => {
  const classes = useStyles();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [searchColumn, setSearchColumn] = useState("name");
  const [openModal, setOpenModal] = useState(false);
  const [typeModal, setTypeModal] = useState("Add");
  const [rows, setRows] = useState([]);
  const [isLoaded, setInitialData] = useState(false);
  const [lenght, setLenght] = useState(0);
  const dispatch = useDispatch();
  const [urlLng] = useState("/en");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState("");
  const [publisherDelete, setPublisherDelete] = useState("");
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [resetShort, setResetShort] = useState("");
  const [displaySearchType, setDisplaySearchType] = useState("name");
  const [showSearch, setShowSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT_NAME);
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );
  const { publishers } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    dispatch(userActions.setLink("publishers"));
    getPublisherData("", "", 10, 0, SORT_DEFAULT_NAME);
  }, []);

  const getPublisherData = async (
    query,
    name = "name",
    limit = 10,
    start = 0,
    orderDefault = SORT_DEFAULT_NAME
  ) => {
    const { publishers: responseData, total } = await getPublishers(
      query,
      name,
      limit,
      start * limit,
      !order ? order : orderDefault
    );
    setQueryPaginationNumber(start);
    setRows(responseData);
    setLenght(total);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const reset = async () => {
    setDisplaySearch("");
    setDisplaySearchType("name");
    setShowSearch(false);
    getPublisherData("", "");
    setCurrentPage(0);
    setResetShort("reset");
  };

  const openModalType = (type) => {
    setOpenModal(true);
    setTypeModal(type);
  };
  const submitCreatePublisher = async (values) => {
    const response = await createPublisher({
      ...values,
    });

    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenAlertSuccess(true);
      setOpenModal(false);
      setStatusSuccess("Created Publisher");
      getPublisherData("", "", 10, 0);

      publishers.unshift(response);
      dispatch(userActions.setPublishers(publishers));
    }
  };
  const DeleteConfirm = async (data) => {
    // eslint-disable-next-line react/prop-types
    const response = await deletePublishers(data);
    if (typeof response == "boolean") {
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      setStatusSuccess("Deleted Publisher");
      getPublisherData("");

      const deletePublisher = publishers.filter((e) => e.id !== data.id);
      dispatch(userActions.setPublishers(deletePublisher));
    } else {
      setOpenDeleteModal(true);
      setError(getErrorsHandler(response).title);
    }
  };
  const ChangeSearch = (e) => {
    setDisplaySearch(e.target.value);
  };
  const ChangeSearchType = (e) => {
    setDisplaySearchType(e.target.value);
  };
  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getPublisherData(displaySearch, displaySearchType);
    }
  };

  const updatePublisher = useCallback(
    async (publisher: Publisher) => {
      await editPublisher(publisher);

      const index = rows.findIndex((row) => row.id === publisher.id);
      rows[index] = publisher;
      setRows([...rows]);
      setOpenAlertSuccess(true);
      setStatusSuccess(
        `Publisher ${publisher.name} has been ${
          publisher.isBlocked ? "blocked" : "unblocked"
        }`
      );
    },
    [rows]
  );

  return (
    <section className={classes.content}>
      <div className={classes.heading}>
        <h1 className={classes.title}>All publishers</h1>
        <Button
          className={`${classes.buttonAdd} button`}
          variant="contained"
          onClick={() => {
            openModalType("Add");
          }}
        >
          Add publisher
        </Button>
      </div>
      <div className={classes.searching}>
        <FormControl className={classes.search}>
          <InputLabel htmlFor="search-publishers">Search publishers</InputLabel>
          <Input
            id="search-publishers"
            type="text"
            value={displaySearch}
            onChange={(e) => ChangeSearch(e)}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    getPublisherData(displaySearch, displaySearchType)
                  }
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.select}>
          <InputLabel id="demo-simple-select-outlined-label">
            Search by
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Search by"
            value={displaySearchType}
            onChange={(e) => ChangeSearchType(e)}
          >
            <MenuItem value="name">Publisher</MenuItem>
            <MenuItem value="email">Email</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => reset()}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={publisherDelete}
        header="Publisher"
        onDelete={(data) => DeleteConfirm(data)}
      />

      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
      >
        <MuiAlert severity="success">{statusSuccess}</MuiAlert>
      </Snackbar>
      {isLoaded && rows && !rows.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <PublishersTable
            data={rows}
            searchColumn={searchColumn}
            modalDelete={() => setOpenDeleteModal(true)}
            onDelete={setPublisherDelete}
            onUpdate={updatePublisher}
            openModalType={(e) => openModalType(e)}
            urlLng={urlLng}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT_NAME),
                getPublisherData(
                  displaySearch,
                  "name",
                  10,
                  Number(querypaginationNumber),
                  e || SORT_DEFAULT_NAME
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={lenght}
            loadData={(value) =>
              getPublisherData(displaySearch, "name", 10, value)
            }
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}
      <PublishersModal
        open={openModal}
        setOpen={setOpenModal}
        type={typeModal}
        newPublisher={submitCreatePublisher}
      />
    </section>
  );
};
