import { getAxiosInstance } from "./api";

export const getAllPlaylists = async (
  value,
  type,
  limit = 10,
  start = 0,
  sort = "",
  kind
) => {
  const axios = getAxiosInstance();
  let query = value && type ? `q=${type}:${value}` : "";
  query = kind ? `${query}&${kind}` : query;

  const params = {
    method: "GET",
    url: `/playlists?limit=${limit}&start=${start}&${query}&sort=${sort}`,
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getAllPlaylists] error", error);
    return error.response.status;
  }
};

export const getPlaylist = async (id) => {
  const axios = getAxiosInstance();
  const params = {
    method: "GET",
    url: `/playlists/${id}`,
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getPlaylist] error", error.response);
    return error.response.status;
  }
};

export const deleteAllPlaylists = async (id) => {
  const axios = getAxiosInstance();
  const params = {
    method: "DELETE",
    url: `/playlists/${id}`,
  };
  try {
    await axios(params);
    return true;
  } catch (error) {
    console.error("[deleteAllPlaylists] error", error);
    return null;
  }
};

export const addVideoPlaylists = async (videoId, playlistId) => {
  const axios = getAxiosInstance();
  const params = {
    method: "PUT",
    url: `/playlists/${playlistId}/videos/${videoId}`,
  };
  try {
    await axios(params);
    return true;
  } catch (error) {
    console.error("[addVideoToPlaylists] error", error.response);
    return error.response.status;
  }
};

export const deleteVideoPlaylists = async (videoId, playlistId) => {
  const axios = getAxiosInstance();
  const params = {
    method: "DELETE",
    url: `/playlists/${playlistId}/videos/${videoId}`,
  };
  try {
    await axios(params);
    return true;
  } catch (error) {
    console.error("[addVideoToPlaylists] error", error.response);
    return error.response.status;
  }
};

export const deletePlaylist = async (data) => {
  const axios = getAxiosInstance();
  const params = {
    method: "DELETE",
    url: `/playlists/${data.id}`,
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getAllPlaylists] error", error.response);
    return error.response.status;
  }
};

export const createPlaylist = async (value) => {
  const axios = getAxiosInstance();
  const params = {
    method: "POST",
    url: `/playlists`,
    data: JSON.stringify(value),
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[createPlaylist] error", error);
    return error.response.status;
  }
};

export const editPlaylist = async (value) => {
  const axios = getAxiosInstance();
  const params = {
    method: "PUT",
    url: `/playlists/${value.id}`,
    data: JSON.stringify(value),
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[editPlaylist] error", error);
    return error.response.status;
  }
};
