import React from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import { useStyles } from "../hooks/useStyles";

export const AdditionalContent = ({ value, setValue, fallback }: any) => {
  const classes = useStyles();

  const handleChange = (e, type) => {
    setValue(e, type);
  };

  return (
    <div>
      <h3>Additional Content</h3>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Type"
          name="type"
          value={value}
          onChange={(e) => handleChange(e, "additional")}
          className={classes.group}
        >
          <FormControlLabel
            value="autoPlay"
            control={<Radio />}
            label="Auto play"
          />
          <FormControlLabel
            value="userDriven"
            control={<Radio />}
            label="User driven"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
