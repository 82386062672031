import React, { useState, useEffect, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Snackbar } from "@material-ui/core";
import { getLanguage } from "../../languages";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { EditIcon } from "../../assets/image/edit-icon";
import { PublishIcon } from "../../assets/image/publish-icon";
import { ChannelsTableVideos } from "../../components/channels-table-videos";
import { getErrorsHandler } from "../../errors/channels";
import { getChannel, getChannelVideos, deleteChannel } from "../../api/chanels";
import MuiAlert from "@material-ui/lab/Alert";
import { useStyles } from "./hooks/useStyles";
import { RootState } from "../../init/rootReducer";
import { DeleteModal } from "../../components/delete-modal";
import { Channel, EmbedCodeTypes } from "../../declarations/pages";
import { EmbedCodeModal } from "../../components/video-embed-modal";
import { ROLE_ADMIN } from "../../init/constants";
import { RenderPagination } from "../../components/pagination/index";
import { PAGINATION_PER_PAGE } from "../../init/constants";

export const ChannelReadOnly = (): ReactElement => {
  const classes = useStyles();
  const [urlLng, setUrlLng] = useState("/en");
  const { location } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isLoaded, setInitialData] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState("");
  const [rows, setRows] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [channel, setChannel] = useState<Channel>();
  const [channelVideos, setChannelVideos] = useState();
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAdmin, role } = useSelector((state: RootState) => state.user);

  const openModalEmded = async () => {
    setIsOpenModalEmbed(true);
  };

  const getChannelData = async () => {
    const data: Channel = await getChannel(id);
    setChannel(data);
    setInitialData(true);
  };

  const getChannelDataVideos = async (
    limit = PAGINATION_PER_PAGE,
    start = 0
  ) => {
    const videos = await getChannelVideos(id, limit, start * 10);
    setRows(videos.videos);
    setCount(videos.total);
    setChannelVideos(videos);
    setInitialData(true);
  };

  useEffect(() => {
    getChannelData();
    getChannelDataVideos();
    dispatch(userActions.setLink("channels"));
  }, []);

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  const deleteData = async (data) => {
    const response = await deleteChannel(data);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      history.push(`${urlLng}/channels`);
    }
  };
  return (
    <section className={classes.playlist}>
      <Link to={`${urlLng}/channels`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Channels
      </Link>
      <div className={classes.heading}>
        <div className={classes.info}>
          <h1 className={classes.title}>{channel?.name}</h1>
          ID <span>{channel?.id}</span>
        </div>
        <div>
          {role !== ROLE_ADMIN && (
            <Button className={`button-white`} onClick={openModalEmded}>
              <PublishIcon />
            </Button>
          )}
          {isAdmin && (
            <>
              <Link to={`${urlLng}/channels/${channel?.id}/edit`}>
                <Button className={`button-white`}>
                  <EditIcon />
                </Button>
              </Link>
              <Button
                className={`button-white`}
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon />
              </Button>
            </>
          )}
        </div>
      </div>
      {/* <p className={classes.description}>{channel?.description}</p> */}
      {!isLoaded && (
        <div className={"spinner"}>
          <div className={`lds-hourglass`}></div>
        </div>
      )}
      <div className={classes.content}>
        <div className={classes.block}>
          <span className={classes.information}>
            <strong>Name*</strong>
            {channel?.name}
          </span>
          <span className={classes.information}>
            <strong>Language</strong>
            {channel?.language?.name}
          </span>
        </div>
        <span className={classes.information}>
          <strong>Short Description</strong>
          {channel?.description || "None"}
        </span>
        {role === ROLE_ADMIN && (
          <>
            <h2 className={classes.subTitle}>Rights</h2>
            <span className={classes.information}>
              <strong>Selected Rights</strong>
              {channel?.rights.length
                ? channel?.rights.map(
                    (item, index) =>
                      `${item.title}${
                        channel?.rights.length === index + 1 ? "." : ", "
                      }`
                  )
                : "None"}
            </span>
            <h2 className={classes.subTitle}>Publishers</h2>
            <span className={classes.information}>
              <strong>Selected Publishers</strong>
              {channel?.publishers?.length
                ? channel?.publishers.map(
                    (item, index) =>
                      `${item?.name}${
                        channel?.publishers.length === index + 1 ? "." : ", "
                      }`
                  )
                : "None"}
            </span>
          </>
        )}
        {isLoaded && rows && !rows.length ? (
          <div className={classes.noResults}>No results</div>
        ) : (
          <>
            <ChannelsTableVideos data={rows} role={role} />
            <RenderPagination
              limit={10}
              currentPage={0}
              count={count}
              loadData={(value) => getChannelDataVideos(10, value)}
            />
          </>
        )}
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={channel}
        onDelete={(data) => deleteData(data)}
        header="Channel"
      />
      <EmbedCodeModal
        open={isOpenModalEmbed}
        channel={channel}
        type={EmbedCodeTypes.CHANNEL}
        setOpen={setIsOpenModalEmbed}
      />
    </section>
  );
};
