import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import { PlayersTable } from "../../components/players-table";
import {
  FormControl,
  Snackbar,
  Button,
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ReplayIcon from "@material-ui/icons/Replay";
import { PlayersModal } from "../../components/players-modal";
import { DeleteModal } from "../../components/delete-modal";
import { getPlayers, createPlayer, deletePlayer } from "../../api/players";
import { useStyles } from "./hooks/useStyles";
import Search from "@material-ui/icons/Search";
import MuiAlert from "@material-ui/lab/Alert";
import { RenderPagination } from "../../components/pagination/index";
import { RootState } from "../../init/rootReducer";
import { AxiosError } from "axios";
import { ErrorApiResponse, errorDict } from "../../declarations/pages";
import { ROLE_PUBLISHER, SORT_DEFAULT_CREATE } from "../../init/constants";

export const Players = () => {
  const classes = useStyles();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );
  // TODO
  const playerInitialStyle = {
    styling: "Standard",
    branding: "None",
    video: "autoPlay",
    isAudio: "off",
    additional: "autoPlay",
  };
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [showSearch, setShowSearch] = useState(false);
  const [isLoaded, setInitialData] = useState(false);
  const [search, setSearch] = useState("");
  const [textSuccess, setTextSuccess] = useState("");
  const [resetShort, setResetShort] = useState("");
  const [rows, setRows] = useState([]);
  const [playerDelete, setPlayerDelete] = useState();
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [urlLng] = useState("/en");
  const [error, setError] = useState("");
  const [lenght, setLenght] = useState(0);
  const [textDelete, setTextDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT_CREATE);
  const dispatch = useDispatch();
  const { publisher, role } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(userActions.setLink("players"));
  }, []);

  const getPlayersData = async (
    search,
    name = "name",
    perPage = 10,
    page = 0,
    orderDefault = SORT_DEFAULT_CREATE
  ) => {
    const { players, total } = await getPlayers(
      search,
      name,
      perPage,
      page,
      !order ? order : orderDefault
    );
    setQueryPaginationNumber(page);
    setRows(players);
    setLenght(total);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const createNewPlayers = async (data) => {
    data.styleData = playerInitialStyle;
    data.publisher = publisher;

    try {
      await createPlayer(data);
      setOpenAlertSuccess(true);
      setTextSuccess(`${data.name} has been created`);
    } catch (error) {
      setError(JSON.stringify(error));
    }
    getPlayersData("");
  };

  const onDelete = async (data) => {
    try {
      await deletePlayer(data);
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      getPlayersData("");
    } catch (e) {
      const response = (e as AxiosError<ErrorApiResponse>).response;
      setOpenAlert(true);
      setError(errorDict[response?.data?.customCode || "unknown"]);
    }
  };

  useEffect(() => {
    getPlayersData("");
  }, [role]);

  const handleDeletePlayer = async (data) => {
    setOpenDeleteModal(true);
    setPlayerDelete(data);
    setTextDelete(
      data.embedCodes.length
        ? "Please note this player is currently in use"
        : null
    );
    setTextSuccess("Player deleted.");
  };

  const reset = async () => {
    if (displaySearch) {
      setDisplaySearch("");
    }
    getPlayersData("");
    setCurrentPage(0);
    setOrder(SORT_DEFAULT_CREATE);
    setResetShort("reset");
  };

  const ChangeSearch = (e) => {
    setDisplaySearch(e.target.value);
  };
  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getPlayersData(displaySearch);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  return (
    <section className={classes.playlist}>
      <div className={classes.heading}>
        <h1 className={classes.title}>Players</h1>
        {role === ROLE_PUBLISHER && (
          <Button
            className={`button ${classes.newButton}`}
            variant="contained"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            New Player
          </Button>
        )}
      </div>
      <div className={classes.searching}>
        <FormControl>
          <InputLabel htmlFor="search-players">Search players</InputLabel>
          <Input
            id="search-players"
            type="text"
            value={displaySearch}
            onChange={(e) => ChangeSearch(e)}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => getPlayersData(displaySearch)}>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => {
            reset(), setShowSearch(false);
          }}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      {isLoaded && rows && !rows.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <PlayersTable
            data={rows}
            search={showSearch ? search : ""}
            handleDeletePlayer={(e) => handleDeletePlayer(e)}
            urlLng={urlLng}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT_CREATE),
                getPlayersData(
                  displaySearch,
                  "name",
                  10,
                  Number(querypaginationNumber),
                  e || SORT_DEFAULT_CREATE
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={lenght}
            loadData={(value) => getPlayersData("", "", 10, value)}
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}

      <PlayersModal
        open={openModal}
        setOpen={setOpenModal}
        createPlayer={createNewPlayers}
      />

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={playerDelete}
        onDelete={onDelete}
        header="Player"
        additionalText={textDelete}
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="success">{textSuccess}</MuiAlert>
      </Snackbar>
    </section>
  );
};
