import React, { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import { useStyles } from "../hooks/useStyles";
import moment from "moment";
import ClickAwayListener from "react-click-away-listener";
import { Link, useHistory } from "react-router-dom";
import { getLanguage } from "../../../languages";
import VideoMenu from "../../../components/video-menu";
import { Video } from "../../../declarations/pages";
import { RootState } from "../../../init/rootReducer";
import { EmbedCodeModal } from "../../../components/video-embed-modal";
import { AddPlaylistModal } from "../../../components/add-playlist-modal";

interface Props {
  data: Video;
}

const CardVideo = ({ data }: Props): ReactElement => {
  const classes = useStyles();
  const { location } = useHistory();
  const urlLng = getLanguage(location.pathname);
  const { images, name, createdDate: created_at, id } = data;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenVideoMenu, setOpenVideoMenu] = useState(false);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [isOpenPlaylistModal, setOpenPlaylistModal] = useState(false);
  const { role } = useSelector((state: RootState) => state.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenVideoMenu(true);
  };

  const onClose = (e) => {
    if (e?.target?.getAttribute("role") == "menuitem") return;
    setOpenVideoMenu(false);
    setIsOpenModalEmbed(false);
    setOpenPlaylistModal(false);
  };
  return (
    <section className={classes.cardVideo}>
      <div className={classes.information}>
        <Link to={`${urlLng}/all-videos/${id}/edit`}>
          <img src={images?.thumbnail?.src} alt="error" />
        </Link>

        <div className="text">
          <Link to={`${urlLng}/all-videos/${id}/edit`}>
            <h4>{name}</h4>
          </Link>
          <span>Created: {moment(created_at).format("DD MMM, YYYY")}</span>
        </div>
      </div>
      <Button
        className={classes.icon}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>

      {isOpenVideoMenu && (
        <ClickAwayListener
          onClickAway={({ relatedTarget }: any) => {
            setOpenVideoMenu(relatedTarget ? true : false);
          }}
        >
          <VideoMenu
            anchorEl={anchorEl}
            open={isOpenVideoMenu}
            setOpenVideoMenu={setOpenVideoMenu}
            role={role}
            setOpenPlaylistModal={() => setOpenPlaylistModal(true)}
            setIsOpenModalEmbed={() => setIsOpenModalEmbed(true)}
          />
        </ClickAwayListener>
      )}
      <EmbedCodeModal open={isOpenModalEmbed} video={data} setOpen={onClose} />
      <AddPlaylistModal
        open={isOpenPlaylistModal}
        setOpen={onClose}
        videoId={data?.id}
      />
    </section>
  );
};

export default CardVideo;
