import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  section: {
    margin: "44px 31px",
  },
  heading: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  searching: {
    display: "flex",
  },
  select: {
    width: "256px",
    margin: "0 25px",
  },
  button: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    display: "flex",
    justifyContent: "space-between",
    width: "100px",
    textTransform: "capitalize",
    "&:hover": {
      borderRadius: "0px",
    },
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  error: {
    color: "red",
  },
  pagination: {
    "& .Mui-selected": {
      background: "black",
      color: "white",
      borderRadius: 0,
    },
  },
}));
