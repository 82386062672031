import React, { Dispatch, ReactElement, SetStateAction } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "./hooks/useStyles";
import { ROLE_PUBLISHER } from "../../init/constants";

interface PropsInterface {
  anchorEl: HTMLAnchorElement;
  open: boolean;
  role?: string;
  setIsOpenModalEmbed?: Dispatch<SetStateAction<boolean>>;
  setOpenPlaylistModal?: Dispatch<SetStateAction<boolean>>;
  setOpenVideoMenu: Dispatch<SetStateAction<boolean>>;
}

const VideoMenu = ({
  anchorEl,
  open,
  setIsOpenModalEmbed,
  setOpenPlaylistModal,
  role,
}: PropsInterface): ReactElement => {
  const classes = useStyles();
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      className={classes.popItemMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        onClick={() => setOpenPlaylistModal(true)}
        className={classes.popItem}
      >
        Add to a playlist
      </MenuItem>
      {role === ROLE_PUBLISHER && (
        <MenuItem
          onClick={() => setIsOpenModalEmbed(true)}
          className={classes.popItem}
        >
          Create embed code
        </MenuItem>
      )}
    </Menu>
  );
};

export default VideoMenu;
