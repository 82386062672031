import React, { FC, useState } from "react";
import { useStyles } from "./hooks/useStyles";
import { SMALL_DEFAULT_IMAGE } from "../../init/constants";
import moment from "moment";
import { VideoBlock } from "../../declarations/pages";

interface Props {
  data: VideoBlock;
  playListId: (id: string) => void;
}

export const CardVideo: FC<Props> = ({ data, playListId }) => {
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const { id, thumbnailUrl, name, createdDate, state } = data;
  return (
    <section
      className={state ? classes.cardVideoActive : classes.cardVideo}
      onClick={() => {
        playListId(id);
      }}
    >
      <img
        className={classes.image}
        src={thumbnailUrl || SMALL_DEFAULT_IMAGE}
        alt="error"
      />
      <div className={classes.text}>
        <h4 className={classes.head}>{name}</h4>
        <span className={classes.date}>
          Created: {moment(createdDate).format("DD MMM, yyyy")}
        </span>
      </div>
    </section>
  );
};
