import { DBVideo } from "../declarations/pages";
import { getAxiosInstance } from "./api";

export const getVideos = async (
  start = 0,
  value: string,
  type: string,
  limit = 10,
  sort = "",
  playlist = ""
): Promise<{ videos: DBVideo[]; total: number }> => {
  const axios = getAxiosInstance();
  let query = value && type ? `&q=${type}:${value}` : "";
  if (playlist) {
    query += `&notInPlaylist=${playlist}`;
  }

  try {
    const response = await axios({
      method: "GET",
      url: `/videos?limit=${limit}&start=${start}${query}&sort=${sort}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getVideos] error: ", error);

    return { videos: [], total: 0 };
  }
};

export const getVideosLatest = async (page: number, limit: number) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "GET",
      url: `/videos/latest?page=${page}&per_page=${limit}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getVideosLatest] error: ", error);
    return null;
  }
};

export const getVideosTrending = async (page: number, limit: number) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "GET",
      url: `/videos/trending?page=${page}&per_page=${limit}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getVideosTrending] error: ", error);
    return null;
  }
};

export const deleteVideo = async (id: string) => {
  const axios = getAxiosInstance();

  try {
    await axios({
      method: "DELETE",
      url: `/videos/${id}`,
    });
    return true;
  } catch (error) {
    console.error("[deleteVideo] error: ", error.response);
    return error.response.status;
  }
};

export const getVideo = async (videoId: string) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "GET",
      url: `/videos/${videoId}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getVideos] error: ", error);
    return {} as any;
  }
};

export const updateVideo = async (videoId: string, data) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "PUT",
      url: `/videos/${videoId}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.error("[updateVideo] error", error);
    return error.response.status;
  }
};

export const updateVideoImage = async (
  videoId: string,
  assetId: string,
  file: File,
  kind: "thumbnail" | "poster"
) => {
  const axios = getAxiosInstance();

  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("kind", kind);

    await axios({
      method: "POST",
      url: `/image-upload/upload/${videoId}/${assetId}`,
      data: formData,
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("[updateVideoImage] error", error);
  }
};
