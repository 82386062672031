// Root Component
// =================
//
// This class is a container for the other UI components. UIRoot tells this class what to render and where.

var { UILanding } = require("./landing");
var { UIPreview } = require("./preview");
var { UIError } = require("./error");

function UIRoot(params) {
  // Protect against forgetting the new keyword when instantiating objects
  if (!(this instanceof UIRoot)) {
    return new UIRoot(params);
  }

  this.node = document.createElement("div");
  this.landing = new UILanding({
    text: params.landingText,
    uploadText: params.uploadText,
    orText: params.orText,
    onFileSelected: params.onFileSelected,
  });
  this.preview = new UIPreview({});
  this.videos = [];
  this.error = new UIError({});
}

UIRoot.prototype.render = function render(done) {
  this.node.innerHTML = "";
  this.node.className = "bcuploader-root";

  this.node.appendChild(this.error.render());
  this.node.appendChild(this.preview.render());
  this.node.appendChild(this.landing.render());

  if (done) {
    var alert = document.createElement("span");
    alert.innerHTML = "Video Saved";
    alert.className = "alert-save-video";
    this.node.appendChild(alert);
  }

  var videoContainer = document.createElement("div");
  videoContainer.className = "bcuploader-video-container";
  this.videos.forEach(
    function (video) {
      videoContainer.appendChild(video.render());
    }.bind(this)
  );
  this.node.appendChild(videoContainer);

  return this.node;
};

UIRoot.prototype.addVideo = function addVideo(video) {
  this.videos.push(video);
  this.render();
};

UIRoot.prototype.removeVideo = function removeVideo(video) {
  this.videos = this.videos.filter(function (item) {
    return item.fileName !== video.fileName;
  });
  this.render(true);
};

UIRoot.prototype.showError = function showError(message) {
  this.error.showMessage(message);
  this.render();
};

export { UIRoot };
