import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../bus/user/actions";
import { Link, useHistory } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ReplayIcon from "@material-ui/icons/Replay";
import { RigthsTable } from "../../components/rights-table";
import { getLanguage } from "../../languages";
import { deleteRights, getRights } from "../../api/rights";
import { getErrorsHandler } from "../../errors/rights";
import { useStyles } from "./hooks/useStyles";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";
import { RenderPagination } from "../../components/pagination/index";
import { DeleteModal } from "../../components/delete-modal";

const SORT_DEFAULT = "title:ASC";
export const Rights = () => {
  const classes = useStyles();

  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT);

  const [rows, setRows] = useState([]);
  const [isLoaded, setInitialData] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [error, setError] = useState("");
  const [rightDelete, setRightDelete] = useState("");
  const [lenght, setLenght] = useState(0);
  const { location } = useHistory();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [urlLng, setUrlLng] = useState("/en");
  const [currentPage, setCurrentPage] = useState(0);
  const [resetShort, setResetShort] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.setLink("rights"));
  }, []);

  useEffect(() => {
    getData("", "", 10, 0);
  }, []);

  useEffect(() => {
    const language = getLanguage(location.pathname);

    setUrlLng(language);
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  const reset = async () => {
    getData("", "title", 10, 0);
    setCurrentPage(0);
    setOrder(SORT_DEFAULT);
    setResetShort("reset");
    if (displaySearch) {
      setDisplaySearch("");
    }
  };

  const hadleSearchChange = (e) => {
    setDisplaySearch(e.target.value);
  };

  const getData = async (
    query,
    name = "name",
    limit = 10,
    start = 0,
    orderDefault = SORT_DEFAULT
  ) => {
    const { total, rights } = await getRights(
      query,
      name,
      limit,
      start * 10,
      !order ? order : orderDefault
    );
    setRows(rights);
    setLenght(total);
    setQueryPaginationNumber(start);
    if (!isLoaded) {
      setInitialData(true);
    }
  };
  const onDelete = async (data) => {
    const response = await deleteRights(data.id);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(
        getErrorsHandler(response).title || "We have some issues, try again."
      );
    } else {
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      getData("", "", 10, 0);
    }
  };

  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getData(displaySearch, "title");
    }
  };

  return (
    <section className={classes.playlist}>
      <div className={classes.heading}>
        <h1 className={classes.title}>Rights</h1>
        <Link className={classes.link} to={`${urlLng}/create-right`}>
          <Button className={`${classes.buttonAdd} button`}>New Rights</Button>
        </Link>
      </div>
      <div className={classes.searching}>
        <FormControl className={classes.input}>
          <InputLabel htmlFor="standard-adornment-password">
            Search rights by name
          </InputLabel>

          <Input
            id="standard-adornment-password"
            type="text"
            value={displaySearch}
            onChange={hadleSearchChange}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <IconButton onClick={() => getData(displaySearch, "title")}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </IconButton>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => reset()}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      {isLoaded && rows && !rows.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <RigthsTable
            data={rows}
            urlLng={urlLng}
            onDelete={(rightDelete) => {
              setRightDelete(rightDelete), setOpenDeleteModal(true);
            }}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT),
                getData(
                  displaySearch,
                  "title",
                  10,
                  Number(querypaginationNumber),
                  e || SORT_DEFAULT
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={lenght}
            loadData={(value) => getData(displaySearch, "title", 10, value)}
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="success">Right deleted.</MuiAlert>
      </Snackbar>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={rightDelete}
        onDelete={(data) => onDelete(data)}
        header="Right"
        right="this right"
      />
    </section>
  );
};
