import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  labelPaper: {
    position: "absolute",
    top: "-7px",
    left: 12,
    backgroundColor: "#fff",
    padding: "0 4px",
    color: "#000",
    fontSize: "13px",
    lineHeight: "13px",
  },
  buttons: {
    width: "49%",
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    width: "100%",
    height: 200,
    overflow: "auto",
    boxShadow: "none",
    border: "solid 1px #000",
    boxSizing: "border-box",
    borderRadius: 0,

    "& .MuiListItemIcon-root": {
      display: "none",
    },

    "& .MuiListItem-root": {
      height: 48,
    },
  },
  active: {
    "& .MuiTypography-root": {
      fontWeight: "bold",
    },
  },
  subTitle: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    margin: "36px 0",
  },
  root: {
    margin: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& button": {
      border: "none",
      width: "49%",
      margin: "24px 0",
    },
  },
  grid: {
    padding: "0 !important",
    width: "49%",
    position: "relative",
  },
}));
