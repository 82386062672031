import React from "react";
import { Button } from "@material-ui/core";
import { UploadIcon } from "../../assets/image/upload-icon";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { useStyles } from "./hooks/useStyles";
import { type } from "os";

export const UploadImage = ({ value, setValue }: any) => {
  const classes = useStyles();

  const handleChangeImage = (event) => {
    setValue(event.target.files[0]);
  };
  const file = typeof value === "string" ? value : value.name;
  const filename = file.split("/").pop();
  return (
    <div className={classes.content}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChangeImage}
      />
      <label htmlFor="contained-button-file" className={classes.label}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
        >
          <span>{filename}</span>
          <UploadIcon />
        </Button>
      </label>
      <Button onClick={() => setValue("")} className={classes.delete}>
        <DeleteIcon />
      </Button>
    </div>
  );
};
