import React, { useEffect, useState, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../init/rootReducer";
import { userActions } from "../../bus/user/actions";

export const Reporting = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.setLink("reporting"));
  }, []);

  const { longitudeId } = useSelector((state: RootState) => state.user);
  let id;

  if (longitudeId) {
    id = longitudeId;
  } else {
    const dataId = localStorage.getItem("longitudeId");
    id = JSON.parse(dataId);
  }
  const url = `https://datastudio.google.com/embed/u/0/reporting/6e4a84e8-b1b0-4fd3-8095-20fe17f3e979/page/WKLoC?params=%7B%22seller_id%22:%20${id},%20%22seller_id2%22:%20${id}%7D`;
  return (
    <section>
      <iframe src={url} height={1200} width={1200} />
    </section>
  );
};
