import { getAxiosInstance } from "./api";

export const getPlayers = async (
  value,
  type,
  perPage = 10,
  page = 0,
  sort = ""
) => {
  const axios = getAxiosInstance();
  const start = perPage * page || 0;
  const query = value && type ? `&q=${type}:${value}` : "";
  const params = {
    method: "GET",
    url: `/players?limit=${perPage}&start=${start}${query}&sort=${sort}`,
  };
  try {
    const response = await axios(params);
    return response.data;
  } catch (error) {
    console.error("[getPlayers] error", error);
    return error;
  }
};

export const createPlayer = async (data) => {
  const axios = getAxiosInstance();
  const params = {
    method: "POST",
    url: `/players`,
    data: JSON.stringify(data),
  };
  try {
    const response = await axios(params);
    return response.data;
  } catch (error) {
    console.error("[createPlayer] error", error);
    return error;
  }
};

export const deletePlayer = async (data) => {
  const axios = getAxiosInstance();
  const params = {
    method: "DELETE",
    url: `/players/${data.id}`,
  };
  return axios(params);
};

export const updatePlayer = async (data) => {
  const axios = getAxiosInstance();
  let headers = null;
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  if (data.file) {
    headers = { "content-type": "multipart/form-data" };
  }
  try {
    const response = await axios.put(`/players/${data.id}`, formData, headers);
    return response.data;
  } catch (error) {
    console.error("[updatePlayer] error", error);
    return error;
  }
};

export const getPlayer = async (id) => {
  const axios = getAxiosInstance();
  const params = {
    method: "get",
    url: `/players/${id}`,
  };
  try {
    const response = await axios(params);
    return response.data;
  } catch (error) {
    console.error("[getPlayer] error", error);
    return error.response.status;
  }
};
