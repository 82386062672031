import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import {
  AppBar,
  Tab,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Snackbar,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import { createEmbedCode, saveEmbedCode } from "../../api/embed-codes";

import { EmbedTabPanel } from "./embed-tab-panel";
import { useStyles } from "./hooks/useStyles";
import { getErrorsHandler } from "../../errors/embed";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Channel,
  EmbedCode,
  EmbedCodeForm,
  EmbedCodeTypes,
  Playlist,
  Video,
} from "../../declarations/pages";
import { useSelector } from "react-redux";
import { RootState } from "../../init/rootReducer";
import { getChannels } from "../../api/chanels";
import { getPlayers } from "../../api/players";
import { getEnvVars } from "../../utils/configHelper";

type PropsType = {
  open: boolean;
  video?: Video;
  playlist?: Playlist;
  players?: Video[];
  channels?: Video[];
  setOpen: (open: boolean) => void;
  type?: EmbedCodeTypes;
  data?: EmbedCode;
  onSave?: () => void;
  channel?: Channel;
};

type TemplateType = {
  standard?: string;
  amp?: string;
};

const embedCodeTemplate = (js_url, amp_url): TemplateType => {
  return {
    standard: (js_url && `<script src="${js_url}"></script>`) || "",
    amp:
      (amp_url &&
        `<amp-video-iframe id="footballco" width="480" height="270" poster="" layout="responsive" src="${amp_url}" noloading>` +
          `<div overflow tabindex=0 role=button aria-label="Show Player">Show Player</div>` +
          `</amp-video-iframe>`) ||
      "",
  };
};

export const EmbedCodeModal = ({
  open,
  video,
  playlist,
  channel,
  setOpen,
  type = EmbedCodeTypes.SINGLE,
  data,
  onSave,
}: PropsType) => {
  const classes = useStyles();
  const { publisher } = useSelector((state: RootState) => state.user);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState("1");
  const [embedScript, setEmbedScript] = useState<TemplateType>({
    standard: "",
    amp: "",
  });
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);
  const [values, setValues] = useState<EmbedCodeForm>();
  const [channels, setChannels] = useState([]);
  const [players, setPlayers] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [isDefault, setDefault] = useState(false);
  useEffect(() => {
    setValues({
      ...data,
      player: data?.player?.id,
      fallbackChannel: data?.fallbackChannel?.id,
    });
    setEmbedScript(
      embedCodeTemplate(data?.embedCodeUrl, data?.ampEmbedCodeUrl)
    );
    if (data?.id) {
      setIsEdit(true);
    }
  }, [data]);

  useEffect(() => {
    const getChannelsPlayers = async () => {
      const channelsRes = await getChannels();
      setChannels(channelsRes.channels);
      const playersRes = await getPlayers(undefined, undefined, 300);
      setPlayers(playersRes.players);
    };
    open && getChannelsPlayers();
  }, [open]);

  useEffect(() => {
    if (video?.name) {
      handleChangeValues("name", video?.name);
    }
  }, [video]);

  useEffect(() => {
    if (playlist?.name) {
      handleChangeValues("name", playlist?.name);
    }
    if (channel?.name) {
      handleChangeValues("name", channel?.name);
    }
  }, [playlist, channel]);

  useEffect(() => {
    if (values?.name && values?.player && values?.fallbackChannel)
      setDisable(true);
  }, [values]);

  const handleChangeValues = (prop, event) => {
    setValues({ ...values, [prop]: event });
  };

  const updateEmbedScripts = (js_url, amp_url) => {
    setEmbedScript(embedCodeTemplate(js_url, amp_url));
  };

  const createEditCode = async (params) => {
    if (isEdit) {
      return await saveEmbedCode(data.id, params);
    }
    return await createEmbedCode(params);
  };

  const generateEmbedCode = async () => {
    if (!values.name || !values.player || !values.fallbackChannel) {
      setDisable(true);
    }
    if (error) {
      setError("");
    }
    const params = {
      publisher,
      player: values.player,
      fallbackChannel: values.fallbackChannel,
      video: video?.id,
      playlist: playlist?.id,
      channel: channel?.id,
      type: data?.type || type,
      name: values.name,
      default: isDefault,
    };
    const response = await createEditCode(params);
    if (typeof response === "number") {
      setError(getErrorsHandler(response).title);
    } else {
      setOpenAlert(true);
      const { js_url, amp_url, embedCodeUrl, ampEmbedCodeUrl } = response;

      updateEmbedScripts(js_url || embedCodeUrl, amp_url || ampEmbedCodeUrl);
    }
    if (onSave) onSave();
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createPanel = (value, embedScript) => (
    <EmbedTabPanel
      value={value}
      setOpen={close}
      setDisable={setDisable}
      generate={generateEmbedCode}
      error={error}
      disable={disable}
      embedScript={embedScript}
      generateTitle={isEdit ? "Update & Save" : "Generate & Save"}
      isDataDefault={data?.default}
      updateDefault={setDefault}
    />
  );
  const close = () => {
    const dropEmbed = { standard: "", amp: "" };
    const resetValues = { ...values, player: "", fallbackChannel: "" };
    setValues(resetValues);
    setEmbedScript(dropEmbed);
    setOpen(false);
    setDisable(false);
    setError(null);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        close(), setError(null);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.canvas}>
        <div className={classes.block}>
          <IconButton
            className={classes.closeIcon}
            aria-label="upload picture"
            component="span"
            onClick={() => close()}
          >
            <HighlightOffIcon />
          </IconButton>
          <h1>{isEdit ? "Edit embed code" : "Generate embed code"}</h1>
          <TextField
            className={classes.input}
            id="name"
            label="Title"
            variant="outlined"
            value={values?.name}
            error={disable && !values.name}
            onChange={(e) => handleChangeValues("name", e.target.value)}
          />
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel
              variant="outlined"
              id="demo-simple-select-outlined-label"
            >
              Select Player *
            </InputLabel>
            <Select
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Select Player *"
              onChange={(e) => handleChangeValues("player", e.target.value)}
              value={values?.player}
              error={disable && !values.player}
            >
              {players &&
                players.map((player) => (
                  <MenuItem key={player.id} value={player.id}>
                    {player.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Fallback Channel *
            </InputLabel>
            <Select
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Fallback Channel *"
              onChange={(e) =>
                handleChangeValues("fallbackChannel", e.target.value)
              }
              value={values?.fallbackChannel}
              error={disable && !values.fallbackChannel}
            >
              {channels &&
                channels.map((channel) => (
                  <MenuItem key={channel.id} value={channel.id}>
                    {channel.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {video?.account_id && (
            <ReactPlayerLoader
              // className={classes.video}
              accountId={video?.account_id || ""}
              videoId={video?.id}
            />
          )}
          <TabContext value={value}>
            <AppBar className={classes.appBar} position="static">
              <TabList
                className={classes.tabList}
                onChange={handleChange}
                aria-label="ant example"
              >
                <Tab label="Standard" value="1" />
                <Tab label="AMP" value="2" />
              </TabList>
            </AppBar>
            {createPanel("1", embedScript.standard)}
            {createPanel("2", embedScript.amp)}
          </TabContext>
        </div>
        <Snackbar
          open={openAlert}
          autoHideDuration={3000}
          onClose={() => setOpenAlert(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert severity="success">Update & Save.</MuiAlert>
        </Snackbar>
      </div>
    </Modal>
  );
};
