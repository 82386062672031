const errorsHandler = {
  409: {
    title: "The video is in one or more playlists",
  },
  404: {
    title: "Page Not found",
  },
};

export const getErrorsHandler = (id) => {
  return errorsHandler[id] ? errorsHandler[id] : "Internal Server Error";
};
