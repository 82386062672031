import TwoBoxesSelectorComponent from "./two-boxes-selector.component";
import React, { useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";

export const TwoBoxesSelector = ({ data, entityName, onChange }) => {
  const classes = useStyles();
  const [items, updateItems] = useState(data);
  const onToggle = (el) => {
    updateItems(
      items.map((item) =>
        el.id === item.id ? { ...el, checked: !el.checked } : item
      )
    );
  };

  const onSelectAll = () => {
    updateItems(
      items.map((item) => (item.selected ? item : { ...item, checked: true }))
    );
  };

  const onAddToSelected = () => {
    const newState = items.map((item) =>
      !item.selected && item.checked
        ? {
            ...item,
            selected: true,
            checked: false,
          }
        : item
    );
    updateItems(newState);
    onChange(newState.filter((item) => item.selected));
  };

  const removeFromSelected = () => {
    const newState = items.map((item) =>
      item.selected && item.checked
        ? {
            ...item,
            selected: false,
            checked: false,
          }
        : item
    );
    updateItems(newState);
    onChange(newState.filter((item) => item.selected));
  };

  useEffect(() => {
    updateItems(data);
  }, [data]);

  return (
    <>
      <h1 className={classes.subTitle}>{entityName}</h1>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid className={classes.grid}>
          <TwoBoxesSelectorComponent
            items={items && items.filter((item) => !item.selected)}
            inputLabel={`Available ${entityName}`}
            onToggle={onToggle}
          />
        </Grid>

        <Grid className={classes.grid}>
          <TwoBoxesSelectorComponent
            items={items && items.filter((item) => item.selected)}
            inputLabel={`Selected ${entityName}`}
            onToggle={onToggle}
          />
        </Grid>

        <div className={classes.buttons}>
          <Button
            variant="outlined"
            size="small"
            className={`button`}
            onClick={onAddToSelected}
          >
            Add Selected
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={`button-white`}
            onClick={onSelectAll}
            aria-label="move all right"
          >
            Select All
          </Button>
        </div>
        <Button
          variant="outlined"
          size="small"
          className={`button-white`}
          onClick={removeFromSelected}
        >
          Remove
        </Button>
      </Grid>
    </>
  );
};
