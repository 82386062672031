import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor: "#C4C4C4",
    padding: 0,
    boxSizing: "border-box",
    margin: 4,
    width: 99,
    height: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiRadio-root": {
      display: "none",
    },

    "& .MuiTypography-body1": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "19px",
      textAlign: "center",
    },
  },
  active: {
    backgroundColor: "#000",
    color: "#fff",

    "& svg": {
      color: "#fff !important",
    },
  },
  radioGroup: {
    flexDirection: "row",
    border: "1px solid #000000",
    boxSizing: "border-box",
    maxWidth: "545px",
    padding: 4,
  },
  root: {
    padding: 0,
    fontSize: 14,
  },
  icon: {
    width: 16,
    marginRight: "5px",
    backgroundColor: "#C4C4C4",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    "& svg": {
      width: "16px",
      height: "16px",
      color: "#000",
    },
  },
  checkedIcon: {
    backgroundColor: "rgba(0,0,0,0)",
  },
}));
