import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 260;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 5,
  },
  drawerPaper: {
    width: "100%",
    background: "#000",
  },
  list: {
    background: "#000",
    color: "rgba(255, 255, 255, .6)",
    "& .listItem": {
      color: "white",
      fontSize: "20px",
    },
  },
  link: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "14px",

    "&:hover": {
      color: "#fff",
      background: "#4A4A4A",
    },
  },
  publishersSwitch: {
    borderTop: "1px solid rgba(255, 255, 255, .6)",
    height: 65,
    borderBottom: "1px solid rgba(255, 255, 255, .6)",
    "& .MuiSelect-icon": {
      color: "white",
      top: 12,
    },
    "& .MuiSelect-select.MuiSelect-select": {
      position: "absolute",
      top: 10,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "white",
    },

    "& p": {
      color: "rgba(255, 255, 255)",
      fontSize: 17,
      margin: "13px 15px",
    },
    "& label": {
      fontSize: 21,
      margin: 15,
      width: "100%",
      color: "rgba(255, 255, 255, .6)",
      marginTop: "35px !important",
    },
  },
  publishersSelect: {
    width: "100%",
    color: "#fff",
  },
  drawerContainer: {
    overflow: "auto",
    scrollbarWidth: "none",
    overflowX: "hidden",

    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
  username: {
    padding: 10,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    // display: "none",
  },

  "@media screen and (min-width: 768px)": {
    drawer: {
      position: "unset",
      display: "block",
    },
    drawerPaper: {
      width: "285px",
      background: "#000",
    },
  },
  activeLink: {
    backgroundColor: "#4A4A4A",
    color: "#000",
    fontWeight: "bold",
  },
}));
