import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "25px",

    "@media screen and (min-width: 1440px)": {
      alignItems: "flex-start",
      justifyContent: "space-between",
      marginLeft: "50px",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    color: "#000",
    margin: "20px 0",
  },
  button: {
    width: "100%",
    maxWidth: "425px",
  },
  buttonGrey: {
    width: "100%",
    maxWidth: "425px",
    backgroundColor: "#E4E4E4",
    color: "#000000 !important",
    boxShadow: "none",
  },
  cardVideo: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "425px",
    height: "63px",
    marginBottom: "16px",
  },
  information: {
    display: "flex",

    "& img": {
      width: "112px",
      marginRight: "16px",
      height: "63px",
      objectFit: "cover",
    },
    "& h4": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "16px",
      order: 0,
      margin: 0,
      width: "300px",
      marginBottom: "10px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& span": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "13px",
      color: "#000000",
      opacity: "0.5",
    },
  },
  icon: {
    minWidth: "15px",
  },
  link: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    color: "#000000",
    opacity: "0.5",
    marginLeft: "24px",
    textDecoration: "none",
  },
  cards: {},
  videoBlock: {},
  buttonsWrapper: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    width: "540px",
  },
  "@media screen and (min-width: 1440px)": {
    cardVideo: {
      minWidth: "600px",
      marginRight: "32px",
    },
    button: {
      maxWidth: "256px",
      marginTop: "22px",
    },
    buttonGrey: {
      maxWidth: "256px",
      marginTop: "22px",
      backgroundColor: "#E4E4E4 !important",
      color: "#000000 !important",
      boxShadow: "none",
    },
    cards: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: "32px",
    },
    videoBlock: {
      marginBottom: "48px",
    },
  },
}));
