import "url-search-params-polyfill";

import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { store } from "./init/store";
import { history } from "./navigation/history";
import { Routes } from "./navigation";

import "./styles/index.scss";
import { getEnvVars } from "./utils/configHelper";

function App() {
  const { domain, clientId, audience, baseUrl } = getEnvVars();

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      scope="read:current_user update:current_user_metadata"
    >
      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </Auth0Provider>
  );
}

export default App;
