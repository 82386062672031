import {
  CircularProgress,
  IconButton,
  IconButtonProps,
} from "@material-ui/core";
import React, { FC } from "react";
import { LockIcon, UnlockIcon } from "../../assets/image/lock-icon";

interface Props extends IconButtonProps {
  loading?: boolean;
  locked?: boolean;
}

const LockButton: FC<Props> = ({ loading, locked, ...props }) => {
  return (
    <IconButton {...props}>
      {loading ? <CircularProgress /> : locked ? <LockIcon /> : <UnlockIcon />}
    </IconButton>
  );
};

export default LockButton;
