import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import { Link, useHistory } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Input,
  Button,
} from "@material-ui/core";

import ReplayIcon from "@material-ui/icons/Replay";
import { ChannelsTable } from "../../components/channels-table";
import { getLanguage } from "../../languages";

import { useStyles } from "./hooks/useStyles";
import SearchIcon from "@material-ui/icons/Search";
import { getChannels } from "../../api/chanels";
import { RenderPagination } from "../../components/pagination/index";
import { RootState } from "../../init/rootReducer";
import { PAGINATION_PER_PAGE, SORT_DEFAULT_NAME } from "../../init/constants";
import { ROLE_ADMIN } from "../../init/constants";
import { ChannelsP } from "../../declarations/pages";

export const Channels = (): ReactElement => {
  const classes = useStyles();

  const history = useHistory();
  const { location } = history;
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [data, setChannels] = useState<ChannelsP>();
  const [isLoaded, setInitialData] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [resetShort, setResetShort] = useState("");
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT_NAME);
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );

  const [urlLng, setUrlLng] = useState("/en");
  const dispatch = useDispatch();

  const { isAdmin, role } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(userActions.setLink("channels"));
  }, []);

  useEffect(() => {
    if (!displaySearch) {
      getData(displaySearch);
    }
  }, [displaySearch, role]);

  useEffect(() => {
    const language = getLanguage(location.pathname);

    setUrlLng(language);
  }, [location]);

  const reset = async () => {
    if (displaySearch) {
      setDisplaySearch("");
    }
    setCurrentPage(0);
    getData("", 0, 10, SORT_DEFAULT_NAME);
    setOrder(SORT_DEFAULT_NAME);
    setResetShort("reset");
  };

  const onSearchChange = (e) => {
    setDisplaySearch(e.target.value);
  };

  const getData = async (
    search,
    page = 0,
    perPage = PAGINATION_PER_PAGE,
    orderDefault = SORT_DEFAULT_NAME
  ) => {
    const channelsData = await getChannels({
      perPage,
      page,
      search,
      sort: !order ? order : orderDefault,
    });
    setQueryPaginationNumber(page);
    setChannels(channelsData);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, querypaginationNumber]);

  const loadData = async (value) => {
    await getData(value);
  };
  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getData(displaySearch);
    }
  };

  return (
    <section className={classes.channels}>
      <div className={classes.heading}>
        <h1 className={classes.title}>Channels</h1>
        {isAdmin && role === ROLE_ADMIN && (
          <Link className={classes.link} to={`${urlLng}/create-channel`}>
            <Button className={`${classes.buttonAdd} button`}>
              New Channel
            </Button>
          </Link>
        )}
      </div>
      <div className={classes.searching}>
        <FormControl className={classes.input}>
          <InputLabel htmlFor="search-channels">
            Search channel by name
          </InputLabel>

          <Input
            id="search-channels"
            type="text"
            value={displaySearch}
            onChange={onSearchChange}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <IconButton>
                <InputAdornment position="end" onClick={loadData}>
                  <SearchIcon />
                </InputAdornment>
              </IconButton>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => reset()}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      {isLoaded && data && !data.channels.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <ChannelsTable
            data={data?.channels}
            urlLng={urlLng}
            onDelete={() => getData(displaySearch)}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT_NAME),
                getData(
                  displaySearch,
                  Number(querypaginationNumber),
                  10,
                  e || SORT_DEFAULT_NAME
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={data?.total}
            loadData={(value) =>
              getData(displaySearch, value, 10, SORT_DEFAULT_NAME)
            }
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}
    </section>
  );
};
