import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Search from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import {
  IconButton,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import Alert from "@material-ui/lab/Alert";
import { getErrorsHandler } from "../../errors/video";
import { useStyles } from "./hooks/useStyles";
import { addVideoPlaylists, getAllPlaylists } from "../../api/all-playlists";
import { RenderPagination } from "../../components/pagination/index";
import { SORT_DEFAULT } from "../../init/constants";

export const AddPlaylistModal = ({ open, setOpen, videoId }: any) => {
  const classes = useStyles();
  const [playListId, setPlayListId] = useState(null);
  const [key, setKey] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState("");
  const [playlists, setPlaylists] = useState([]);
  const [totalPlaylists, setTotalPlaylists] = useState(0);

  const [displaySearch, setDisplaySearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const getPlayList = async (start = 0, limit = 5) => {
    const { playlists: result, total } = await getAllPlaylists(
      displaySearch,
      "name",
      limit,
      start * limit,
      SORT_DEFAULT,
      "type=MANUAL"
    );
    setPlaylists(result);
    setTotalPlaylists(total);
  };

  const saveVideoInPlaylist = async () => {
    setShowSuccess(false);
    setShowError(false);
    const response = await addVideoPlaylists(videoId, playListId);
    if (typeof response == "boolean") {
      setShowSuccess(true);
    } else {
      setShowError(true);
      setError(getErrorsHandler(response).title);
    }
  };

  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getPlayList();
    }
  };

  const changeSearch = (e) => {
    setDisplaySearch(e.target.value);
  };

  useEffect(() => {
    if (open && !displaySearch) getPlayList();
  }, [open, displaySearch]);

  const reset = async () => {
    setDisplaySearch("");
    getPlayList();
    setCurrentPage(0);
  };

  const RenderTable = () => (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>Name</TableCell>
              <TableCell className={classes.title}>Created</TableCell>
              <TableCell className={classes.title}>Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {playlists.map((row, index) => (
              <TableRow
                className={
                  key === index ? classes.tableRowActive : classes.tableRow
                }
                key={index}
                onClick={() => {
                  setPlayListId(row.id), setKey(index);
                }}
              >
                <TableCell width="20%" className={classes.row}>
                  {row.name}
                </TableCell>
                <TableCell width="18%" className={classes.row}>
                  {moment(row.created).format("DD-MM-yyyy, LT")}
                </TableCell>
                <TableCell width="18%" className={classes.row}>
                  {row.update}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RenderPagination
        limit={5}
        count={totalPlaylists}
        loadData={(value) => getPlayList(value)}
        currentPage={currentPage}
        setCurrentPage={(value) => setCurrentPage(value)}
      />
    </>
  );
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false),
          setShowError(false),
          setShowSuccess(false),
          setDisplaySearch("");
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          Add to a playlist
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false),
                setShowError(false),
                setShowSuccess(false),
                setDisplaySearch("");
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <p>Choose the playlist where you want to add the selected video</p>
        <FormControl>
          <InputLabel htmlFor="search-playlist">Search</InputLabel>
          <Input
            id="search-playlist"
            type="text"
            value={displaySearch}
            onChange={(e) => changeSearch(e)}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onSearch}>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.buttonReset}
          onClick={reset}
        >
          <ReplayIcon /> Reset
        </IconButton>
        {playlists.length === 0 ? (
          <div className={classes.noResults}>No results</div>
        ) : (
          RenderTable()
        )}
        {showError && <Alert severity="error">{error}</Alert>}
        {showSuccess && (
          <Alert severity="success">Video added to playlist!</Alert>
        )}
        <Button
          className={`${classes.button} button`}
          variant="contained"
          onClick={() => {
            setOpen(false),
              setShowError(false),
              setShowSuccess(false),
              setDisplaySearch("");
          }}
        >
          Cancel
        </Button>
        <Button
          className={`${classes.buttonBlack} button`}
          variant="contained"
          onClick={saveVideoInPlaylist}
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};
