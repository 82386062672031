const errorsHandler = {
  404: {
    title: "Currently the page is unavailable, try again",
  },
  500: {
    title: "Unable to create embed code, try again.",
  },
};

export const getErrorsHandler = (id) => {
  return errorsHandler[id] ? errorsHandler[id] : "Internal Server Error";
};
