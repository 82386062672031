import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import { LockIcon, UnlockIcon } from "../../assets/image/lock-icon";

import { OpticIcon, CloseOpticIcon } from "../../assets/image/optic-icon";
import { EditIcon } from "../../assets/image/edit-icon";
import { SortArray } from "../../components/sorter/sorter";
import { Link } from "react-router-dom";
import moment from "moment";
import { SORT_DEFAULT_ICON_CREATE } from "../../init/constants";

export const SemanticArticlesTable = ({
  data = [],
  urlLng,
  onLock,
  onHide,
  updateData,
  resetShort = "",
}: any) => {
  const classes = useStyles();
  const [sort, setSort] = useState(resetShort);
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT_ICON_CREATE);
  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);
  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>Article URL</TableCell>
              <TableCell className={classes.title}>Embed Name</TableCell>
              <TableCell className={classes.title}>
                Created
                <SortArray
                  sort={sort}
                  type="createdDate"
                  setSort={(e) => {
                    updateData(e && `createdDate:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title}>
                Updated
                <SortArray
                  sort={sort}
                  type="updatedDate"
                  setSort={(e) => {
                    updateData(e && `updatedDate:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title}></TableCell>
              <TableCell className={classes.title}></TableCell>
              <TableCell className={classes.title}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell width="20%" className={classes.row}>
                  <Link
                    to={`${urlLng}/semantic-articles-playlist/${row.id}`}
                    className={classes.link}
                  >
                    {row.url}
                  </Link>
                </TableCell>
                <TableCell width="20%" className={classes.row}>
                  {row.embedCode?.name}
                </TableCell>
                <TableCell width="15%" className={classes.row}>
                  {moment(row.createdDate).format("DD/MM/yyyy")}
                </TableCell>
                <TableCell width="15%" className={classes.row}>
                  {moment(row.updatedDate).format("DD/MM/yyyy")}
                </TableCell>
                <TableCell width="5%" className={classes.row}>
                  <IconButton onClick={() => onLock(row)}>
                    {row.locked ? <LockIcon /> : <UnlockIcon />}
                  </IconButton>
                </TableCell>
                <TableCell width="5%" className={classes.row}>
                  <IconButton onClick={() => onHide(row)}>
                    {!row.hide ? <OpticIcon /> : <CloseOpticIcon />}
                  </IconButton>
                </TableCell>
                <TableCell width="5%" className={classes.row}>
                  <IconButton>
                    <Link
                      to={`${urlLng}/semantic-articles-playlist/${row.id}`}
                      className={classes.link}
                    >
                      <EditIcon />
                    </Link>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
