import { getAxiosInstance } from "./api";
// import mockData from "../mock-data/users.json"

export const getUsers = async (
  value,
  type,
  perPage = 10,
  page = 0,
  sort = ""
): Promise<{ users: any; total: number }> => {
  const axios = getAxiosInstance();
  const query = value && type ? `&q=${type}:${value}` : "";

  try {
    const response = await axios({
      method: "GET",
      url: `/users?per_page=${perPage}&page=${page}${query}&sort=${sort}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getUsers] error", error);
    return { users: [], total: 0 };
  }
};

export const getUser = async (id) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "GET",
      url: `/users/${id}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getUser] error", error);
    return null;
  }
};

export const createUser = async (data) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "POST",
      url: "/users",
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    console.error("[createUser] error", error);
    return error.response.status;
  }
};

export const blockUser = async (id) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "PUT",
      url: `/users/${id}/block`,
    });
    return response.data;
  } catch (error) {
    console.error("[blockUser] error", error);
    return null;
  }
};

export const unblockUser = async (id) => {
  const axios = getAxiosInstance();

  try {
    const response = await axios({
      method: "PUT",
      url: `/users/${id}/unblock`,
    });
    return response.data;
  } catch (error) {
    console.error("[unblockUser] error", error);
    return null;
  }
};

export const deleteUser = async (id) => {
  const axios = getAxiosInstance();

  try {
    await axios({
      method: "DELETE",
      url: `/users/${id}`,
    });
    return true;
  } catch (error) {
    console.error("[removeUser] error", error);
    return null;
  }
};

export const updateUser = async (data) => {
  const axios = getAxiosInstance();
  const id = data.user_id;

  try {
    const response = await axios({
      method: "PUT",
      url: `/users/${id}`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    console.error("[updateUser] error", error);
    return null;
  }
};
