import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  playlistManual: {},
  breadcrumbs: {
    display: "flex",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
  },
  header: {
    display: "flex",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "880px",
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  head: {
    color: "#000",
    margin: "0",
    "& span": {
      marginTop: "15px",
      display: "inline-block",
      marginRight: "20px",
    },
  },
  text: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "140%",
    width: "644px",
  },
  button: {},
  textButton: {
    display: "flex",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "140%",
  },
  "@media screen and (min-width: 1440px)": {
    playlistManual: {
      marginTop: "38px",
      marginLeft: "36px",
    },
    breadcrumbs: {
      alignItems: "center",
      justifyContent: "space-between",
      width: "150px",
    },
    header: {
      marginTop: "29px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "950px",
    },
    head: {
      alignItems: "center",
      margin: "0",
    },
    button: {
      width: "256px",
      marginRight: "32px",
    },
    textButton: {
      justifyContent: "space-beetwen",
      alignItems: "center",
      marginBottom: "48px",
    },
  },
}));
