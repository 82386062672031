import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  playlist: {
    marginLeft: "32px",
    marginTop: "38px",
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    textDecoration: "none",
  },
  heading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "32px",
    paddingRight: 22,
    boxSizing: "border-box",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
    margin: "24px 0",
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid #C4C4C4",
    width: "100%",
  },
  tabList: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#000",
    },
  },
  tabPanel: {
    width: "100%",
    padding: 0,
    marginTop: 32,
  },
  description: {
    marginBottom: "24px",
    width: "100%",
  },
  input: {
    marginBottom: "24px",
    width: "100%",
    "& [data-shrink=true]": {
      fontWeight: "bold",
      color: "#000000",
    },
  },
  label: {
    backgroundColor: "#C4C4C4",
    padding: 8,
    boxSizing: "border-box",
    marginRight: 16,
    marginLeft: 0,

    "& .MuiRadio-root": {
      display: "none",
    },

    "& .MuiTypography-body1": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "19px",
    },
  },
  type: {
    display: "block",
  },
  active: {
    backgroundColor: "#000",
    color: "#fff",

    "& .MuiRadio-root": {
      display: "inline-flex",
    },

    "& svg": {
      color: "#fff !important",
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  root: {
    padding: 0,
    fontSize: 14,
  },
  icon: {
    width: 16,
    marginRight: "5px",
    backgroundColor: "#C4C4C4",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    "& svg": {
      width: "16px",
      height: "16px",
      color: "#000",
    },
  },
  checkedIcon: {
    backgroundColor: "rgba(0,0,0,0)",
  },
  group: {
    flexDirection: "row",
    "& .Mui-checked": {
      color: "#000",
    },

    "& .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "rgba(0,0,0, .04)",
    },
  },
  select: {
    width: "100%",
    marginBottom: 25,
    marginTop: 20,
    "& svg": {
      color: "#000",
    },
    "& label": {
      color: "#000",
      fontWeight: "bold",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
  content: {
    display: "flex",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",

    "& button": {
      width: "47%",
      height: "48px",
      margin: 0,
    },
  },
  videos: {
    width: "50%",
    boxSizing: "border-box",
    padding: "0 32px",
  },
  settings: {
    width: "50%",
  },
  image: {
    marginTop: 32,
    boxSizing: "border-box",
    width: "100%",
    position: "relative",

    "& img": {
      width: "100%",
    },
  },
  logo: {
    width: 99,
    height: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E4E4E4",
    position: "absolute",
  },
  topLeft: {
    top: 16,
    left: 16,
  },
  topRight: {
    top: 16,
    right: 16,
  },
  bottomRight: {
    bottom: 16,
    right: 16,
  },
  bottomLeft: {
    bottom: 16,
    left: 16,
  },
  info: {
    display: "flex",
    alignItems: "flex-start",
    flexFlow: "column",
    "& span": {
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "22px",
    },
  },
  error: {
    marginBottom: 34,
  },
}));
