import axios, { AxiosInstance } from "axios";
import { getEnvVars } from "../utils/configHelper";

let axiosInstance: AxiosInstance;
let axiosToken: string;

const urlParts = window.location.href.split(".");
const env = urlParts[1] || "dev";

export const getAxiosInstance = () => {
  return axiosInstance;
};

export const getAxiosToken = () => axiosToken;

export const setAxiosInstance = (token: string, publisherId: string = "") => {
  const { baseUrl } = getEnvVars();
  axiosToken = token;
  const headers = {
    "Content-type": "application/json",
    "x-origin-access-key": "football-co",
    Authorization: `Bearer ${token}`,
  };
  if (publisherId) {
    headers["x-pub-id"] = publisherId;
  }
  axiosInstance = axios.create({
    baseURL: baseUrl,
    headers,
  });
};

export const updateAxiosInstance = (publisherId) => {
  const { baseUrl } = getEnvVars();
  axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-type": "application/json",
      "x-origin-access-key": "football-co",
      Authorization: `Bearer ${axiosToken}`,
      "x-pub-id": `${publisherId}`,
    },
  });
};
