import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Toolbar } from "@material-ui/core";
import DehazeIcon from "@material-ui/icons/Dehaze";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { setAxiosInstance } from "../../api/api";
import { useDispatch } from "react-redux";

import { useStyles } from "./hooks/useStyles";
import { userActions } from "../../bus/user/actions";
import logo from "../../assets/image/logo-white.png";
import { getEnvVars } from "../../utils/configHelper";

const userRoleKey = "https://footballco.com/roles";
const userPublisherIdKey = "https://footballco.com/organization_id";

const { audience } = getEnvVars();

export const Header = ({ onSubmit, visibleSideBar, setIsToken }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userMetadata, setUserMetadata] = useState(null);
  const { user, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: audience,
          scope: "read:users read:current_user",
        });

        const data = await getIdTokenClaims(); // TODO refactor?

        const roles = data[userRoleKey];
        const publisherId =
          data[userPublisherIdKey] ||
          JSON.parse(localStorage.getItem("publisherId"));
        setAxiosInstance(data.__raw, publisherId);
        setIsToken(true);
        dispatch(userActions.setRole(roles?.length && roles[0]));
        dispatch(userActions.setPublisher(publisherId));
        dispatch(userActions.setUserName(data.nickname));

        const userDetailsByIdUrl = `${audience}users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        setUserMetadata(user_metadata);
      } catch (e) {
        e.message === "Login required" && loginWithRedirect();
      }
    };

    getUserMetadata();
  }, [
    getAccessTokenSilently,
    user?.sub,
    loginWithRedirect,
    getIdTokenClaims,
    setIsToken,
  ]);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.flexContainer}>
        <div style={{ display: "none" }}>Hello world</div>
        <div>
          <a href={"/"}>
            <img className={classes.logo} src={logo} alt="footballco logo" />
          </a>
        </div>

        <IconButton
          onClick={() => onSubmit(!visibleSideBar)}
          aria-label="delete"
          className={classes.button}
        >
          {!visibleSideBar ? <DehazeIcon /> : <CloseIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
