import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  videodetails: {
    marginLeft: "32px",
    marginTop: "38px",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
    maxWidth: "69%",
  },
  heading: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    marginBottom: "29px",
    textDecoration: "none",
  },
  select: {
    width: "256px",
    margin: "0 25px",
  },
  content: {
    padding: "33px 44px",
  },

  searching: {
    display: "flex",
  },
  contentIcons: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  button: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    display: "flex",
    justifyContent: "space-between",
    width: "100px",

    "&:hover": {
      borderRadius: "0px",
    },
  },
  img: {
    width: "100%",
  },
  img2: {
    width: "300px",
  },
  head: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    order: 0,
    flexGrow: 1,
    margin: 0,
    marginBottom: "10px",
  },
  headImage: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    marginTop: 25,
  },
  h3: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  paragraph: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "22px",
    letterSpacing: 0,
    textAlign: "left",
  },
  paragraphTitle: {
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: 40,
    "& span": {
      fontWeight: 300,
    },
  },
  label: {
    "& label": {
      fontWeight: 700,
      fontSize: 16,
      color: "#000",
    },
    "& .MuiChip-deleteIcon": {
      color: "#000",
    },
    "& .MuiAutocomplete-tag, .MuiChip-root": {
      borderRadius: 0,
      color: "#000",
      fontWeight: 400,
      fontSize: 14,
      background: "#C4C4C4",
    },
  },
  popItemMenu: {
    "& .MuiPaper-root": {
      borderRadius: 0,
    },
    "& ul": {
      padding: 0,
    },
  },
  popItem: {
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  input: {
    margin: "12px 0",

    "& .MuiInputBase-root": {
      borderRadius: 0,
    },

    "& .Mui-focused": {
      color: "#000",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
  },

  paper: {
    border: "1px solid #C4C4C4",
    borderRadius: "0",
    padding: "14px 0 0 14px",
    boxShadow: "none",
    position: "relative",
    minHeight: 56,
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "wrap",

    "& label": {
      fontWeight: 700,
      fontSize: 16,
      color: "#000",
    },
    "& .MuiChip-deleteIcon": {
      color: "#000",
    },
    "& .MuiAutocomplete-tag, .MuiChip-root": {
      borderRadius: 0,
      color: "#000",
      fontWeight: 400,
      fontSize: 14,
      background: "#C4C4C4",
      margin: "0 8px 8px 0",
    },
  },

  titleInput: {
    position: "absolute",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "16px",
    top: -10,
    color: "#000000",
    backgroundColor: "#fff",
    padding: "0 5px",
    boxSizing: "border-box",
    left: 9,
  },
  video: {
    "& .video-js": {
      width: 544,
      height: 308,
      marginTop: 12,
    },
  },
});
