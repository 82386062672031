const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_CLIENT_ID,
  REACT_APP_AUDIENCE,
  REACT_APP_BASE_URL,
  REACT_APP_AUTH0_DOMAIN_DEV,
  REACT_APP_CLIENT_ID_DEV,
  REACT_APP_AUDIENCE_DEV,
  REACT_APP_BASE_URL_DEV,
  REACT_APP_AUTH0_DOMAIN_STAGE,
  REACT_APP_CLIENT_ID_STAGE,
  REACT_APP_AUDIENCE_STAGE,
  REACT_APP_BASE_URL_STAGE,
  REACT_APP_AUTH0_DOMAIN_PROD,
  REACT_APP_CLIENT_ID_PROD,
  REACT_APP_AUDIENCE_PROD,
  REACT_APP_BASE_URL_PROD,
} = process.env;

export const getEnvVars = () => {
  const host = window.location.host;

  if (host === "dev.fcp-portal.footballco.cloud") {
    return {
      domain: REACT_APP_AUTH0_DOMAIN_DEV,
      clientId: REACT_APP_CLIENT_ID_DEV,
      audience: REACT_APP_AUDIENCE_DEV,
      baseUrl: REACT_APP_BASE_URL_DEV,
    };
  }

  if (host === "stage.fcp-portal.footballco.cloud") {
    return {
      domain: REACT_APP_AUTH0_DOMAIN_STAGE,
      clientId: REACT_APP_CLIENT_ID_STAGE,
      audience: REACT_APP_AUDIENCE_STAGE,
      baseUrl: REACT_APP_BASE_URL_STAGE,
    };
  }

  if (host === "prod.fcp-portal.footballco.cloud" || host === "fcplayer.net") {
    return {
      domain: REACT_APP_AUTH0_DOMAIN_PROD,
      clientId: REACT_APP_CLIENT_ID_PROD,
      audience: REACT_APP_AUDIENCE_PROD,
      baseUrl: REACT_APP_BASE_URL_PROD,
    };
  }

  return {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientId: REACT_APP_CLIENT_ID,
    audience: REACT_APP_AUDIENCE,
    baseUrl: REACT_APP_BASE_URL,
  };
};
