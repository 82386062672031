import React, { useState, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useHistory } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
} from "@material-ui/core";
import { getLanguage } from "../../languages";
import { book } from "../../navigation/book";
import { useStyles } from "./hooks/useStyles";
import { RootState } from "../../init/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  getPublishers,
  getPublisher,
  getCurrentPublisher,
} from "../../api/publishers";
import { updateAxiosInstance, setAxiosInstance } from "../../api/api";
import { userActions } from "../../bus/user/actions";
import { ROLE_ADMIN, ROLE_PUBLISHER } from "../../init/constants";
import { Publisher } from "../../declarations/pages";

interface MenuItem {
  name: string;
  link: string;
  role: "publisher" | "admin" | "typePublisher";
  active?: boolean;
  disabled?: boolean;
  module?: "api" | "semantic" | "videoUpload";
}

const filterByModule = (publisher: Publisher) => (menuItem: MenuItem) => {
  if (!(menuItem.module && publisher)) return true;

  if (menuItem.module === "semantic" && publisher.moduleSemantic) {
    return true;
  } else if (menuItem.module === "videoUpload" && publisher.moduleVideoUpload) {
    return true;
  }

  return false;
};

const filterByRole = (publisher: Publisher) => (menuItem: MenuItem) => {
  if (menuItem.role === "typePublisher" && !publisher) return false;
  if (menuItem.role !== "admin") return true;

  return !publisher;
};

const DEMONAV: MenuItem[] = [
  { name: "Content", link: "content", role: "publisher", active: false },
  { name: "Players", link: "players", role: "publisher", active: false },
  { name: "Playlists", link: "playlists", role: "publisher", active: false },
  { name: "Embed Codes", link: "embedCodes", role: "publisher", active: false },
  {
    name: "Reporting",
    link: "reporting",
    role: "typePublisher",
    active: false,
  },
  {
    name: "Video Upload",
    link: "videoUpload",
    role: "publisher",
    active: false,
    module: "videoUpload",
  },

  { name: "Channels", link: "channels", role: "publisher", active: false },
  {
    name: "Semantic",
    link: "divider",
    role: "publisher",
    module: "semantic",
  },
  {
    name: "Semantic Embed Codes",
    link: "semanticEmbedCodes",
    role: "publisher",
    module: "semantic",
    active: false,
  },
  {
    name: "Semantic Articles",
    link: "semanticArticles",
    role: "publisher",
    active: false,
    module: "semantic",
  },
  {
    name: "Accounts",
    link: "divider",
    role: "admin",
  },
  { name: "Publishers", link: "publishers", role: "admin", active: false },

  { name: "Users", link: "users", role: "admin", active: false },
  {
    name: "Rightsholders",
    link: "rightsholders",
    role: "admin",
    active: false,
  },
  { name: "Rights", link: "rights", role: "admin", active: false },
];

const userPublisherIdKey = "https://footballco.com/organization_id";

export const Sidebar = ({ visibleSideBar }: any) => {
  const { logout, getIdTokenClaims } = useAuth0();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { location } = useHistory();
  const [urlLng, setUrlLng] = useState("/en");

  const [publisher, setPublisher] = useState<Publisher>(null);

  const {
    activeLink,
    isAdmin,
    userName,
    publisher: publisherId,
    publishers,
  } = useSelector((state: RootState) => state.user);

  const items = useMemo(() => {
    return DEMONAV.filter(filterByRole(publisher)).filter(
      filterByModule(publisher)
    );
  }, [publisher, isAdmin]);

  const getPublishersData = async () => {
    const data = await getPublishers("", "", 1000, 0);
    dispatch(userActions.setPublishers(data.publishers));
  };

  const getPublisherData = async () => {
    const data = await getCurrentPublisher();
    dispatch(userActions.setPublisher(data.id));
    setPublisher(data);
  };

  useEffect(() => {
    if (publisherId && publisher?.id !== publisherId) {
      getPublisherData();
    }
  }, [publisherId]);

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  useEffect(() => {
    if (isAdmin) {
      getPublishersData();
    }
  }, [isAdmin]);

  const handleLogout = () => {
    logout({
      returnTo: `${window.location.protocol}//${window.location.host}/sign-in`,
    });
  };

  const handleChange = async (value) => {
    if (value === "superAdmin") {
      const data = await getIdTokenClaims();
      localStorage.removeItem("publisherId");
      localStorage.setItem("role", JSON.stringify(ROLE_ADMIN));
      setPublisher(null);
      setAxiosInstance(data.__raw);
      dispatch(userActions.setPublisher(null));
      dispatch(userActions.setRole(ROLE_ADMIN));
    } else {
      localStorage.setItem("publisherId", JSON.stringify(value.id));
      localStorage.setItem("longitudeId", JSON.stringify(value.longitudeId));
      localStorage.setItem("role", JSON.stringify(ROLE_PUBLISHER));
      setPublisher(value);
      updateAxiosInstance(value.id);
      dispatch(userActions.setRoleAdmin(ROLE_PUBLISHER));
      dispatch(userActions.setPublisher(value.id));
      dispatch(userActions.setPublisherLongitude(value.longitudeId));
    }
  };
  return (
    <Drawer
      className={`${classes.drawer} ${!visibleSideBar ? classes.active : ""}`}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Toolbar />

      <div className={classes.drawerContainer}>
        <List className={classes.list}>
          {isAdmin && (
            <FormControl className={`${classes.publishersSwitch}`} fullWidth>
              <Select
                labelId="publishers-label"
                className={`${classes.publishersSelect}`}
                label="Publisher"
                onChange={(e) => handleChange(e.target.value)}
                value={userName}
              >
                <MenuItem value="superAdmin">Super Admin</MenuItem>
                {publishers.map((item) => (
                  <MenuItem key={item.id} value={item}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>

              <InputLabel id="demo-simple-select-label">{userName}</InputLabel>
              <p>{publisher?.name ? publisher?.name : "Super Admin"}</p>
            </FormControl>
          )}
          {items.map((item) => {
            if (item.link == "divider") {
              return (
                <>
                  <Divider
                    key={`${item.name}${item.link}`}
                    style={{ backgroundColor: "#313C4E" }}
                  />
                  <ListItem className="listItem" key={item.name}>
                    {item.name}
                  </ListItem>
                </>
              );
            } else {
              return (
                <ListItem
                  className={`${classes.link} ${
                    item.link === activeLink ? classes.activeLink : ""
                  }`}
                  button
                  key={item.name}
                  component={Link}
                  to={`${urlLng}${book[item.link]}`}
                >
                  <ListItemText primary={item.name} />
                </ListItem>
              );
            }
          })}
          <ListItem button key="logout">
            <ListItemText primary="Logout" onClick={() => handleLogout()} />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};
