const errorsHandler = {
  409: {
    title: "The user already exists",
    name: ["email"],
  },
  1: {
    title: "Invalid email",
    name: ["email"],
  },
  2: {
    title: "Not all fields are filled",
    name: [],
  },
  0: { title: "", name: [] },
};

export const getErrorsHandler = (id) => {
  return errorsHandler[id] ? errorsHandler[id] : "Internal Server Error";
};
