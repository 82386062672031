import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    marginTop: 32,
  },
  label: {
    width: "100%",
    maxWidth: "calc(545px - 64px)",
  },
  input: {
    display: "none",
  },
  button: {
    color: "#000",
    backgroundColor: "#F5F5F5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 0,
    boxShadow: "none",
    padding: 16,
    boxSizing: "border-box",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "15px",

    "&:hover": {
      backgroundColor: "#F5F5F5",
      boxShadow: "none",
    },
  },
  delete: {
    backgroundColor: "#F5F5F5",
    borderRadius: 0,
    padding: 16,
    boxSizing: "border-box",
  },
}));
