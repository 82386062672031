import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ReplayIcon from "@material-ui/icons/Replay";
import { useHistory } from "react-router-dom";
import {
  getRightSholders,
  createRightSholder,
  deleteRightHolder,
} from "../../api/rightsholders";
import { RightSholdersTable } from "../../components/rightsholders-table";
import { getLanguage } from "../../languages";
import { RightSholdersModal } from "../../components/rightsholders-modal";
import { getErrorsHandler } from "../../errors/rightsholder";
import {
  Button,
  Snackbar,
  IconButton,
  Input,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { useStyles } from "./hooks/useStyles";
import SearchIcon from "@material-ui/icons/Search";
import { DeleteModal } from "../../components/delete-modal";

import { RenderPagination } from "../../components/pagination/index";

const SORT_DEFAULT = "title:ASC";

type customCode = "duplicatedEntity";

const errorMessagesDic: Record<customCode, string> = {
  duplicatedEntity: "Rightsholder name or ID already exists, please try again",
};

export const Rightsholders = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = history;
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");

  const [urlLng, setUrlLng] = useState("/en");
  const [showSearch, setShowSearch] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [statusRight, setStatusRight] = useState("");
  const [error, setError] = useState("");
  const [rows, setRows] = useState([]);
  const [isLoaded, setInitialData] = useState(false);
  const [length, setLength] = useState(0);
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [resetShort, setResetShort] = useState("");
  const [open, setOpen] = useState(false);
  const [rightDelete, setRightDelete] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT);
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );

  useEffect(() => {
    dispatch(userActions.setLink("rightsholders"));
  }, []);

  const reset = async () => {
    setDisplaySearch("");
    getData(displaySearch, "", 10, 0);
    setCurrentPage(0);
    setOrder(SORT_DEFAULT);
    setResetShort("reset");
  };

  const getData = async (
    query,
    title = "title",
    perPage = 10,
    page = 0,
    orderDefault = SORT_DEFAULT
  ) => {
    const { total, rightHolders } = await getRightSholders(
      query,
      title,
      perPage,
      page * perPage,
      !order ? order : orderDefault
    );
    setRows(rightHolders);
    setLength(total);
    setQueryPaginationNumber(page);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const onSubmit = async (data) => {
    const response = await createRightSholder(data);
    if (response.status >= 200 && response.status < 300) {
      setRows((prevState) => [response.data, ...prevState]);
      setOpenAlertSuccess(true);
      setStatusRight("Rightsholder Added.");
      return { isError: true };
    } else {
      const message = errorMessagesDic[response?.data?.customCode];
      return { isError: false, error: message };
    }
  };

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  useEffect(() => {
    getData(displaySearch, "", 10, 0);
  }, []);

  const onDelete = async (data) => {
    const response = await deleteRightHolder(data.id);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      getData(displaySearch, "", 10, 0);
      setStatusRight("Rightsholder deleted.");
    }
  };
  const ChangeSearch = (e) => {
    setDisplaySearch(e.target.value);
  };
  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getData(displaySearch);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order]);
  return (
    <section className={classes.rightsholders}>
      <div className={classes.heading}>
        <h1 className={classes.title}>Rightsholders</h1>
        <Button
          className={`${classes.newButton} button`}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          New Rightsholder
        </Button>
      </div>
      <div className={classes.searching}>
        <FormControl className={classes.input}>
          <InputLabel htmlFor="search-rightholders">
            Search rightsholders
          </InputLabel>
          <Input
            id="search-rightholders"
            type="text"
            value={displaySearch}
            onChange={(e) => ChangeSearch(e)}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => getData(query)}>
                  <SearchIcon className={classes.icon} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => {
            reset(), setShowSearch(false);
          }}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      {isLoaded && rows && !rows.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <RightSholdersTable
            data={rows}
            onDelete={(data) => {
              setRightDelete(data), setOpenDeleteModal(true);
            }}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT),
                getData(
                  displaySearch,
                  "title",
                  10,
                  Number(querypaginationNumber),
                  e || SORT_DEFAULT
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={length}
            loadData={(value) => getData("", "", 10, value)}
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}
      <RightSholdersModal
        open={open}
        setOpen={setOpen}
        createRightHolder={onSubmit}
      />

      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="success">{statusRight}</MuiAlert>
      </Snackbar>
      <DeleteModal
        data={rightDelete}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onDelete={() => onDelete(rightDelete)}
        header="Rightsholder"
      />
    </section>
  );
};
