import Modal from "@material-ui/core/Modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton, Button } from "@material-ui/core";

import { useStyles } from "./hooks/useStyles";
import React, { useEffect, useState } from "react";

interface State {
  name?: string;
  email?: string;
  title?: string;
  id?: string;
  account_id?: string;
  right?: string;
  removeVideo?: boolean;
}

export const DeleteModal = ({
  open,
  setOpen,
  onDelete,
  data,
  error,
  header,
  right,
  additionalText,
  removeVideo,
}: any) => {
  const classes = useStyles();
  const [values, setValues] = useState<State>({
    name: "",
    id: "",
    account_id: "",
  });

  useEffect(() => {
    setValues({
      name: data?.name,
      email: data?.email,
      title: data?.title,
      id: data?.id || data?.user_id,
      account_id: data?.account_id,
    });
  }, [data]);
  const messageVideo = () => (
    <div>
      Are you sure you want to permanently delete{" "}
      <b>{values.name || values.email || values.title}</b> from the system? This
      action cannot be undone.{" "}
      <b>{values.name || values.email || values.title} </b>could be in one or
      more playlists or channels.
    </div>
  );
  const message = () => (
    <div>
      Are you sure you really want to delete {right}{" "}
      <b>{values.name || values.email || values.title}</b>? This action cannot
      be undone. {additionalText}
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          {removeVideo ? "Delete video from playlist" : `Delete ${header}`}
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <div className={classes.text}>
          {header === "Video" ? messageVideo() : message()}
        </div>
        <div className={classes.error}>{error}</div>
        <div className={classes.buttons}>
          <Button
            className={`${classes.button} button-white`}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            className={`${classes.button} button`}
            onClick={() => {
              onDelete(values);
            }}
            variant="contained"
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
