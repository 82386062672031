import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  videodetails: {
    marginLeft: "32px",
    marginTop: "38px",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  heading: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    marginBottom: "29px",
    textDecoration: "none",
  },
  select: {
    width: "256px",
    margin: "0 25px",
  },
  content: {
    padding: "33px 44px",
  },

  searching: {
    display: "flex",
  },
  contentIcons: {
    position: "absolute",
    top: 0,
    right: "30px",
  },
  button: {
    marginRight: 30,
    "& button": {
      width: 256,
    },
  },

  paragraphHead: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    color: "black",
    margin: 0,
  },
  h3: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  paragraph: {
    color: "#000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "22px",
    letterSpacing: 0,
    textAlign: "left",
    margin: 0,
    marginTop: 10,
    marginBottom: 40,
  },
  tagHeader: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: "left",
    display: "inline",
  },
  tag: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: 0,
    textAlign: "left",
    display: "inline-flex",
    marginLeft: 8,
    marginRight: 20,
    marginBottom: 30,
    marginTop: 0,
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  pagination: {
    "& .Mui-selected": {
      background: "black",
      color: "white",
      borderRadius: 0,
    },
  },
  customTooltip: {
    opacity: 1,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "50px !important",
  },
}));
