import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  pagination: {
    marginBottom: 20,
    "& .Mui-selected": {
      background: "black",
      color: "white",
      borderRadius: 0,
    },
  },
}));
