import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Snackbar,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { MESSAGE_ALERT } from "../../../init/constants";

import { useStyles } from "../hooks/useStyles";
import { Autocomplete } from "@material-ui/lab";

const data = ["5%", "25%", "50%", "75%", "100%"];

export const Audio = ({ value, setValue, volume }: any) => {
  const classes = useStyles();
  const [alertOutside, setAlertOutside] = useState(false);
  const [values, setValues] = useState(volume);
  const [inputValue, setInputValue] = useState("");

  const handleSetValue = (value, reason = null) => {
    if (value > 100 || value < 0) {
      setAlertOutside(true);
    } else {
      if (reason === "blur") {
        if (!value.includes("%")) {
          setValues(`${value}%`);
          setInputValue(`${value}%`);
          setAlertOutside(false);
          handleChange({ target: { value: `${value}%` } }, "volumeValue");
        }
      } else {
        setValues(value);
        setInputValue(value);
        setAlertOutside(false);
        handleChange({ target: { value: value } }, "volumeValue");
      }
    }
  };

  const handleChange = (e, type) => {
    setValue(e, type);
  };

  const handleRange = (range) => {
    handleSetValue(range);
  };

  const handleClose = (event, reason) => {
    if (reason === "blur") {
      handleSetValue(event.target.value, reason);
    }
  };

  return (
    <div>
      <h3>Audio</h3>
      <FormControl component="fieldset" className={classes.type}>
        <RadioGroup
          aria-label="Type"
          name="type"
          value={value}
          onChange={(e) => handleChange(e, "isAudio")}
          className={classes.group}
        >
          <FormControlLabel value="on" control={<Radio />} label="On" />
          <FormControlLabel value="off" control={<Radio />} label="Off" />
        </RadioGroup>
      </FormControl>

      {value === "on" && (
        <FormControl variant="outlined" className={classes.select}>
          <Autocomplete
            id="volume"
            value={values}
            onClose={handleClose}
            onChange={(_, value) => {
              handleRange(value);
            }}
            inputValue={inputValue}
            onInputChange={(_, value) => {
              handleRange(value);
            }}
            options={data}
            renderInput={(params) => (
              <TextField {...params} label="Volume" variant="outlined" />
            )}
            fullWidth
          />
        </FormControl>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={alertOutside}
        autoHideDuration={6000}
        message={MESSAGE_ALERT}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setAlertOutside(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};
