import moment from "moment";

export const sortArrBy = (arr, type, isCreated, sort) => {
  if (!sort) {
    return arr || [];
  }

  const sorted = arr.concat().sort((a, b) => {
    if (isCreated) {
      const firstDate: any = moment(a.createdDate).format("X");
      const secondDate: any = moment(b.createdDate).format("X");
      return secondDate - firstDate;
    } else {
      const initial: any = a[type] ? a[type] : "";
      const final: any = a[type] ? b[type] : "";
      return initial > final ? 1 : -1;
    }
  });

  if (sort === `rev`) {
    return sorted;
  }

  return sorted.reverse();
};

export const sortArrByField = (arr, field) => {
  return arr.sort((a, b) => {
    const aTitle = a[field].toLocaleLowerCase();
    const bTitle = b[field].toLocaleLowerCase();
    if (aTitle < bTitle) {
      return -1;
    }
    if (aTitle > bTitle) {
      return 1;
    }
    return 0;
  });
};
