import { getAxiosInstance } from "./api";

export const getRightSholders = async (
  value,
  type,
  limit = 10,
  start = 0,
  sort = ""
) => {
  const axios = getAxiosInstance();
  const query = value && type ? `&q=${type}:${value}` : "";
  const params = {
    method: "GET",
    url: `/right-holders?limit=${limit}&start=${start}${query}&sort=${sort}`,
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getrightSholders] error", error);
    return error.response.status;
  }
};

export const deleteRightHolder = async (id) => {
  const axios = getAxiosInstance();
  const params = {
    method: "DELETE",
    url: `/right-holders/${id}`,
  };
  try {
    return await axios(params);
  } catch (error) {
    console.error("[removerightSholders] error", error);
    return error.response.status;
  }
};

export const createRightSholder = async (value) => {
  const axios = getAxiosInstance();
  const params = {
    method: "POST",
    url: `/right-holders`,
    data: JSON.stringify(value),
  };
  try {
    return await axios(params);
  } catch (error) {
    return error.response;
  }
};
