import React from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import { useStyles } from "../hooks/useStyles";

export const LogoOverlay = ({ configuration, handleConfiguration }: any) => {
  const classes = useStyles();

  return (
    <div>
      <h3>Logo Overlay</h3>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Type"
          name="type"
          value={configuration.isLogo}
          onChange={(e) => handleConfiguration(e, "isLogo")}
          className={classes.group}
        >
          <FormControlLabel value="on" control={<Radio />} label="On" />
          <FormControlLabel value="off" control={<Radio />} label="Off" />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
