import { getAxiosInstance } from "./api";
import { PAGINATION_PER_PAGE, SORT_DEFAULT } from "../init/constants";

export const getChannels = async ({
  perPage = 1000,
  page = 0,
  sort = SORT_DEFAULT,
  search = [],
} = {}) => {
  const start = page * perPage || 0;
  const axios = getAxiosInstance();
  const query = search ? `&q=name:${search}` : "";

  try {
    const response = await axios({
      method: "GET",
      url: `/channels?limit=${perPage}&start=${start}${query}&sort=${sort}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getChannels] error", error);
    return error.response.status;
  }
};

export const createChannel = async (data) => {
  const axios = getAxiosInstance();
  try {
    const response = await axios({
      method: "POST",
      url: `/channels`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    console.error("[createChannel] error", error.response);
    return error.response.status;
  }
};

export const deleteChannel = async (data) => {
  const axios = getAxiosInstance();
  try {
    await axios({
      method: "DELETE",
      url: `/channels/${data.id}`,
    });
    return true;
  } catch (error) {
    console.error("[removeChannel] error", error.response);
    return error.response.status;
  }
};

export const getChannel = async (id) => {
  const axios = getAxiosInstance();
  try {
    const response = await axios({
      method: "GET",
      url: `/channels/${id}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getChannel] error", error);
    return error.response.status;
  }
};

export const getChannelVideos = async (
  id,
  limit = PAGINATION_PER_PAGE,
  start = 0
) => {
  const axios = getAxiosInstance();
  try {
    const response = await axios({
      method: "GET",
      url: `/channels/${id}/videos?limit=${limit}&start=${start}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getChannelVideos] error", error);
    return error.response.status;
  }
};

export const updateChannel = async (data) => {
  const axios = getAxiosInstance();
  try {
    await axios({
      method: "PUT",
      url: `/channels/${data.id}`,
      data: JSON.stringify(data),
    });
    return true;
  } catch (error) {
    console.error("[updateChannel] error", error.response);
    return error.response.status;
  }
};
