// @ts-ignore
import React, { useEffect, useState, ReactElement } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../bus/user/actions";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { getLanguage } from "../../languages";
import { PropsParamaters } from "./video.interface";
import Upload from "./components/upload";
import { getRights } from "../../api/rights";
import { getErrorsHandler } from "../../errors/rights";
import ChipInput from "material-ui-chip-input";
import { useStyles } from "./hooks/useStyles";
import moment from "moment";
import { sortArrByField } from "../../utils/arraySorter";
import tags from "../../assets/tags/taxonomy-keywords.json";

const initialDate = {
  publishDate: null,
  publishDateOffsetHours: 0,
  takedownDate: null,
  takedownOffsetDays: undefined,
  tags: [],
  rights: "",
  rightsKey: "",
};
export const VideoUpload = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { location } = history;
  const { id } = useParams<PropsParamaters>();
  const [urlLng, setUrlLng] = useState("/en");
  const [progress, setProgress] = useState(20);
  const dispatch = useDispatch();
  const [rights, setRights] = useState([]);
  const [newTags, setTags] = useState([]);
  const [values, setValues] = useState(initialDate);
  const [activeRights, setActiveRights] = useState({
    id: "",
    title: "",
    description: null,
    brightcoveRestrictionId: "",
    allowToOverride: false,
    filtering: 0,
    countries: null,
    whitelistedDomains: null,
    blacklistedDomains: null,
    availability: {
      publishDate: null,
      publishDateOffsetHours: null,
      takedownDate: null,
      takedownOffsetDays: null,
      allowToOverrideAvailability: false,
    },
    rightsHolder: {
      id: "",
      title: "",
    },
    rightsHolderId: "",
  });
  const [videoData, setVideoData] = useState({
    publishedTime: null,
    expireTime: null,
    rightId: "",
    tags: [],
  });
  const [apply, setApply] = useState(false);
  const [publish, setPublish] = useState(3);
  const [takedown, setTakedown] = useState(3);

  const editPublish = (event) => {
    if (event === 2) {
      setValues({
        ...values,
        publishDateOffsetHours: void 0,
        publishDate: new Date(),
      });
    }
    setPublish(event);
  };

  const editTakedown = (event) => {
    if (event === 2) {
      setValues({ ...values, takedownOffsetDays: void 0, takedownDate: null });
    }
    setTakedown(event);
  };

  useEffect(() => {
    getSelectRights();

    dispatch(userActions.setLink("videoUpload"));
  }, []);

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  useEffect(() => {
    applyToAll();
  }, [values, publish]);

  const handleChange = (prop, event) => {
    if (prop === "rights") {
      setActiveRights(rights[event]);
      setPublish(3);
      setTakedown(3);
      setValues({
        ...values,
        rights: rights[event].id,
        rightsKey: event,
        publishDate: null,
        publishDateOffsetHours: 0,
        takedownDate: null,
        takedownOffsetDays: null,
      });
    } else {
      setValues({ ...values, [prop]: event });
    }
  };

  const handlePublisherClick = (event) => {
    setPublish(event);
  };

  const handleTakedownClick = (event) => {
    setTakedown(event);
  };

  const prepareVideoData = () => {
    let publishedTime = moment();
    let expireTime = undefined;
    const {
      publishDate,
      publishDateOffsetHours,
      takedownDate,
      takedownOffsetDays,
    } = activeRights.availability;

    if (publishDate) {
      publishedTime = moment(publishDate);
    }

    if (publishDateOffsetHours) {
      publishedTime = publishedTime
        .clone()
        .add(publishDateOffsetHours, "hours");
    }

    if (takedownDate) {
      expireTime = moment(takedownDate);
    }

    if (takedownOffsetDays || takedown === 1) {
      expireTime = publishedTime.clone().add(takedownOffsetDays, "days");
    }

    if (activeRights.availability.allowToOverrideAvailability) {
      if (values.publishDate && publish === 0) {
        publishedTime = moment(values.publishDate);
      }

      if (values.publishDateOffsetHours && publish === 1) {
        publishedTime = publishedTime
          .clone()
          .add(values.publishDateOffsetHours, "hours");
      }

      if (values.takedownDate && takedown === 0) {
        expireTime = moment(values.takedownDate);
      }

      if (values.takedownOffsetDays && takedown === 1) {
        expireTime = publishedTime
          .clone()
          .add(values.takedownOffsetDays, "days");
      }

      if (takedown === 2) {
        expireTime = null;
      }

      if (publish === 2) {
        publishedTime = moment();
      }
    }

    return {
      publishedTime: publishedTime && publishedTime.toISOString(),
      expireTime: expireTime && expireTime.toISOString(),
      tags: values.tags,
      rightId: values.rights,
    };
  };

  const getSelectRights = async () => {
    const response = await getRights(null, null, 1000000);

    if (typeof response === "number") {
      console.error(getErrorsHandler(response).title);
    } else {
      setRights(sortArrByField(response.rights, "title"));
    }
  };

  const handleDeleteChip = (chip, index) => {
    setValues({ ...values, tags: values.tags.filter((item) => item !== chip) });
    setTags([]);
  };

  const applyToAll = () => {
    const videoData = prepareVideoData();
    console.log("Video upload setting:", videoData);
    setVideoData(videoData);
    setApply(true);
  };

  const findAndExcludeItem = (nestedArr, item) => {
    const arrContaining = [];
    nestedArr.forEach((e) => {
      const words = e.split(" ");
      words.forEach((word) => {
        if (word.match(new RegExp(`^${item}.*`, "gi"))) {
          arrContaining.push(e);
        }
      });
    });
    return arrContaining.filter((filteredItem) => filteredItem != item);
  };

  const searchTags = (chip) => {
    if (chip.length > 1) {
      const findMatch = findAndExcludeItem(tags, chip).filter(
        (item) => !values.tags.includes(item)
      );
      setTags(findMatch);
    } else {
      setTags([]);
    }
  };
  const handleAddChip = (chip) => {
    const findMatch = findAndExcludeItem(tags, chip);
    setTags(findMatch);
    setValues({ ...values, tags: [...values.tags, chip] });
  };

  const disableAvailability =
    !activeRights?.availability?.allowToOverrideAvailability;

  return (
    <section className={classes.videodetails}>
      <div className={classes.heading}>
        <h1 className={classes.title}>Video Upload</h1>
      </div>
      <div className={classes.tags}>
        <FormControl variant="outlined" className={classes.input}>
          <InputLabel id="demo-simple-select-outlined-label">Right</InputLabel>
          <Select
            MenuProps={{ classes: { paper: classes.menuPaper } }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Rights"
            value={values.rightsKey}
            onChange={(event) => {
              handleChange("rights", event.target.value);
            }}
          >
            {rights.map((item, index) => (
              <MenuItem key={item.id} value={index}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {values.rights && (
          <div className={classes.rightsInfo}>
            <div>
              <h5>Publish rules</h5>
              <span>
                {activeRights.availability.publishDateOffsetHours
                  ? activeRights.availability.publishDateOffsetHours + " hour"
                  : "Immediately"}
              </span>
            </div>
            <div>
              <h5>Takedown rules</h5>
              <span>
                {activeRights.availability.takedownOffsetDays
                  ? activeRights.availability.takedownOffsetDays + " day"
                  : "None"}
              </span>
            </div>
          </div>
        )}
      </div>
      <h2 className={classes.subTitle}>Publish</h2>
      <div className={classes.inputs}>
        <FormControl disabled={disableAvailability} component="fieldset">
          <RadioGroup
            aria-label="Type"
            name="type"
            className={classes.group}
            value={publish}
            onChange={(e) => editPublish(Number(e.target.value))}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    variant="inline"
                    disabled={disableAvailability}
                    ampm={false}
                    className={classes.input}
                    inputVariant="outlined"
                    label="Publish Date"
                    onChange={(e) => handleChange("publishDate", e)}
                    onClick={(e) =>
                      !disableAvailability && handlePublisherClick(0)
                    }
                    value={values.publishDate}
                    onError={console.log}
                    disablePast
                    format="yyyy/MM/dd, HH:mm"
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={
                <TextField
                  className={classes.input}
                  disabled={disableAvailability}
                  id="title"
                  type="number"
                  label="Hours after upload"
                  variant="outlined"
                  inputProps={{
                    min: 1,
                    max: 1000,
                    step: 1,
                    onKeyDown: (event) => {
                      event.preventDefault();
                    },
                  }}
                  onClick={(e) =>
                    !disableAvailability && handlePublisherClick(1)
                  }
                  onChange={(e) => {
                    handleChange(
                      "publishDateOffsetHours",
                      Number(e.target.value)
                    );
                  }}
                  value={values.publishDateOffsetHours}
                />
              }
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label="Immediately"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <h2 className={classes.subTitle}>Takedown</h2>
      <div className={classes.inputs}>
        <FormControl disabled={disableAvailability} component="fieldset">
          <RadioGroup
            aria-label="Type"
            name="type"
            className={classes.group}
            value={takedown}
            onChange={(e) => editTakedown(Number(e.target.value))}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    className={classes.input}
                    inputVariant="outlined"
                    label="Takedown Date"
                    onChange={(e) => handleChange("takedownDate", e)}
                    onClick={(e) =>
                      !disableAvailability && handleTakedownClick(0)
                    }
                    value={
                      values.takedownDate ? Number(values.takedownDate) : null
                    }
                    onError={console.log}
                    disablePast
                    disabled={disableAvailability}
                    format="yyyy/MM/dd, HH:mm"
                  />
                </MuiPickersUtilsProvider>
              }
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={
                <FormControl
                  disabled={disableAvailability}
                  variant="outlined"
                  className={classes.input}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Hours/Days after upload
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Holdback (days)"
                    onChange={(e) =>
                      handleChange("takedownOffsetDays", e.target.value)
                    }
                    onClick={(e) =>
                      !disableAvailability && handleTakedownClick(1)
                    }
                    value={
                      values.takedownOffsetDays
                        ? Number(values.takedownOffsetDays)
                        : null
                    }
                  >
                    <MenuItem value={1}>24 hours</MenuItem>
                    <MenuItem value={2}>48 hours</MenuItem>
                    <MenuItem value={5}>5 days</MenuItem>
                    <MenuItem value={7}>7 days</MenuItem>
                    <MenuItem value={14}>14 days</MenuItem>
                    <MenuItem value={30}>30 days</MenuItem>
                  </Select>
                </FormControl>
              }
            />
            <FormControlLabel value={2} control={<Radio />} label="None" />
          </RadioGroup>
        </FormControl>
      </div>
      <h2 className={classes.subTitle}>Tags</h2>
      <div className={classes.blockVideo}>
        <ChipInput
          className={`
            ${classes.input} 
            ${classes.label} 
            ${newTags.length > 0 && classes.tagActive} 
            ${values.tags.length > 1 && classes.disableFlex}`}
          fullWidth
          variant="outlined"
          blurBehavior="clear"
          label="Tags"
          value={values.tags}
          onUpdateInput={(e) => searchTags(e.target.value)}
          onAdd={(chip) => handleAddChip(chip)}
          onDelete={(chip, index) => handleDeleteChip(chip, index)}
        />
        {newTags.length > 0 && (
          <>
            <div className={classes.divider} />
            <List component="div" className={classes.tagsList}>
              {newTags.map((e) => {
                return (
                  <ListItem key={e} button onClick={() => handleAddChip(e)}>
                    <ListItemText primary={e} />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </div>
      <div className={classes.upload}>
        {values.rights && <Upload data={apply ? videoData : null} />}
      </div>
    </section>
  );
};
