import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  popItemMenu: {
    "& .MuiPaper-root": {
      borderRadius: 0,
    },
    "& ul": {
      padding: 0,
    },
  },
  popItem: {
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
});
