import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { useStyles } from "../hooks/useStyles";
import { UploadImage } from "../../../components/upload-image";

export const ImageUploader = ({
  configuration,
  handleConfiguration,
  image,
  setImage,
  logoOverlayError,
  setLogoOverlayError,
}: any) => {
  const classes = useStyles();
  const { brandingLogo = "" } = configuration;
  const actualImage = image || brandingLogo;

  return (
    <>
      <UploadImage value={actualImage} setValue={setImage} />

      <FormControl
        variant="outlined"
        className={classes.select}
        error={logoOverlayError}
      >
        <InputLabel id="demo-simple-select-outlined-label">Position</InputLabel>
        <Select
          MenuProps={{
            transformOrigin: {
              vertical: 130,
              horizontal: 0,
            },
          }}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Search by"
          value={configuration.logoPosition}
          onChange={(e) => {
            setLogoOverlayError(false);
            handleConfiguration(e, "logoPosition");
          }}
        >
          <MenuItem value="topLeft">Top left</MenuItem>
          <MenuItem value="topRight">Top right</MenuItem>
          <MenuItem value="bottomLeft">Bottom left</MenuItem>
          <MenuItem value="bottomRight">Bottom right</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
