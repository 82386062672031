import React, { useEffect, useState } from "react";
import { CardVideo } from "../cardVideo";

import { useStyles } from "./hooks/useStyles";
import { RenderPagination } from "../../components/pagination/index";

export const VideoBlock = ({
  title,
  videos,
  count,
  getVideos,
  getList,
  clearVideos,
  setClearVideos,
}: any) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const [saveVideos, setSaveVideos] = useState([]);
  const [videosSelect, setVideosSelect] = useState([]);

  // validate if array saved match with videos and add parameter state, to pint gray
  const validateExistArray = () => {
    const map = new Map(saveVideos.map((o) => [o, o]));
    videos.forEach((o) =>
      map.has(o.id) ? (o.state = true) : (o.state = false)
    );
    setVideosSelect(videos);
  };

  const updatePlayList = (id) => {
    if (saveVideos.includes(id)) {
      setSaveVideos(saveVideos.filter((e) => e !== id));
    } else {
      saveVideos.length < 10 && setSaveVideos([...saveVideos, id]);
    }
    validateExistArray();
    setClearVideos(false);
  };

  useEffect(() => {
    validateExistArray();
  }, [videos]);

  useEffect(() => {
    if (clearVideos) {
      setSaveVideos([]);
    }
  }, [clearVideos]);

  useEffect(() => {
    getList(saveVideos);
    validateExistArray();
  }, [saveVideos]);

  return (
    <section>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.block}>
        {!videos.length ? (
          <div className={classes.noResults}>No results</div>
        ) : (
          <>
            {videosSelect.map((item, index) => {
              return (
                <CardVideo
                  key={index}
                  data={item}
                  playListId={updatePlayList}
                />
              );
            })}
            <RenderPagination
              limit={5}
              currentPage={currentPage}
              count={count}
              loadData={(value) => getVideos(value)}
              setCurrentPage={(value) => setCurrentPage(value)}
            />
          </>
        )}
      </div>
    </section>
  );
};
