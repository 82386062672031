import React from "react";
export const PublishIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 16L13 12L9 16"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 12V21"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3901 18.39C23.3965 17.2962 24.4059 14.9813 23.8423 12.7667C23.2786 10.5521 21.2853 9.00151 19.0001 9H17.7401C16.9112 5.79382 14.1925 3.42978 10.9023 3.0541C7.61205 2.67843 4.43047 4.36878 2.90016 7.30559C1.36984 10.2424 1.80715 13.8185 4.00008 16.3"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 16L13 12L9 16"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
