import { getAxiosInstance } from "./api";
export const getSemanticArticles = async ({
  perPage = 10,
  page = 0,
  q_type = null,
  query = null,
  sort = "",
}) => {
  const paginationQuery = `start=${
    page * perPage
  }&limit=${perPage}&q_type=${q_type}&q=${query}&sort=${sort}`;
  const axios = getAxiosInstance();
  const params = {
    method: "GET",
    url: `/semantic-article?${paginationQuery}`,
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getSemanticArticles] error", error);
    return error.response.status;
  }
};

export const getSemanticArticleById = async (id) => {
  const axios = getAxiosInstance();
  const params = {
    method: "GET",
    url: `/semantic-article/${id}`,
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getSemanticArticleById] error", error);
    return error.response.status;
  }
};

export const updateSemanticArticle = async (id, article, videos) => {
  const axios = getAxiosInstance();
  const params = {
    method: "PUT",
    url: `/semantic-article/${id}`,
    data: JSON.stringify({
      ...article,
      videos: videos.map((el) => ({ id: el.id })),
    }),
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[updateSemanticArticle] error", error);
    return error.response.status;
  }
};
