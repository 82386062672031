import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { DeleteModal } from "../../components/delete-modal";

import DateFnsUtils from "@date-io/date-fns";
import ChipInput from "material-ui-chip-input";
import { getLanguage, languageRightList } from "../../languages";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { SaveIcon } from "../../assets/image/save-icon";
import { RotateIcon } from "../../assets/image/rotate-icon";
import { getRightSholders } from "../../api/rightsholders";
import { updateRights, getRight, deleteRights } from "../../api/rights";
import { getErrorsHandler } from "../../errors/rights";
import { GeoFiltering, MISSING_FIELD_RIGHT } from "../../init/constants";
import { useStyles } from "./hooks/useStyles";
import countriesList from "../../assets/countries/countries.json";
import { getCountryShortName } from "../../utils/countriesHelper";

export const RightUpdate = () => {
  const classes = useStyles();
  const [urlLng, setUrlLng] = useState("/en");
  const { location } = useHistory();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [values, setValues] = useState({
    description: "",
    allowToOverride: false,
    blacklistedDomains: [],
    brightcoveRestrictionId: "0",
    countries: [],
    filtering: GeoFiltering.Unrestricted,
    rightsHolderId: "",
    title: "",
    language: { id: "", name: "" },
    whitelistedDomains: [],
    id: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState({
    title: "",
    isError: false,
  });
  const [rightHolders, setRightHolders] = useState([]);
  const [availability, setAvailability] = useState({
    allowToOverrideAvailability: false,
    publishDate: null,
    publishDateOffsetHours: undefined,
    takedownDate: null,
    takedownOffsetDays: undefined,
  });

  useEffect(() => {
    getDataRightsholders();
    getData(id);
    dispatch(userActions.setLink("rights"));
  }, []);

  useEffect(() => {
    const language = getLanguage(location.pathname);

    setUrlLng(language);
  }, [location]);

  const getData = async (id) => {
    const response = await getRight(id);
    if (typeof response === "number") {
      setOpenAlert(true);
      setEvent({ title: getErrorsHandler(response).title, isError: true });
    } else {
      setValues({
        ...response,
        countries: response.countries.map((el) => countriesList[el]),
      });
      setAvailability(response.availability);
      setDisableAllFields(!response.allowToOverride);
    }
  };

  const handleAddChip = (chip, prop) => {
    if (prop === "countries") {
      setValues({ ...values, [prop]: chip });
    } else {
      setValues({ ...values, [prop]: [...values[prop], chip] });
    }
  };
  const handleDeleteChip = (chip, prop) => {
    setValues({
      ...values,
      [prop]: values[prop].filter((item) => item !== chip),
    });
  };

  const getDataRightsholders = async () => {
    const response = await getRightSholders(null, null, 1000000);

    if (typeof response === "number") {
      setOpenAlert(true);
      setEvent({ title: getErrorsHandler(response).title, isError: true });
    } else {
      setRightHolders(response.rightHolders);
    }
  };

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event });
  };

  const handleChangeAvailability = (prop, event) => {
    const updateObject: any = {};
    if (prop === "publishDate") {
      updateObject.publishDateOffsetHours = null;
    }
    if (prop === "publishDateOffsetHours") {
      updateObject.publishDateOffsetHours = null;
    }
    if (prop === "takedownDate") {
      updateObject.takedownOffsetDays = null;
    }
    if (prop === "takedownOffsetDays") {
      updateObject.takedownDate = null;
    }
    updateObject[prop] = event;
    setAvailability({ ...availability, ...updateObject });
  };

  const onSubmit = async () => {
    if (!values.title) {
      setDisable(true);
      return;
    } else {
      setDisable(false);
      const rightsHolder = rightHolders.filter(
        (item) => item.id === values.rightsHolderId
      )[0];
      const response = await updateRights({
        ...values,
        availability,
        rightsHolder,
        countries: values.countries.map((el) => getCountryShortName(el)),
      });

      if (typeof response === "number") {
        setOpenAlert(true);
        setEvent({ title: getErrorsHandler(response).title, isError: true });
      } else {
        setEvent({ title: "Right saved", isError: false });
        setOpenAlert(true);
        getData(id);
      }
    }
  };

  const setRadioPublish = (event) => {
    if (event === 0) {
      setAvailability({
        ...availability,
        publishDate: new Date(),
        publishDateOffsetHours: null,
      });
    }
    if (event === 1) {
      setAvailability({
        ...availability,
        publishDate: null,
        publishDateOffsetHours: 0,
      });
    }
    if (event === 2) {
      setAvailability({
        ...availability,
        publishDate: null,
        publishDateOffsetHours: null,
      });
    }
  };

  const setRadioTakedown = (event) => {
    if (event === 0) {
      setAvailability({
        ...availability,
        takedownDate: new Date(),
        takedownOffsetDays: null,
      });
    }
    if (event === 1) {
      setAvailability({
        ...availability,
        takedownDate: null,
        takedownOffsetDays: 0,
      });
    }
    if (event === 2) {
      setAvailability({
        ...availability,
        takedownDate: null,
        takedownOffsetDays: null,
      });
    }
  };

  const deleteData = async (data) => {
    const response = await deleteRights(id);
    if (typeof response === "number") {
      setOpenAlert(true);
      setEvent({ title: getErrorsHandler(response).title, isError: true });
    } else {
      setOpenDialog(false);
      setOpenAlert(true);
      history.push(`${urlLng}/rights`);
    }
  };
  return (
    <section className={classes.playlist}>
      <Link to={`${urlLng}/rights`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Rights
      </Link>
      <div className={classes.heading}>
        <div className={classes.info}>
          <h1 className={classes.title}>Edit Rights</h1>
        </div>
        <div>
          <Button className={`button-white`} onClick={() => getData(id)}>
            <RotateIcon />
          </Button>
          <Button
            className={`button-white`}
            onClick={() => {
              onSubmit();
            }}
          >
            <SaveIcon />
          </Button>
          <Button
            className={`button-white`}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.inputs}>
          <TextField
            className={classes.input}
            id="title"
            label="Media Right Title"
            variant="outlined"
            onChange={(e) => handleChange("title", e.target.value)}
            value={values.title}
            required
            disabled={disableAllFields}
            error={disable && !values.title}
            helperText={disable && !values.title ? MISSING_FIELD_RIGHT : false}
          />
          <FormControl
            variant="outlined"
            className={classes.input}
            disabled={disableAllFields}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Rightsholder
            </InputLabel>
            <Select
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Holdback (days)"
              onChange={(e) => handleChange("rightsHolderId", e.target.value)}
              value={values.rightsHolderId}
            >
              {rightHolders.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleChange("allowToOverride", e.target.checked)
                }
                checked={values.allowToOverride}
                color="default"
                name="Allow Superadmin to override"
                inputProps={{ "aria-label": "checkbox with default color" }}
              />
            }
            label="Allow Superadmin to override"
          />
        </div>
        <TextField
          disabled={disableAllFields}
          id="short-description"
          label="Short Description"
          multiline
          onChange={(e) => handleChange("description", e.target.value)}
          value={values.description}
          rows={4}
          variant="outlined"
          className={classes.description}
        />
        <FormControl
          variant="outlined"
          className={classes.language}
          disabled={disableAllFields}
        >
          <InputLabel>Language</InputLabel>
          <Select
            MenuProps={{ classes: { paper: classes.menuPaper } }}
            autoWidth
            value={values?.language?.id}
            onChange={(e) => handleChange("language", { id: e.target.value })}
          >
            {languageRightList.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <h2 className={classes.subTitle}>Publish</h2>
        <div className={classes.inputs}>
          <FormControl
            component="fieldset"
            className={classes.radioGroup}
            disabled={disableAllFields}
          >
            <RadioGroup
              aria-label="Type"
              name="type"
              className={classes.group}
              value={
                availability.publishDate !== null
                  ? 0
                  : availability.publishDateOffsetHours !== null
                  ? 1
                  : 2
              }
              onChange={(e) => setRadioPublish(Number(e.target.value))}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      disabled={disableAllFields}
                      variant="inline"
                      ampm={false}
                      className={classes.input}
                      inputVariant="outlined"
                      label="Publish Date*"
                      onChange={(e) =>
                        handleChangeAvailability("publishDate", e)
                      }
                      value={availability.publishDate}
                      onError={console.log}
                      disablePast
                      format="yyyy/MM/dd, HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                }
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={
                  <TextField
                    disabled={disableAllFields}
                    className={classes.input}
                    id="title"
                    type="number"
                    label="Hours after upload"
                    variant="outlined"
                    onChange={(e) => {
                      Number(e.target.value) <= 24
                        ? handleChangeAvailability(
                            "publishDateOffsetHours",
                            Number(e.target.value)
                          )
                        : null;
                    }}
                    value={availability.publishDateOffsetHours}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                }
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Immediately"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <h2 className={classes.subTitle}>Takedown</h2>
        <div className={classes.inputs}>
          <FormControl
            component="fieldset"
            className={classes.radioGroup}
            disabled={disableAllFields}
          >
            <RadioGroup
              aria-label="Type"
              name="type"
              className={classes.group}
              value={
                availability.takedownDate !== null
                  ? 0
                  : availability.takedownOffsetDays !== null
                  ? 1
                  : 2
              }
              onChange={(e) => setRadioTakedown(Number(e.target.value))}
            >
              <FormControlLabel
                value={0}
                control={<Radio />}
                label={
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      disabled={disableAllFields}
                      variant="inline"
                      ampm={false}
                      className={classes.input}
                      inputVariant="outlined"
                      label="Takedown Date*"
                      onChange={(e) =>
                        handleChangeAvailability("takedownDate", e)
                      }
                      value={availability.takedownDate}
                      onError={console.log}
                      disablePast
                      format="yyyy/MM/dd, HH:mm"
                    />
                  </MuiPickersUtilsProvider>
                }
              />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={
                  <FormControl
                    variant="outlined"
                    className={classes.input}
                    disabled={disableAllFields}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Hours/Days after upload
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Holdback (days)"
                      onChange={(e) =>
                        handleChangeAvailability(
                          "takedownOffsetDays",
                          e.target.value
                        )
                      }
                      value={Number(availability.takedownOffsetDays)}
                    >
                      <MenuItem value={1}>24 hours</MenuItem>
                      <MenuItem value={2}>48 hours</MenuItem>
                      <MenuItem value={5}>5 days</MenuItem>
                      <MenuItem value={7}>7 days</MenuItem>
                      <MenuItem value={14}>14 days</MenuItem>
                      <MenuItem value={30}>30 days</MenuItem>
                    </Select>
                  </FormControl>
                }
              />
              <FormControlLabel value={2} control={<Radio />} label="None" />
            </RadioGroup>
          </FormControl>
        </div>
        <FormControlLabel
          disabled={disableAllFields}
          control={
            <Checkbox
              onChange={(e) =>
                handleChangeAvailability(
                  "allowToOverrideAvailability",
                  e.target.checked
                )
              }
              checked={availability.allowToOverrideAvailability}
              color="default"
              name="Allow Superadmin to override"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
          }
          label="Allow override"
        />
        <h2 className={classes.subTitle}>Geo Filtering</h2>
        <div className={classes.inputs}>
          <FormControl
            variant="outlined"
            className={classes.input}
            disabled={disableAllFields}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Filtering Options
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Filtering Options"
              onChange={(e) => handleChange("filtering", e.target.value)}
              value={values.filtering}
            >
              <MenuItem value={GeoFiltering.Unrestricted}>
                Unrestricted
              </MenuItem>
              <MenuItem value={GeoFiltering.AllowViewing}>
                Allow viewing from the following countries
              </MenuItem>
              <MenuItem value={GeoFiltering.PreventViewing}>
                Prevent viewing from the following countries
              </MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            multiple
            className={classes.input}
            options={Object.values(countriesList).sort()}
            getOptionLabel={(data) => data}
            onChange={(_, e) => handleAddChip(e, "countries")}
            value={values.countries.sort()}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Countries" />
            )}
          />
        </div>

        <h2 className={classes.subTitle}>Domain Restriction</h2>
        <span className={classes.information}>
          Domain restrictions allow the user to limit video playback based upon
          domain.
        </span>
        <div className={classes.inputs}>
          <ChipInput
            disabled={disableAllFields}
            className={classes.input}
            fullWidth
            variant="outlined"
            label="Blacklisted Domains"
            value={values.blacklistedDomains}
            onAdd={(chip) => handleAddChip(chip, "blacklistedDomains")}
            onDelete={(chip, index) =>
              handleDeleteChip(chip, "blacklistedDomains")
            }
          />

          <ChipInput
            disabled={disableAllFields}
            className={classes.input}
            fullWidth
            variant="outlined"
            label="Whitelisted Domains"
            value={values.whitelistedDomains}
            onAdd={(chip) => handleAddChip(chip, "whitelistedDomains")}
            onDelete={(chip, index) =>
              handleDeleteChip(chip, "whitelistedDomains")
            }
          />
        </div>
        <div className={classes.buttons}>
          <Button
            className={`button-white`}
            onClick={() => {
              history.push(`${urlLng}/rights`);
            }}
          >
            Cancel
          </Button>
        </div>
        <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={() => setOpenAlert(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert severity={event.isError ? "error" : "success"}>
            {event.title}
          </MuiAlert>
        </Snackbar>
      </div>
      <DeleteModal
        open={openDialog}
        setOpen={setOpenDialog}
        data={values}
        onDelete={(data) => deleteData(data)}
        header="Right"
        right="this right"
      />
    </section>
  );
};
