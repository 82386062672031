import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton, Button, TextField } from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import Alert from "@material-ui/lab/Alert";

interface State {
  title: string;
  id: string;
}

const initialState = {
  title: "",
  id: "",
};

export const RightSholdersModal = ({
  open,
  setOpen,
  createRightHolder,
}: any) => {
  const classes = useStyles();
  const [values, setValues] = useState<State>({
    title: "",
    id: "",
  });
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [disable, setDisable] = useState(false);

  const reset = () => {
    setValues({ title: "", id: "" });
  };

  const closeModal = () => {
    setOpen(false);
    setError("");
    setIsError(false);
    reset();
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      isError && setIsError(false);
      setValues({ ...values, [prop]: event.target.value });
    };

  const onSubmit = async (data) => {
    if (!data.id || !data.title) {
      setDisable(true);
    } else {
      setDisable(false);
      const res = await createRightHolder(data);
      if (res.isError) {
        setOpen(false);
        setValues(initialState);
      } else {
        setError(res.error);
        setIsError(true);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          Add Rightsholder
          <IconButton
            aria-label="close"
            onClick={() => {
              closeModal();
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <form autoComplete="off" className={classes.form}>
          <TextField
            className={classes.input}
            id="Name"
            label="Rightsholder name"
            variant="outlined"
            onChange={handleChange("title")}
            value={values.title}
            inputProps={{ maxLength: 40 }}
            required
            error={disable && !values.title}
          />
          <TextField
            className={classes.input}
            id="ID"
            label="ID"
            variant="outlined"
            onChange={handleChange("id")}
            value={values.id}
            inputProps={{ maxLength: 40 }}
            required
            error={disable && !values.id}
          />
          {isError && <Alert severity="error">{error}</Alert>}
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              onClick={() => {
                closeModal();
              }}
            >
              Discard
            </Button>
            <Button
              className={`${classes.button} button`}
              onClick={() => onSubmit(values)}
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
