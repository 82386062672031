import React from "react";
export const LockIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2222 11H5.77778C4.79594 11 4 11.8954 4 13V20C4 21.1046 4.79594 22 5.77778 22H18.2222C19.2041 22 20 21.1046 20 20V13C20 11.8954 19.2041 11 18.2222 11Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UnlockIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2222 11H2.77778C1.79594 11 1 11.8954 1 13V20C1 21.1046 1.79594 22 2.77778 22H15.2222C16.2041 22 17 21.1046 17 20V13C17 11.8954 16.2041 11 15.2222 11Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 11V7.00003C12.9988 5.76008 13.4583 4.5639 14.2894 3.6437C15.1205 2.7235 16.2638 2.14493 17.4975 2.02032C18.7312 1.89571 19.9671 2.23393 20.9655 2.96934C21.9638 3.70475 22.6533 4.78488 22.9 6.00003"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
