import React, { ReactElement, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { CloseOpticIcon, OpticIcon } from "../../assets/image/optic-icon";
import { SaveIcon } from "../../assets/image/save-icon";
import { LockIcon, UnlockIcon } from "../../assets/image/lock-icon";
import { DocumentIcon } from "../../assets/image/document-icon";
import { Button, Box, Snackbar } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import { getLanguage } from "../../languages";
import { SemanticArticlesPlaylistTable } from "../../components/semantic-articles-playlist-table";
import semanticArticlesPlayer from "../../mock-data/semantic-articles-player.json";
import { useStyles } from "./hooks/useStyles";
import { RenderPagination } from "../../components/pagination/index";
import {
  getSemanticArticleById,
  updateSemanticArticle,
} from "../../api/semantic-article";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../init/rootReducer";
import { semanticArticleActions } from "../../bus/semantic-article/actions";
import MuiAlert from "@material-ui/lab/Alert";
import { AddVideoModal } from "../../components/add-video-modal";
import { getVideos, getVideo, deleteVideo } from "../../api/videos";
import { getErrorsHandler } from "../../errors/playlists";

import { DeleteModal } from "../../components/delete-modal";
// import * as api from '../../api/videos'

const SORT_DEFAULT = "createdDate:DESC";
export const SemanticArticlesPlaylist = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { location } = history;
  const { id } = useParams<{ id: string }>();
  const [urlLng, setUrlLng] = useState("/en");
  const [videosToAdd, setVideosToAdd] = useState([]);
  const [isLoaded, setInitialData] = useState(false);
  const [openSaveAlert, setOpenSaveAlert] = useState(false);
  const [openAddVideo, setOpenAddVideo] = useState(false);
  const [length, setLength] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertFail, setOpenAlertFail] = useState(false);
  const [textSuccess, setTextSuccess] = useState("");
  const [error, setError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [data, setData] = useState({});
  const { article, videos } = useSelector(
    (state: RootState) => state.semanticArticle
  );

  useEffect(() => {
    getData("", "", 10, 0);
  }, [article.locked]);

  const getData = async (query, name = "name", perPage = 10, page = 0) => {
    const { article, videos } = await getSemanticArticleById(id);
    dispatch(semanticArticleActions.loadData({ article, videos }));
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  const onSave = () => {
    updateSemanticArticle(id, article, videos);
    setOpenSaveAlert(true);
    setTextSuccess("Playlist saved");
  };

  const onLock = (data) => {
    dispatch(semanticArticleActions.lockArticle(data));
    updateSemanticArticle(id, { ...article, locked: !data }, videos).then(() =>
      getData("", "", 10, 0)
    );
  };

  const onHide = (data) => {
    return () => {
      dispatch(semanticArticleActions.hideArticle(data));
      updateSemanticArticle(id, { ...article, hide: data }, videos);
    };
  };

  const getDataVideos = async (
    page = 0,
    query = "",
    name = "name",
    perPage = 5
  ) => {
    const { videos, total } = await getVideos(
      page * 5,
      query,
      name,
      perPage,
      SORT_DEFAULT
    );
    setVideosToAdd(videos);
    setLength(total);
  };

  const handleAddPlaylistModal = async (videoId) => {
    const video = await getVideo(videoId);
    const response = await updateSemanticArticle(id, article, [
      video,
      ...videos,
    ]);

    if (typeof response === "number") {
      setOpenAlertFail(true);
      setError(getErrorsHandler(response).title);
    } else {
      setTextSuccess("Video Added");
      setOpenAlert(true);
      setOpenAddVideo(false);
      getData("", "", 10, 0);
    }
  };

  const handleAddVideos = () => {
    setOpenAddVideo(true);
    getDataVideos();
  };
  const handleDeleteConfirm = async (response) => {
    await deleteVideo(response.id);
    dispatch(semanticArticleActions.deleteVideo(response.id));
    setTextSuccess("Video deleted");
    setOpenAlert(true);
    setOpenDeleteModal(false);
  };
  const url =
    article && article.url && article.url[0] === "."
      ? `https://www${article.url}`
      : `https://www.${article.url}`;

  return (
    <section className={classes.videodetails}>
      <Box>
        <section>
          <div className={classes.heading}>
            <h1 className={classes.title}>Semantic Article Playlist {id}</h1>
            <section>
              {article.locked ? (
                <Button
                  className={`button-white`}
                  data-tip="Unlock"
                  onClick={() => onLock(true)}
                >
                  <LockIcon />
                </Button>
              ) : (
                <Button
                  className={`button-white`}
                  data-tip="Lock"
                  onClick={() => onLock(false)}
                >
                  <UnlockIcon />
                </Button>
              )}
              {!article.hide ? (
                <Button
                  className={`button-white`}
                  data-tip="Hide"
                  onClick={onHide(true)}
                >
                  <OpticIcon />
                </Button>
              ) : (
                <Button
                  className={`button-white`}
                  data-tip="Show"
                  onClick={onHide(false)}
                >
                  <CloseOpticIcon />
                </Button>
              )}
              <Button
                className={`button-white`}
                data-tip="Save"
                onClick={onSave}
              >
                <SaveIcon />
              </Button>
              <Link to={{ pathname: `${url}` }} target="_blank">
                <Button className={`button-white`} data-tip="View URL">
                  <DocumentIcon />
                </Button>
              </Link>
            </section>
          </div>
        </section>
      </Box>
      <p className={classes.tag}>{url}</p>
      {article.locked && (
        <div className={classes.button}>
          <Button
            onClick={handleAddVideos}
            className={`${classes.button} button`}
            variant="contained"
          >
            Add Videos
          </Button>
          <p className={classes.tag}>Playlist may contain up to 32 Videos</p>
        </div>
      )}

      <ReactTooltip
        backgroundColor="#fff"
        className={classes.customTooltip}
        place="bottom"
        textColor="#1A1818"
      />
      {isLoaded && videos && !videos.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <SemanticArticlesPlaylistTable
            onDeleteVideo={(e) => {
              setData(e), setOpenDeleteModal(true);
            }}
          />
          <RenderPagination
            limit={null}
            currentPage={0}
            count={semanticArticlesPlayer.total}
            loadData={(value) => getData("", "", 10, value)}
          />
        </>
      )}
      <Snackbar
        open={openSaveAlert}
        autoHideDuration={4000}
        onClose={() => setOpenSaveAlert(false)}
      >
        <MuiAlert severity="success">Playlist saved</MuiAlert>
      </Snackbar>

      <AddVideoModal
        open={openAddVideo}
        setOpen={setOpenAddVideo}
        videos={videosToAdd}
        count={length}
        getVideos={getDataVideos}
        AddPlaylistModal={(e) => {
          handleAddPlaylistModal(e);
        }}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={data}
        header="Semantic article Playist"
        onDelete={(data) => handleDeleteConfirm(data)}
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="success">{textSuccess}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertFail}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
    </section>
  );
};
