// Types
import { types } from "./types";

export const userActions = Object.freeze({
  setName: (payload) => {
    return {
      type: types.SET_NAME,
      payload,
    };
  },
  setUserName: (payload) => {
    return {
      type: types.SET_USERNAME,
      payload,
    };
  },
  setLogin: (payload) => {
    return {
      type: types.SET_LOGIN,
      payload,
    };
  },
  setUsers: (payload) => {
    return {
      type: types.SET_USERS,
      payload,
    };
  },
  setLink: (payload) => {
    return {
      type: types.SET_LINK,
      payload,
    };
  },
  setRole: (payload) => {
    return {
      type: types.SET_ROLE,
      payload,
    };
  },
  setRoleAdmin: (payload) => {
    return {
      type: types.SET_ROLE_ADMIN,
      payload,
    };
  },
  setPublisher: (payload) => {
    return {
      type: types.SET_PUBLISHER,
      payload,
    };
  },
  setPublisherLongitude: (payload) => {
    return {
      type: types.SET_PUBLISHER_LONGITUDE,
      payload,
    };
  },
  setPublishers: (payload) => {
    return {
      type: types.SET_PUBLISHERS,
      payload,
    };
  },
});
