import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { useStyles } from "../hooks/useStyles";

export const Video = ({ value, setValue }: any) => {
  const classes = useStyles();

  const handleChange = (e) => {
    setValue(e, "video");
  };

  return (
    <div>
      <h3>Video</h3>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Type"
          name="type"
          value={value}
          onChange={handleChange}
          className={classes.group}
        >
          <FormControlLabel
            value="clickToPLay"
            control={<Radio />}
            label="Click to play"
          />
          <FormControlLabel
            value="autoPlay"
            control={<Radio />}
            label="Auto play"
          />
          <FormControlLabel
            value="scrollToPlay"
            control={<Radio />}
            label="Scroll to play"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
