export const PAGINATION_PER_PAGE = 10;
export const LATEST_VIDEO_LIMIT = 6;
export const MISSING_FIELD = "Missing field.";
export const MISSING_FIELD_RIGHT = "Please fill in Media Right Title field";
export const ROLE_ADMIN = "admin";
export const ROLE_PUBLISHER = "publisher";
export const SMALL_DEFAULT_IMAGE =
  "https://via.placeholder.com/70x40/000000/FFFFFF";
export const ADDITIONAL_TEXT = "could be in one or more manual playlists";
export const MESSAGE_ALERT = "Value must be between 0 and 100.";

export const SORT_DEFAULT = "name:ASC";
export const SORT_DEFAULT_NAME = "name:ASC";
export const SORT_DEFAULT_CREATE = "createdDate:DESC";
export const SORT_DEFAULT_ICON_NAME = "DESCname";
export const SORT_DEFAULT_ICON_CREATE = "DESCcreatedDate";

export enum GeoFiltering {
  Unrestricted = 0,
  AllowViewing = 1,
  PreventViewing = 2,
}

export const COUNTRIES = [
  { country: "Afghanistan", abbreviation: "af" },
  {
    country: "Albania",
    abbreviation: "al",
  },
  { country: "Algeria", abbreviation: "dz" },
  {
    country: "American Samoa",
    abbreviation: "as",
  },
  { country: "Andorra", abbreviation: "ad" },
  { country: "Angola", abbreviation: "ao" },
  {
    country: "Anguilla",
    abbreviation: "ai",
  },
  { country: "Antarctica", abbreviation: "aq" },
  {
    country: "Antigua and Barbuda",
    abbreviation: "ag",
  },
  { country: "Argentina", abbreviation: "ar" },
  { country: "Armenia", abbreviation: "am" },
  {
    country: "Aruba",
    abbreviation: "aw",
  },
  { country: "Australia", abbreviation: "au" },
  {
    country: "Austria",
    abbreviation: "at",
  },
  { country: "Azerbaijan", abbreviation: "az" },
  { country: "Bahamas", abbreviation: "bs" },
  {
    country: "Bahrain",
    abbreviation: "bh",
  },
  { country: "Bangladesh", abbreviation: "bd" },
  {
    country: "Barbados",
    abbreviation: "bb",
  },
  { country: "Belarus", abbreviation: "by" },
  { country: "Belgium", abbreviation: "be" },
  {
    country: "Belize",
    abbreviation: "bz",
  },
  { country: "Benin", abbreviation: "bj" },
  { country: "Bermuda", abbreviation: "bm" },
  {
    country: "Bhutan",
    abbreviation: "bt",
  },
  { country: "Bolivia", abbreviation: "bo" },
  {
    country: "Bosnia and Herzegovina",
    abbreviation: "ba",
  },
  { country: "Botswana", abbreviation: "bw" },
  {
    country: "Bouvet Island",
    abbreviation: "bv",
  },
  { country: "Brazil", abbreviation: "br" },
  {
    country: "British Indian Ocean Territory",
    abbreviation: "io",
  },
  { country: "Brunei", abbreviation: "bn" },
  {
    country: "Bulgaria",
    abbreviation: "bg",
  },
  { country: "Burkina Faso", abbreviation: "bf" },
  {
    country: "Burundi",
    abbreviation: "bi",
  },
  { country: "Cambodia", abbreviation: "kh" },
  { country: "Cameroon", abbreviation: "cm" },
  {
    country: "Canada",
    abbreviation: "ca",
  },
  { country: "Cape Verde", abbreviation: "cv" },
  {
    country: "Cayman Islands",
    abbreviation: "ky",
  },
  { country: "Central African Republic", abbreviation: "cf" },
  {
    country: "Chad",
    abbreviation: "td",
  },
  { country: "Chile", abbreviation: "cl" },
  {
    country: "China",
    abbreviation: "cn",
  },
  { country: "Christmas Island", abbreviation: "cx" },
  {
    country: "Cocos (Keeling) Islands",
    abbreviation: "cc",
  },
  { country: "Colombia", abbreviation: "co" },
  { country: "Comoros", abbreviation: "km" },
  {
    country: "Congo",
    abbreviation: "cg",
  },
  { country: "Cook Islands", abbreviation: "ck" },
  {
    country: "Costa Rica",
    abbreviation: "cr",
  },
  { country: "Croatia", abbreviation: "hr" },
  { country: "Cuba", abbreviation: "cu" },
  {
    country: "Cyprus",
    abbreviation: "cy",
  },
  { country: "Czech Republic", abbreviation: "cz" },
  {
    country: "Denmark",
    abbreviation: "dk",
  },
  { country: "Djibouti", abbreviation: "dj" },
  {
    country: "Dominica",
    abbreviation: "dm",
  },
  { country: "Dominican Republic", abbreviation: "do" },
  {
    country: "East Timor",
    abbreviation: "tp",
  },
  { country: "Ecuador", abbreviation: "ec" },
  { country: "Egypt", abbreviation: "eg" },
  {
    country: "El Salvador",
    abbreviation: "sv",
  },
  { country: "Equatorial Guinea", abbreviation: "gq" },
  {
    country: "Eritrea",
    abbreviation: "er",
  },
  { country: "Estonia", abbreviation: "ee" },
  {
    country: "Ethiopia",
    abbreviation: "et",
  },
  { country: "Falkland Islands", abbreviation: "fk" },
  {
    country: "Faroe Islands",
    abbreviation: "fo",
  },
  { country: "Fiji Islands", abbreviation: "fj" },
  {
    country: "Finland",
    abbreviation: "fi",
  },
  { country: "France", abbreviation: "fr" },
  {
    country: "French Guiana",
    abbreviation: "gf",
  },
  { country: "French Polynesia", abbreviation: "pf" },
  {
    country: "French Southern territories",
    abbreviation: "tf",
  },
  { country: "Gabon", abbreviation: "ga" },
  { country: "Gambia", abbreviation: "gm" },
  {
    country: "Georgia",
    abbreviation: "ge",
  },
  { country: "Germany", abbreviation: "de" },
  { country: "Ghana", abbreviation: "gh" },
  {
    country: "Gibraltar",
    abbreviation: "gi",
  },
  { country: "Greece", abbreviation: "gr" },
  { country: "Greenland", abbreviation: "gl" },
  {
    country: "Grenada",
    abbreviation: "gd",
  },
  { country: "Guadeloupe", abbreviation: "gp" },
  { country: "Guam", abbreviation: "gu" },
  {
    country: "Guatemala",
    abbreviation: "gt",
  },
  { country: "Guernsey", abbreviation: "gg" },
  {
    country: "Guinea",
    abbreviation: "gn",
  },
  { country: "Guinea-Bissau", abbreviation: "gw" },
  { country: "Guyana", abbreviation: "gy" },
  {
    country: "Haiti",
    abbreviation: "ht",
  },
  { country: "Heard Island and McDonald Islands", abbreviation: "hm" },
  {
    country: "Holy See (Vatican City State)",
    abbreviation: "va",
  },
  { country: "Honduras", abbreviation: "hn" },
  { country: "Hong Kong", abbreviation: "hk" },
  {
    country: "Hungary",
    abbreviation: "hu",
  },
  { country: "Iceland", abbreviation: "is" },
  { country: "India", abbreviation: "in" },
  {
    country: "Indonesia",
    abbreviation: "id",
  },
  { country: "Iran", abbreviation: "ir" },
  { country: "Iraq", abbreviation: "iq" },
  {
    country: "Ireland",
    abbreviation: "ie",
  },
  { country: "Isle of Man", abbreviation: "im" },
  { country: "Israel", abbreviation: "il" },
  {
    country: "Italy",
    abbreviation: "it",
  },
  { country: "Ivory Coast", abbreviation: "ci" },
  { country: "Jamaica", abbreviation: "jm" },
  {
    country: "Japan",
    abbreviation: "jp",
  },
  { country: "Jersey", abbreviation: "je" },
  { country: "Jordan", abbreviation: "jo" },
  {
    country: "Kazakhstan",
    abbreviation: "kz",
  },
  { country: "Kenya", abbreviation: "ke" },
  { country: "Kiribati", abbreviation: "ki" },
  {
    country: "Kuwait",
    abbreviation: "kw",
  },
  { country: "Kyrgyzstan", abbreviation: "kg" },
  { country: "Laos", abbreviation: "la" },
  {
    country: "Latvia",
    abbreviation: "lv",
  },
  { country: "Lebanon", abbreviation: "lb" },
  { country: "Lesotho", abbreviation: "ls" },
  {
    country: "Liberia",
    abbreviation: "lr",
  },
  { country: "Libyan Arab Jamahiriya", abbreviation: "ly" },
  {
    country: "Liechtenstein",
    abbreviation: "li",
  },
  { country: "Lithuania", abbreviation: "lt" },
  { country: "Luxembourg", abbreviation: "lu" },
  {
    country: "Macao",
    abbreviation: "mo",
  },
  { country: "North Macedonia", abbreviation: "mk" },
  {
    country: "Madagascar",
    abbreviation: "mg",
  },
  { country: "Malawi", abbreviation: "mw" },
  { country: "Malaysia", abbreviation: "my" },
  {
    country: "Maldives",
    abbreviation: "mv",
  },
  { country: "Mali", abbreviation: "ml" },
  {
    country: "Malta",
    abbreviation: "mt",
  },
  { country: "Marshall Islands", abbreviation: "mh" },
  {
    country: "Martinique",
    abbreviation: "mq",
  },
  { country: "Mauritania", abbreviation: "mr" },
  {
    country: "Mauritius",
    abbreviation: "mu",
  },
  { country: "Mayotte", abbreviation: "yt" },
  {
    country: "Mexico",
    abbreviation: "mx",
  },
  { country: "Micronesia, Federated States of", abbreviation: "fm" },
  {
    country: "Moldova",
    abbreviation: "md",
  },
  { country: "Monaco", abbreviation: "mc" },
  { country: "Mongolia", abbreviation: "mn" },
  {
    country: "Montenegro",
    abbreviation: "me",
  },
  { country: "Montserrat", abbreviation: "ms" },
  {
    country: "Morocco",
    abbreviation: "ma",
  },
  { country: "Mozambique", abbreviation: "mz" },
  { country: "Myanmar", abbreviation: "mm" },
  {
    country: "Namibia",
    abbreviation: "na",
  },
  { country: "Nauru", abbreviation: "nr" },
  { country: "Nepal", abbreviation: "np" },
  {
    country: "Netherlands",
    abbreviation: "nl",
  },
  { country: "Netherlands Antilles", abbreviation: "an" },
  {
    country: "New Caledonia",
    abbreviation: "nc",
  },
  { country: "New Zealand", abbreviation: "nz" },
  {
    country: "Nicaragua",
    abbreviation: "ni",
  },
  { country: "Niger", abbreviation: "ne" },
  { country: "Nigeria", abbreviation: "ng" },
  {
    country: "Niue",
    abbreviation: "nu",
  },
  { country: "Norfolk Island", abbreviation: "nf" },
  {
    country: "North Korea",
    abbreviation: "kp",
  },
  { country: "Northern Ireland", abbreviation: "gb" },
  {
    country: "Northern Mariana Islands",
    abbreviation: "mp",
  },
  { country: "Norway", abbreviation: "no" },
  { country: "Oman", abbreviation: "om" },
  {
    country: "Pakistan",
    abbreviation: "pk",
  },
  { country: "Palau", abbreviation: "pw" },
  { country: "Palestine", abbreviation: "ps" },
  {
    country: "Panama",
    abbreviation: "pa",
  },
  { country: "Papua New Guinea", abbreviation: "pg" },
  {
    country: "Paraguay",
    abbreviation: "py",
  },
  { country: "Peru", abbreviation: "pe" },
  { country: "Philippines", abbreviation: "ph" },
  {
    country: "Pitcairn",
    abbreviation: "pn",
  },
  { country: "Poland", abbreviation: "pl" },
  {
    country: "Portugal",
    abbreviation: "pt",
  },
  { country: "Puerto Rico", abbreviation: "pr" },
  { country: "Qatar", abbreviation: "qa" },
  {
    country: "Reunion",
    abbreviation: "re",
  },
  { country: "Romania", abbreviation: "ro" },
  {
    country: "Russian Federation",
    abbreviation: "ru",
  },
  { country: "Rwanda", abbreviation: "rw" },
  {
    country: "Saint Helena",
    abbreviation: "sh",
  },
  { country: "Saint Kitts and Nevis", abbreviation: "kn" },
  {
    country: "Saint Lucia",
    abbreviation: "lc",
  },
  { country: "Saint Pierre and Miquelon", abbreviation: "pm" },
  {
    country: "Saint Vincent and the Grenadines",
    abbreviation: "vc",
  },
  { country: "Samoa", abbreviation: "ws" },
  {
    country: "San Marino",
    abbreviation: "sm",
  },
  { country: "Sao Tome and Principe", abbreviation: "st" },
  {
    country: "Saudi Arabia",
    abbreviation: "sa",
  },
  { country: "Senegal", abbreviation: "sn" },
  { country: "Serbia", abbreviation: "rs" },
  {
    country: "Seychelles",
    abbreviation: "sc",
  },
  { country: "Sierra Leone", abbreviation: "sl" },
  {
    country: "Singapore",
    abbreviation: "sg",
  },
  { country: "Slovakia", abbreviation: "sk" },
  {
    country: "Slovenia",
    abbreviation: "si",
  },
  { country: "Solomon Islands", abbreviation: "sb" },
  {
    country: "Somalia",
    abbreviation: "so",
  },
  { country: "South Africa", abbreviation: "za" },
  {
    country: "South Georgia and the South Sandwich Islands",
    abbreviation: "gs",
  },
  { country: "South Korea", abbreviation: "kr" },
  {
    country: "South Sudan",
    abbreviation: "ss",
  },
  { country: "Spain", abbreviation: "es" },
  { country: "Sri Lanka", abbreviation: "lk" },
  {
    country: "Sudan",
    abbreviation: "sd",
  },
  { country: "Suriname", abbreviation: "sr" },
  {
    country: "Svalbard and Jan Mayen",
    abbreviation: "sj",
  },
  { country: "Swaziland", abbreviation: "sz" },
  {
    country: "Sweden",
    abbreviation: "se",
  },
  { country: "Switzerland", abbreviation: "ch" },
  {
    country: "Syria",
    abbreviation: "sy",
  },
  { country: "Tajikistan", abbreviation: "tj" },
  {
    country: "Tanzania",
    abbreviation: "tz",
  },
  { country: "Thailand", abbreviation: "th" },
  {
    country: "The Democratic Republic of Congo",
    abbreviation: "cd",
  },
  { country: "Timor-Leste", abbreviation: "tl" },
  { country: "Togo", abbreviation: "tg" },
  {
    country: "Tokelau",
    abbreviation: "tk",
  },
  { country: "Tonga", abbreviation: "to" },
  {
    country: "Trinidad and Tobago",
    abbreviation: "tt",
  },
  { country: "Tunisia", abbreviation: "tn" },
  {
    country: "Turkey",
    abbreviation: "tr",
  },
  { country: "Turkmenistan", abbreviation: "tm" },
  {
    country: "Turks and Caicos Islands",
    abbreviation: "tc",
  },
  { country: "Tuvalu", abbreviation: "tv" },
  { country: "Uganda", abbreviation: "ug" },
  {
    country: "Ukraine",
    abbreviation: "ua",
  },
  { country: "United Arab Emirates", abbreviation: "ae" },
  {
    country: "United Kingdom",
    abbreviation: "uk",
  },
  { country: "United States", abbreviation: "us" },
  {
    country: "United States Minor Outlying Islands",
    abbreviation: "um",
  },
  { country: "Uruguay", abbreviation: "uy" },
  { country: "Uzbekistan", abbreviation: "uz" },
  {
    country: "Vanuatu",
    abbreviation: "vu",
  },
  { country: "Venezuela", abbreviation: "ve" },
  {
    country: "Vietnam",
    abbreviation: "vn",
  },
  { country: "Virgin Islands, British", abbreviation: "vg" },
  {
    country: "Virgin Islands, U.S.",
    abbreviation: "vi",
  },
  { country: "Wallis and Futuna", abbreviation: "wf" },
  {
    country: "Western Sahara",
    abbreviation: "eh",
  },
  { country: "Yemen", abbreviation: "ye" },
  { country: "Zambia", abbreviation: "zm" },
  {
    country: "Zimbabwe",
    abbreviation: "zw",
  },
];
