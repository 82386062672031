import React, { useEffect, useState } from "react";
import { TwoBoxesSelector } from "./two-boxes-selector.container";
import { getRights } from "../../api/rights";
import { Right } from "../../declarations/pages";
import { sortArrByField } from "../../utils/arraySorter";

export const RightsTwoBoxesSelector = ({ selectedRights, onChangeRights }) => {
  const [allSelectedRights, setAllSelectedRights] = useState<Right[]>();
  const [allRights, setAllRights] = useState<Right[]>();
  useEffect(() => {
    const get = async () => {
      const { rights } = await getRights(null, null, 1000000);
      setAllRights(rights);
    };
    get();
  }, []);

  useEffect(() => {
    if (allRights) {
      setAllSelectedRights(
        sortArrByField(allRights, "title").map((right) =>
          selectedRights &&
          selectedRights.findIndex((selected) => right.id === selected.id) > -1
            ? {
                ...right,
                selected: true,
              }
            : right
        )
      );
    }
  }, [selectedRights, allRights]);
  return (
    <TwoBoxesSelector
      data={allSelectedRights}
      entityName="Rights"
      onChange={onChangeRights}
    />
  );
};
