import React from "react";
import Button from "@material-ui/core/Button";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../assets/image/logo.jpg";

import { useStyles } from "./hooks/useStyles";

export const SignIn = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <section className={classes.singIn}>
      <div className={classes.window}>
        <img className={classes.logo} src={logo} alt="error" />

        <Button
          className="button"
          variant="contained"
          onClick={() => loginWithRedirect()}
        >
          Sign in
        </Button>
      </div>
    </section>
  );
};
