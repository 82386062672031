import React, { useEffect, useState } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { EditIcon } from "../../assets/image/edit-icon";
import moment from "moment";
import { SortArray } from "../../components/sorter/sorter";
import {
  EmbedCode,
  EmbedCodeTypes,
  styleCodeDic,
} from "../../declarations/pages";
import { SORT_DEFAULT_ICON_CREATE } from "../../init/constants";

const SourceDic: Record<EmbedCodeTypes, string> = {
  [EmbedCodeTypes.SMART]: "Playlist Smart",
  [EmbedCodeTypes.MANUAL]: "Playlist Manual",
  [EmbedCodeTypes.CHANNEL]: "Channel",
  [EmbedCodeTypes.SEMANTIC]: "Semantic",
  [EmbedCodeTypes.SINGLE]: "Single Video",
};

interface PropsEmbed {
  article?: boolean;
  embedCode?: boolean;
  search?: string;
  resetShort?: string;
  data?: EmbedCode[];
  onDelete: (data) => void;
  updateData?: (data: string) => void;
  getData?: () => void;
  setModal?: (boolean) => void;
  onEditEmbedCode: (id: number) => void;
}

export const EmbedCodesTable = ({
  data,
  article,
  onDelete,
  onEditEmbedCode,
  setModal,
  embedCode,
  updateData,
  resetShort = "",
}: PropsEmbed) => {
  const classes = useStyles();
  const [sort, setSort] = useState(resetShort);
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT_ICON_CREATE);

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);

  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>
                Name
                {!embedCode && (
                  <SortArray
                    sort={sort}
                    type="name"
                    setSort={(e) => {
                      updateData(e && `name:${e}`), setSort(e);
                    }}
                    setSortOwn={setSortOwn}
                    sortOwn={sortOwn}
                  />
                )}
              </TableCell>
              <TableCell className={classes.title}>
                Created date
                <SortArray
                  sort={sort}
                  type="createdDate"
                  setSort={(e) => {
                    updateData(e && `createdDate:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              {!embedCode && (
                <TableCell className={classes.title}>
                  Source
                  <SortArray
                    sort={sort}
                    type="type"
                    setSort={(e) => {
                      updateData(e && `type:${e}`), setSort(e);
                    }}
                    setSortOwn={setSortOwn}
                    sortOwn={sortOwn}
                  />
                </TableCell>
              )}
              <TableCell className={classes.title}>Player Title</TableCell>
              <TableCell className={classes.title}>Player ID</TableCell>
              <TableCell className={classes.title}>Player Style</TableCell>
              <TableCell className={classes.title}></TableCell>
              <TableCell className={classes.title}></TableCell>
              <TableCell className={classes.title}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {data &&
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    width={article ? "10%" : "30%"}
                    className={classes.row}
                  >
                    {row?.name}
                  </TableCell>
                  <TableCell width="15%" className={classes.row}>
                    {moment(row.createdDate).format("DD/MM/yyyy, LT")}
                  </TableCell>
                  {!embedCode && (
                    <TableCell width="10%" className={classes.row}>
                      {SourceDic[row.type]}
                    </TableCell>
                  )}
                  <TableCell width="10%" className={classes.row}>
                    {row?.player?.name}
                  </TableCell>
                  <TableCell width="10%" className={classes.row}>
                    {row?.player?.id}
                  </TableCell>
                  <TableCell width="15%" className={classes.row}>
                    {styleCodeDic[row?.player?.styleData.styling]}
                  </TableCell>
                  {!article && (
                    <TableCell width="5%" className={classes.row}>
                      <IconButton onClick={() => onEditEmbedCode(index)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  )}

                  <TableCell width="5%" className={classes.row}>
                    <IconButton
                      onClick={() => {
                        onDelete(row), setModal(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
