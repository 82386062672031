import React, { useState } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { sortArrBy } from "../../utils/arraySorter";
import VideoMenu from "../video-menu";
import { Video } from "../../declarations/pages";
import { EmbedCodeModal } from "../video-embed-modal";
import { AddPlaylistModal } from "../add-playlist-modal";
import ClickAwayListener from "react-click-away-listener";

export const ChannelsTableVideos = ({ data, role }: any) => {
  const classes = useStyles();
  const [sort, setSort] = useState("");
  const [isCreated, setCreated] = useState(false);
  const [type, setType] = useState("");
  const [videoMenuProps, setVideoMenuProps] = useState<{
    anchorEl: HTMLAnchorElement;
    video: Video;
  }>();
  const [isOpenVideoMenu, setOpenVideoMenu] = useState(false);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [isOpenPlaylistModal, setOpenPlaylistModal] = useState(false);

  const handleClick = (video) => {
    return (event) => {
      setVideoMenuProps({ anchorEl: event.currentTarget, video });
      setOpenVideoMenu(true);
    };
  };
  const onClose = (e) => {
    if (e?.target?.getAttribute("role") == "menuitem") return;
    setOpenVideoMenu(false);
    setIsOpenModalEmbed(false);
    setOpenPlaylistModal(false);
  };
  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>Pos</TableCell>
              <TableCell className={classes.title}></TableCell>
              <TableCell className={classes.title}>Name</TableCell>
              <TableCell className={classes.title}>Status</TableCell>
              <TableCell className={classes.title}>ID</TableCell>
              <TableCell className={classes.title}>Created</TableCell>
              <TableCell className={classes.title}>Update</TableCell>
              <TableCell className={classes.title}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {sortArrBy(data, type, isCreated, sort).map((row, index) => (
              <TableRow key={index}>
                <TableCell width="3%" className={classes.row}>
                  {index + 1}
                </TableCell>
                <TableCell width="8%" className={classes.row}>
                  <img
                    className={classes.img}
                    src={row?.assetUrl}
                    alt="error"
                  />
                </TableCell>
                <TableCell width="20%" className={classes.row}>
                  {row?.name}
                </TableCell>
                <TableCell width="10%" className={classes.row}>
                  {row?.status}
                </TableCell>
                <TableCell width="11%" className={classes.row}>
                  {row?.id}
                </TableCell>
                <TableCell width="18%" className={classes.row}>
                  {moment(row?.createdDate).format("DD/MM/yyyy, LT")}
                </TableCell>
                <TableCell width="18%" className={classes.row}>
                  {moment(row?.updatedDate).format("DD/MM/yyyy, LT")}
                </TableCell>
                <TableCell width="5%" className={classes.row}>
                  <IconButton onClick={handleClick(row)}>
                    <MoreVertIcon className={classes.btn} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isOpenVideoMenu && (
        <ClickAwayListener
          onClickAway={({ relatedTarget }: any) => {
            setOpenVideoMenu(relatedTarget ? true : false);
          }}
        >
          <VideoMenu
            anchorEl={videoMenuProps?.anchorEl}
            open={isOpenVideoMenu}
            setOpenVideoMenu={setOpenVideoMenu}
            role={role}
            setOpenPlaylistModal={() => setOpenPlaylistModal(true)}
            setIsOpenModalEmbed={() => setIsOpenModalEmbed(true)}
          />
        </ClickAwayListener>
      )}
      <EmbedCodeModal open={isOpenModalEmbed} video={data} setOpen={onClose} />
      <AddPlaylistModal
        open={isOpenPlaylistModal}
        setOpen={onClose}
        videoId={data?.id}
      />
    </>
  );
};
