export const book = Object.freeze({
  players: "/players",
  reports: "/reports",
  playlists: "/playlists",
  playlist: "/playlists/:id",
  users: "/users",
  signIn: "/sign-in",
  content: "/",
  publishers: "/publishers",
  publishersDetails: "/publishers/:id",
  allVideos: "/all-videos",
  videosDetails: "/all-videos/:id/edit",
  playerDetails: "/players/:id",
  embedCodes: "/embed-codes",
  semanticEmbedCodes: "/semantic-embed-codes",
  semanticArticlesPlaylist: "/semantic-articles-playlist/:id",
  semanticArticles: "/semantic-articles",
  channels: "/channels",
  createChannel: "/create-channel",
  editChannel: "/channels/:id/edit",
  channelReadOnly: "/channels/:id",
  rights: "/rights",
  rightReadOnly: "/rights/:id",
  rightCreate: "/create-right",
  rightUpdate: "/rights/:id/edit",
  rightsholders: "/rightsholders",
  videoUpload: "/video-upload",
  reporting: "/reporting",
});
