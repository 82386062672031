import React, { ReactElement, useState, useEffect } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import IconButton from "@material-ui/core/IconButton";
import { SortArray } from "../../components/sorter/sorter";
import { PropsRightSholders } from "./rightsholders.interface";
import { DeleteIcon } from "../../assets/image/delete-icon";

const SORT_DEFAULT = "ASCtitle";

export const RightSholdersTable = ({
  data,
  onDelete,
  updateData,
  resetShort = "",
}: PropsRightSholders): ReactElement => {
  const classes = useStyles();
  const [sort, setSort] = useState("");
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT);

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.title}>
              Rightsholders
              <SortArray
                sort={sort}
                type="title"
                setSort={(e) => {
                  updateData(e && `title:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}>ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.line}>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.row} width="50%">
                {row.title}
              </TableCell>
              <TableCell className={classes.row} width="50%">
                {row.id}
              </TableCell>
              <TableCell width="5%" className={classes.row}>
                <IconButton onClick={() => onDelete(row)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
