import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  shroud: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: 400,
    background: "#FFFFFF",
    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.25)",
    padding: "32px 32px 48px",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: "24px",
    borderColor: "rgba(0, 0, 0, 0.23)",
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "49% !important",
    backgroundColor: "#E4E4E4",
  },
  select: {
    marginBottom: 10,
  },
});
