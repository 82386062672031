import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import {
  IconButton,
  Snackbar,
  Input,
  InputAdornment,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import ReplayIcon from "@material-ui/icons/Replay";
import { EmbedCodesTable } from "../../components/embed-codes-table";
import { getChannels } from "../../api/chanels";
import { getPlayers } from "../../api/players";
import { getEmbed, deleteEmbed } from "../../api/embed-codes";
import { getErrorsHandler } from "../../errors/embed";
import { useStyles } from "./hooks/useStyles";
import SearchIcon from "@material-ui/icons/Search";
import { RenderPagination } from "../../components/pagination/index";
import { EmbedCode, EmbedCodesP } from "../../declarations/pages";
import { PAGINATION_PER_PAGE } from "../../init/constants";
import { EmbedCodeModal } from "../../components/video-embed-modal";
import { DeleteModal } from "../../components/delete-modal";
import { RootState } from "../../init/rootReducer";

const SORT_DEFAULT = "createdDate:ASC";
export const EmbedCodes = () => {
  const classes = useStyles();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );

  const [embedData, setEmbedData] = useState<EmbedCodesP>();
  const [isLoaded, setInitialData] = useState(false);
  const [channels, setChannels] = useState([]);
  const [players, setPlayers] = useState([]);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [dataDelete, setdataDelete] = useState({});
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [error, setError] = useState("");
  const [resetShort, setResetShort] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentEmbedCode, setCurrentEmbedCode] = useState<EmbedCode>();
  const { role } = useSelector((state: RootState) => state.user);
  const [currentPage, setCurrentPage] = useState(0);
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.setLink("embedCodes"));
  }, []);

  useEffect(() => {
    if (!displaySearch) {
      getEmbedData();
    }
  }, [role, displaySearch]);

  const getEmbedData = async (
    page = 0,
    perPage = PAGINATION_PER_PAGE,
    orderDefault = SORT_DEFAULT
  ) => {
    const data = await getEmbed({
      perPage,
      page,
      name: displaySearch as unknown as string,
      sort: !order ? order : orderDefault,
    });
    setEmbedData(data);
    setQueryPaginationNumber(page);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const deleteData = async (data) => {
    const response = await deleteEmbed(data);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      getEmbedData(0, 10);
      setResetShort("reset");
    }
  };

  const editEmbedCode = (index: number) => {
    setCurrentEmbedCode(embedData.codes[index]);
    setIsOpenModalEmbed(true);
  };
  const getDataModal = async () => {
    const channelsRes = await getChannels();
    setChannels(channelsRes.channels);
    const playersRes = await getPlayers();
    setPlayers(playersRes.players);
  };

  const reset = async () => {
    setDisplaySearch("");
    setCurrentPage(0);
    setOrder(SORT_DEFAULT);
    setResetShort("reset");
    getEmbedData(0);
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  const hadleSearchChange = (e) => {
    setDisplaySearch(e.target.value);
  };

  const loadData = async (value) => {
    await getEmbedData(value);
  };
  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getEmbedData();
    }
  };
  return (
    <section className={classes.playlist}>
      <div className={classes.heading}>
        <h1 className={classes.title}>Embed Codes</h1>
      </div>
      <div className={classes.searching}>
        <FormControl className={classes.input}>
          <InputLabel htmlFor="search-embed-codes">Search by Title</InputLabel>
          <Input
            id="search-embed-codes"
            type="text"
            value={displaySearch}
            onChange={hadleSearchChange}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <IconButton>
                <InputAdornment position="end" onClick={onSearch}>
                  <SearchIcon />
                </InputAdornment>
              </IconButton>
            }
          />
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => reset()}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      {!isLoaded && (
        <div className="spinner">
          <div className={`lds-hourglass`}></div>
        </div>
      )}
      {isLoaded && embedData && embedData?.codes && !embedData?.codes.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <EmbedCodesTable
            data={embedData?.codes}
            onDelete={(e) => setdataDelete(e)}
            setModal={setOpenDeleteModal}
            onEditEmbedCode={editEmbedCode}
            getData={getDataModal}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT),
                getEmbedData(
                  Number(querypaginationNumber),
                  10,
                  e || SORT_DEFAULT
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={embedData?.total}
            loadData={loadData}
            currentPage={currentPage}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
          {isOpenModalEmbed && (
            <EmbedCodeModal
              open={isOpenModalEmbed}
              setOpen={setIsOpenModalEmbed}
              type={currentEmbedCode.type}
              data={currentEmbedCode}
              players={players}
              channels={channels}
              onSave={() => getEmbedData()}
            />
          )}
        </>
      )}
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={dataDelete}
        onDelete={(data) => deleteData(data)}
        header="Embed Codes"
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
      >
        <MuiAlert severity="success">Embed Codes deleted.</MuiAlert>
      </Snackbar>
    </section>
  );
};
