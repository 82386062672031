const errorsHandler = {
  500: {
    title: "we have a problem, try again",
  },
  404: {
    title: "Page Not found",
  },
};

export const getErrorsHandler = (id = 0) =>
  errorsHandler[id] || "Internal Server Error";
