import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  videodetails: {
    marginLeft: "32px",
    marginTop: "38px",
    paddingBottom: 62,
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  heading: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
  },
  menuPaper: {
    maxHeight: "500px",
  },
  tagsList: {
    width: "100%",
    maxWidth: 327,
    border: "2px solid black",
    borderTopStyle: "none",
    padding: 0,
    "& .MuiListItem-button:hover": {
      background: "black",
      color: "white",
    },
  },
  divider: {
    background: "#80808054",
    height: 1,
    width: 290,
    marginLeft: 20,
    marginTop: -11,
  },
  tagActive: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderBottomStyle: "none",
    },
  },
  disableFlex: {
    "& input ": {
      flex: "auto",
    },
  },
  input: {
    margin: "12px 0",
    width: "100%",
    maxWidth: "331px",
    "& .MuiInputBase-root": {
      borderRadius: 0,
    },
    "& .Mui-focused": {
      color: "#000",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
  },
  tags: {
    display: "flex",
    alignItems: "center",
    width: "100%",

    "& button": {
      marginLeft: "32px",
      width: "160px",
    },
  },
  subTitle: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    marginTop: "48px",
  },
  group: {
    flexDirection: "row",
    flexWrap: "nowrap",

    "& .Mui-checked": {
      color: "#000",
    },

    "& .MuiIconButton-colorSecondary:hover": {
      backgroundColor: "rgba(0,0,0, .04)",
    },
  },
  rights: {
    display: "flex",
    alignItems: "flex-start",
  },
  upload: {
    position: "relative",
  },
  dropzone: {
    height: 325,

    "& svg": {
      color: "#000000",
      opacity: 0.3,
    },

    "& .MuiDropzoneArea-textContainer::after": {
      content: "'Video Upload'",
      opacity: 0.3,
      fontWeight: "bold",
      fontSize: "29px",
      lineHeight: "32px",
      margin: "0 10px",
      position: "relative",
      bottom: 15,
    },
  },
  dropzoneParagraph: {
    marginTop: "32px",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
  },
  uploadBlock: {
    position: "absolute",
    bottom: 40,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    width: "100%",

    "& .button": {
      width: "25%",
      margin: "0 16px",
    },
  },
  span: {
    width: "100%",
    textAlign: "center",
    height: 48,
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "22px",
  },
  blockVideo: {
    "& button": {
      height: 54,
      width: 160,
      margin: 0,
      marginLeft: "20px",
    },
  },
  linear: {
    backgroundColor: "rgba(0,0,0, .1)",
    height: 16,

    "& .MuiLinearProgress-bar": { backgroundColor: "#000" },
  },
  uploading: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: 7,
    display: "flex",
    alignItems: "center",

    "& svg": {
      marginRight: 10,
    },
  },
  block: {
    marginTop: 28,
    display: "flex",
    width: "100%",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    margin: "16px 0",
    marginRight: 44,

    "& strong": {
      fontWeight: "bold",
      marginBottom: "13px",
    },
  },
  inputs: {
    display: "flex",
    width: "100%",

    "& .MuiFormControl-root": {
      width: "352px",
      marginRight: 32,
    },
  },
  label: {
    marginTop: 0,
    "& .MuiChip-deleteIcon": {
      color: "#000",
    },
    "& .MuiAutocomplete-tag, .MuiChip-root": {
      borderRadius: 0,
      color: "#000",
      fontWeight: 400,
      fontSize: 14,
      background: "#C4C4C4",
    },
  },

  rightsInfo: {
    display: "flex",
    marginLeft: 30,
    width: "50%",
    justifyContent: "space-around",
  },
}));
