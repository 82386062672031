import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VideoBlock from "./videoBlock/videoBlock";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  Input,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { getTranslate } from "../../languages";
import { useHistory } from "react-router-dom";
import { getLanguage } from "../../languages";
import { userActions } from "../../bus/user/actions";
import { getVideosLatest, getVideosTrending } from "../../api/videos";
import { useStyles } from "./hooks/useStyles";
import { RootState } from "../../init/rootReducer";
import { LATEST_VIDEO_LIMIT } from "../../init/constants";

export const Content = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { location } = useHistory();
  const [value, setValue] = useState("");
  const [dataLatest, setDataLatest] = useState([]);
  const [countLatest, setCountLatest] = useState(0);
  const [dataTrending, setDataTrending] = useState([]);
  const [countTrending, setCountTrending] = useState(0);
  const [page, setPage] = useState(0);
  const { role } = useSelector((state: RootState) => state.user);

  const [limitLatest, setLimitLatest] = useState(LATEST_VIDEO_LIMIT);
  const [limitTrending, setLimitTrending] = useState(LATEST_VIDEO_LIMIT);
  const searchVideos = () => {
    const language = getLanguage(location.pathname);
    history.push(`${language}/all-videos?query=${value}`);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    dispatch(userActions.setLink("content"));
    return () => {
      setDataLatest([]);
      setDataTrending([]);
    };
  }, [role, page]);

  useEffect(() => {
    if (role) {
      getDataLatest(LATEST_VIDEO_LIMIT);
      getDataTrending(LATEST_VIDEO_LIMIT);
    }
  }, [role]);

  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      searchVideos();
    }
  };

  const getDataTrending = async (limit) => {
    const dataTrendings = await getVideosTrending(0, limit);
    setCountTrending(dataTrendings?.count);
    setDataTrending(dataTrendings.videos);
    setLimitTrending(limit);
  };

  const getDataLatest = async (limit) => {
    const dataLatests = await getVideosLatest(0, limit);
    setCountLatest(dataLatests?.count);
    setDataLatest(dataLatests?.videos);
    setLimitLatest(limit);
  };

  return (
    <section className={classes.content}>
      <FormControl>
        <InputLabel htmlFor="search-content">
          {getTranslate().pages.content.search}
        </InputLabel>
        <Input
          id="search-content"
          type="text"
          value={value}
          onChange={(e) => handleChange(e)}
          onKeyDown={onSearch}
          autoFocus={true}
          endAdornment={
            <IconButton
              onClick={() => {
                searchVideos();
              }}
            >
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            </IconButton>
          }
        />
      </FormControl>
      <VideoBlock
        key="1"
        title="Latest Videos"
        isLinkAllVideo={true}
        type="latest"
        limit={LATEST_VIDEO_LIMIT}
        data={dataLatest}
        count={countLatest}
        changePage={() => {
          getDataLatest(limitLatest + 6);
        }}
      />
      <VideoBlock
        key="2"
        title="Trending Videos"
        type="trending"
        limit={LATEST_VIDEO_LIMIT}
        data={dataTrending}
        count={countTrending}
        changePage={() => {
          getDataTrending(limitTrending + 6);
        }}
      />
    </section>
  );
};
