import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  videodetails: {
    marginLeft: "32px",
    marginTop: "38px",

    "& .MuiGrid-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  heading: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    marginBottom: "29px",
    textDecoration: "none",
  },
  select: {
    width: "256px",
    margin: "0 25px",
  },
  content: {
    padding: "33px 44px",
  },

  searching: {
    display: "flex",
    padding: "25px 0 0 0",
  },
  contentIcons: {
    position: "absolute",
    top: 0,
    right: "30px",
  },
  button: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    display: "flex",
    justifyContent: "space-between",
    width: "100px",
    color: "#000",
    top: 9,
    "&:hover": {
      borderRadius: "0px",
    },
  },
  tableContainer: {
    "& .MuiBox-root": {
      padding: 0,
    },
  },
  paragraphHead: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    color: "black",
    margin: 0,
  },
  h3: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  paragraph: {
    color: "#000",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "22px",
    letterSpacing: 0,
    textAlign: "left",
    margin: 0,
    marginTop: 10,
    marginBottom: 40,
    height: 3,
  },
  tagHeader: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: "left",
    display: "inline",
  },
  tag: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 300,
    letterSpacing: 0,
    textAlign: "left",
    display: "inline",
    marginLeft: 8,
    marginRight: 20,
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  headerTabs: {
    flexGrow: 1,
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiSvgIcon-root": {
      fill: "#E5E5E5",
      width: 25,
      height: 26,
      "&:hover": {
        backgroundColor: "blue",
      },
    },
    "& .Mui-checked .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 17,
      width: 17,
      position: "absolute",
      backgroundColor: "black",
      zIndex: -1,
      borderColor: "transparent",
    },
    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      borderRadius: 3,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 17,
      width: 18,
      position: "absolute",
      backgroundColor: "#E5E5E5",
      zIndex: -1,
      borderColor: "transparent",
    },
  },
  input: {
    width: "100%",
    marginBottom: 13,
    "& .MuiInputLabel-outlined": {
      fontWeight: "bold",
      color: "#000",
    },
  },
  row: {
    padding: 10,
  },
  tabs: {
    background: "black",
  },
  pagination: {
    "& .Mui-selected": {
      background: "black",
      color: "white",
      borderRadius: 0,
    },
  },
}));
