import React, { ReactElement, useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";

import { useStyles } from "./hooks/useStyles";
import { PropsPagination } from "./pagination.interface";
import { PAGINATION_PER_PAGE } from "../../init/constants";

export const RenderPagination = ({
  limit = null,
  currentPage = 0,
  count = 0,
  loadData,
  setCurrentPage,
}: PropsPagination): ReactElement => {
  const classes = useStyles();
  const paginationLimit = limit || PAGINATION_PER_PAGE;
  const [page, setPage] = useState(currentPage);
  const handleChangePage = (_, value) => {
    setPage(value);
    loadData(value - 1);
    setCurrentPage(value);
  };
  useEffect(() => {
    setPage(currentPage === 0 ? 0 : page);
  }, [currentPage]);
  return (
    <>
      {count >= paginationLimit ? (
        <Pagination
          count={Math.ceil(count / paginationLimit)}
          page={page === 0 ? page + 1 : page}
          onChange={handleChangePage}
          shape="rounded"
          className={classes.pagination}
        />
      ) : null}
    </>
  );
};
