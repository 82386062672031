import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import { AppBar, Tab, TextField, Button, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { PopupPosition } from "./popup-position/index";
import { Video } from "./video/index";
import { Audio } from "./audio/index";
import { AdditionalContent } from "./additional-content/index";
import { LogoOverlay } from "./logo-overlay/logo-overlay";
import { ImageUploader } from "./image-uploader/image-uploader";
import Image from "../../assets/image/Rectangle6.png";
import { getLanguage } from "../../languages";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { updatePlayer, getPlayer, deletePlayer } from "../../api/players";
import { getErrorsHandler } from "../../errors/players";
import { DeleteModal } from "../../components/delete-modal";
import { useStyles } from "./hooks/useStyles";
import { RevertChanges } from "../../components/player-details-modal";
import MuiAlert from "@material-ui/lab/Alert";
import { RootState } from "../../init/rootReducer";
import { ROLE_ADMIN } from "../../init/constants";
import { AxiosError } from "axios";
import { ErrorApiResponse, errorDict } from "../../declarations/pages";

const bottomPositions = ["bottomLeft", "bottomRight"];
const topPositions = ["topLeft", "topRight", "topFullWidth"];

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(classes.icon, classes.checkedIcon)}>
          <CheckIcon />
        </span>
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const sanitizeNumber = (
  value: string,
  { min, max }: { min: number; max: number }
) => Math.min(Math.max(Number.parseInt(value), min), max) || 0;

interface Player {
  name: string;
  description: string;
}

export const PlayerDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const [player, setPlayer] = useState<Player>({
    name: "",
    description: "",
  });
  const [configuration, setConfiguration] = useState({
    branding: "Logo",
    style: "standard",
    popupPosition: "bottomRight",
    mobilePaddingTop: 0,
    mobilePaddingBottom: 0,
    closeButtonDelay: 5000,
    isLogo: "on",
    brandingLogo: "",
    logoPosition: "topLeft",
    video: "1",
    isAudio: "1",
    volume: "75",
    styling: "1",
    additional: "1",
    fallback: "AFTV",
    volumeValue: "5%",
  });
  const [selectedTab, setSelectedTab] = useState("1");
  const [image, setImage] = useState("");
  const [urlLng, setUrlLng] = useState("/en");
  const [saveErrorMessage, setSaveErrorMessage] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [logoOverlayError, setLogoOverlayError] = useState(false);
  const [revertModal, setRevertModal] = useState(false);
  const { location } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { role } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const handlePlayer = (event, type: keyof typeof player) => {
    setPlayer({ ...player, [type]: event.target.value });
  };

  const handleConfiguration = (event, key: keyof typeof configuration) => {
    setConfiguration({ ...configuration, [key]: event.target.value });
  };

  const handleFileUpload = (file) => {
    setImage(file);
    setConfiguration({ ...configuration, brandingLogo: file });
  };

  useEffect(() => {
    dispatch(userActions.setLink("players"));
  }, []);

  useEffect(() => {
    getPlayerData();
    setUrlLng(getLanguage(location.pathname));
  }, [location]);

  const revertChanges = () => {
    getPlayerData();
    setRevertModal(false);
  };

  const getPlayerData = async () => {
    const response = await getPlayer(id);

    if (typeof response === "number") {
      console.error(getErrorsHandler(response).title);
    } else {
      setConfiguration(response.styleData);
      setPlayer(response);
    }
  };

  const handleChange = (_event, newValue: string) => {
    setSelectedTab(newValue);
  };

  const onSubmit = async () => {
    const {
      brandingLogo = null,
      isLogo,
      popupPosition,
      logoPosition,
    } = configuration;

    if (isLogo === "on" && !logoPosition) {
      setErrorMessage("Please specify the logo position");
      setLogoOverlayError(true);

      return;
    }

    if (!bottomPositions.includes(popupPosition)) {
      configuration.mobilePaddingBottom = 0;
    }

    if (!topPositions.includes(popupPosition)) {
      configuration.mobilePaddingTop = 0;
    }

    const data = {
      name: player.name,
      description: player.description,
      id,
      styleData: JSON.stringify(configuration),
      file:
        brandingLogo && typeof brandingLogo === "object" ? brandingLogo : null,
    };

    const response = await updatePlayer(data);

    if (response) {
      setSuccessMessage(`Changes for ${player.name} have been saved`);
    } else {
      setSaveErrorMessage(getErrorsHandler());
    }
  };

  const onDelete = async (data) => {
    try {
      await deletePlayer(data);
      setOpenDeleteModal(false);
      setSuccessMessage("Player deleted.");
      history.push(`${urlLng}/players`);
    } catch (e) {
      const response = (e as AxiosError<ErrorApiResponse>).response;
      setErrorMessage(errorDict[response?.data?.customCode || "unknown"]);
    }
  };

  return (
    <section className={classes.playlist}>
      <Link to={`${urlLng}/players`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Players
      </Link>
      <div className={classes.heading}>
        <div className={classes.info}>
          <h1 className={classes.title}>
            {player.name.length > 32
              ? player.name.substring(0, 32) + "..."
              : player.name}
          </h1>
          <span>ID {id}</span>
        </div>
        <div>
          {role == ROLE_ADMIN && (
            <Button
              className={`button-white`}
              onClick={() => setOpenDeleteModal(true)}
            >
              <DeleteIcon />
            </Button>
          )}
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.settings}>
          <TabContext value={selectedTab}>
            <AppBar className={classes.appBar} position="static">
              <TabList
                className={classes.tabList}
                onChange={handleChange}
                aria-label="ant example"
              >
                <Tab label="Player Information" value="1" />
                <Tab label="Styling" value="2" />
                <Tab label="Playback" value="3" />
              </TabList>
            </AppBar>
            <TabPanel value="1" className={classes.tabPanel}>
              <div>
                <TextField
                  className={classes.input}
                  id="name"
                  label="Name (Internal Only)"
                  variant="outlined"
                  value={player.name}
                  onChange={(e) => handlePlayer(e, "name")}
                />
                <TextField
                  id="short-description"
                  label="Short Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  className={classes.description}
                  value={player.description}
                  onChange={(e) => handlePlayer(e, "description")}
                />
              </div>
            </TabPanel>
            <TabPanel value="2" className={classes.tabPanel}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={configuration.styling}
                  className={classes.radioGroup}
                  aria-label="gender"
                  name="customized-radios"
                  onChange={(e) => handleConfiguration(e, "styling")}
                >
                  <FormControlLabel
                    className={`${classes.label} ${
                      configuration.styling === "standard" ? classes.active : ""
                    }`}
                    value="standard"
                    control={<StyledRadio />}
                    label="Standard"
                  />
                  <FormControlLabel
                    className={`${classes.label} ${
                      configuration.styling === "sixteenByNine"
                        ? classes.active
                        : ""
                    }`}
                    value="sixteenByNine"
                    control={<StyledRadio />}
                    label="16:9"
                  />
                  <FormControlLabel
                    className={`${classes.label} ${
                      configuration.styling === "popup" ? classes.active : ""
                    }`}
                    value="popup"
                    control={<StyledRadio />}
                    label="Popup"
                  />
                  <FormControlLabel
                    className={`${classes.label} ${
                      configuration.styling === "fullWidth"
                        ? classes.active
                        : ""
                    }`}
                    value="fullWidth"
                    control={<StyledRadio />}
                    label="Full Width"
                  />
                </RadioGroup>
              </FormControl>

              {configuration.styling !== "popup" && (
                <LogoOverlay
                  configuration={configuration}
                  handleConfiguration={handleConfiguration}
                />
              )}

              {configuration.styling !== "popup" &&
                configuration.isLogo === "on" && (
                  <ImageUploader
                    configuration={configuration}
                    handleConfiguration={handleConfiguration}
                    image={image}
                    setImage={handleFileUpload}
                    logoOverlayError={logoOverlayError}
                    setLogoOverlayError={setLogoOverlayError}
                  />
                )}

              {configuration.styling === "popup" && (
                <>
                  <div>
                    <h3>In-Page Player Style</h3>
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={configuration.style}
                        className={classes.radioGroup}
                        aria-label="gender"
                        name="customized-radios"
                        onChange={(e) => {
                          handleConfiguration(e, "style");
                        }}
                      >
                        <FormControlLabel
                          className={`${classes.label} ${
                            configuration.style === "standard"
                              ? classes.active
                              : ""
                          }`}
                          value="standard"
                          control={<StyledRadio />}
                          label="Standard"
                        />
                        <FormControlLabel
                          className={`${classes.label} ${
                            configuration.style === "sixteenNine"
                              ? classes.active
                              : ""
                          }`}
                          value="sixteenNine"
                          control={<StyledRadio />}
                          label="16:9"
                        />
                        <FormControlLabel
                          className={`${classes.label} ${
                            configuration.style === "fullWidth"
                              ? classes.active
                              : ""
                          }`}
                          value="fullWidth"
                          control={<StyledRadio />}
                          label="Full width"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <PopupPosition
                    value={configuration.popupPosition}
                    setValue={handleConfiguration}
                  />

                  {bottomPositions.includes(configuration.popupPosition) && (
                    <div>
                      <h3>
                        <label htmlFor="mobilePaddingBottom">
                          Mobile Bottom Padding
                        </label>
                      </h3>
                      <TextField
                        type="number"
                        className={classes.input}
                        id="mobilePaddingBottom"
                        disabled={role !== ROLE_ADMIN}
                        value={configuration.mobilePaddingBottom?.toString()}
                        variant="outlined"
                        helperText="Value must be between 0 and 300 pixels."
                        onChange={(e) => {
                          handleConfiguration(
                            {
                              target: {
                                value: sanitizeNumber(e.target.value, {
                                  min: 0,
                                  max: 300,
                                }),
                              },
                            },
                            "mobilePaddingBottom"
                          );
                        }}
                      />
                    </div>
                  )}

                  {topPositions.includes(configuration.popupPosition) && (
                    <div>
                      <h3>
                        <label htmlFor="mobilePaddingTop">
                          Mobile Top Padding
                        </label>
                      </h3>
                      <TextField
                        type="number"
                        className={classes.input}
                        id="mobilePaddingTop"
                        disabled={role !== ROLE_ADMIN}
                        value={configuration.mobilePaddingTop?.toString()}
                        variant="outlined"
                        helperText="Value must be between 0 and 300 pixels."
                        onChange={(e) => {
                          handleConfiguration(
                            {
                              target: {
                                value: sanitizeNumber(e.target.value, {
                                  min: 0,
                                  max: 300,
                                }),
                              },
                            },
                            "mobilePaddingTop"
                          );
                        }}
                      />
                    </div>
                  )}
                  <LogoOverlay
                    configuration={configuration}
                    handleConfiguration={handleConfiguration}
                  />

                  <div>
                    <h3>
                      <label htmlFor="close-button-delay">
                        Close Button Delay (ms)
                      </label>
                    </h3>
                    <TextField
                      type="number"
                      className={classes.input}
                      id="close-button-delay"
                      label=""
                      variant="outlined"
                      value={configuration.closeButtonDelay?.toString()}
                      onChange={(e) => {
                        handleConfiguration(
                          {
                            target: {
                              value: sanitizeNumber(e.target.value, {
                                min: 0,
                                max: 50000,
                              }),
                            },
                          },
                          "closeButtonDelay"
                        );
                      }}
                    />
                  </div>

                  {configuration.isLogo === "on" && (
                    <ImageUploader
                      configuration={configuration}
                      handleConfiguration={handleConfiguration}
                      image={image}
                      setImage={handleFileUpload}
                      logoOverlayError={logoOverlayError}
                      setLogoOverlayError={setLogoOverlayError}
                    />
                  )}
                </>
              )}
            </TabPanel>
            <TabPanel value="3" className={classes.tabPanel}>
              <Video
                value={configuration.video}
                setValue={handleConfiguration}
              />
              <Audio
                value={configuration.isAudio}
                setValue={handleConfiguration}
                volume={configuration.volumeValue}
              />
              <AdditionalContent
                value={configuration.additional}
                setValue={handleConfiguration}
                fallback={configuration.fallback}
              />
            </TabPanel>
          </TabContext>
        </div>
        <div className={classes.videos}>
          {saveErrorMessage && (
            <Alert className={classes.error} severity="error">
              {saveErrorMessage}
            </Alert>
          )}
          <div className={classes.buttons}>
            <Button
              className={`button-white`}
              onClick={() => setRevertModal(true)}
            >
              Revert Changes
            </Button>
            <Button className={`button`} onClick={() => onSubmit()}>
              Save
            </Button>
          </div>

          <div className={classes.image}>
            <img src={Image} alt="" />
            {configuration.isLogo === "on" && configuration.logoPosition && (
              <span
                className={`${classes.logo} ${
                  classes[configuration.logoPosition]
                }`}
              >
                Logo
              </span>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={player}
        onDelete={onDelete}
        header="Player"
      />
      <RevertChanges
        open={revertModal}
        setOpen={setRevertModal}
        onSubmit={revertChanges}
        data={player}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={4000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="success">{successMessage}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={4000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="error">{errorMessage}</MuiAlert>
      </Snackbar>
    </section>
  );
};
