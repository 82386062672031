import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import { DeleteIcon } from "../../assets/image/delete-icon";

import {
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { getLanguage } from "../../languages";
import { PublisherDetailTable } from "../../components/publisher-detail-table";
import { useStyles } from "./hooks/useStyles";
import Search from "@material-ui/icons/Search";
import ReplayIcon from "@material-ui/icons/Replay";
import { RenderPagination } from "../../components/pagination";
import {
  getPublisher,
  getChannels,
  editPublisher,
  deletePublishers,
} from "../../api/publishers";
import { getErrorsHandler } from "../../errors/publishers";
import { DeleteModal } from "../../components/delete-modal";

import { RightsTwoBoxesSelector } from "../../components/two-boxes-selector/rights-two-boxes-selector";
import { Publisher } from "../../declarations/pages";
import { SaveIcon } from "../../assets/image/save-icon";
import LockButton from "../../components/LockButton";
import { COUNTRIES, SORT_DEFAULT } from "../../init/constants";

export const PublishersDetails = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { location } = history;
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");

  const [searchColumn, setSearchColumn] = useState("channels");
  const [search, setSearch] = useState("");

  const { id } = useParams<{ id: string }>();
  const [urlLng, setUrlLng] = useState("/en");
  const [channels, setChannels] = useState([]);
  const [total, setTotal] = useState(0);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState<Publisher>();
  const [isLoaded, setInitialData] = useState(false);
  const [resetShort, setResetShort] = useState("");
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT);
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );

  useEffect(() => {
    getData();
    getChannelsData("", 10, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChange = (prop, event) => {
    setData({ ...data, [prop]: event });
  };
  const getData = async () => {
    const response = await getPublisher(id);
    setData(response);

    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const getChannelsData = async (
    query,
    limit = 10,
    start = 0,
    orderDefault = SORT_DEFAULT
  ) => {
    const { channels: result, total } = await getChannels(
      id,
      query,
      limit,
      start * 10,
      orderDefault
    );
    setChannels(result);
    setTotal(total);
    setQueryPaginationNumber(start);
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const onChangeRights = (rights) => {
    setData({ ...data, rights });
  };
  const submitEditPublisher = async () => {
    const response = await editPublisher({
      ...data,
    });

    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setStatusSuccess("Changes for the Publisher have been saved");
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      setTimeout(() => {
        history.push(`${urlLng}/publishers`);
      }, 1000);
    }
  };
  const deleteConfirm = async (data) => {
    // eslint-disable-next-line react/prop-types
    const response = await deletePublishers(data);
    if (typeof response == "boolean") {
      history.push(`${urlLng}/publishers`);
    } else {
      setOpenDeleteModal(true);
      setError(getErrorsHandler(response).title);
    }
  };
  useEffect(() => {
    const language = getLanguage(location.pathname);
    setUrlLng(language);
  }, [location]);

  const reset = () => {
    getChannelsData("", 10, 0);
    setSearchColumn("channels");
    setSearch("");
    setCurrentPage(0);
    setResetShort("reset");
    if (displaySearch) {
      setDisplaySearch("");
    }
  };
  const Item = (props) => {
    const { ...other } = props;
    return (
      <Box
        component="div"
        sx={{
          pr: 3,
        }}
        {...other}
        noValidate
      />
    );
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      getChannelsData(search, 10, 0);
    }
  };

  const onChangeBlocked = useCallback(
    async (isBlocked: boolean) => {
      setData({ ...data, isBlocked });
      await editPublisher({
        ...data,
        isBlocked,
      });

      setStatusSuccess(
        `Publisher ${data.name} has been ${isBlocked ? "blocked" : "unblocked"}`
      );

      setOpenAlertSuccess(true);
    },
    [data]
  );

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("order", order);
      params.append("page", querypaginationNumber.toString());
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, querypaginationNumber]);

  const RenderTabs = () => {
    return (
      <>
        {isLoaded && channels && !channels.length ? (
          <div className={classes.noResults}>No results</div>
        ) : (
          <>
            <PublisherDetailTable
              data={channels}
              search={""}
              urlLng={urlLng}
              updateData={(e) => {
                setResetShort(""),
                  setOrder(e || SORT_DEFAULT),
                  getChannelsData(
                    displaySearch,
                    Number(querypaginationNumber),
                    10,
                    e || SORT_DEFAULT
                  );
              }}
              resetShort={resetShort}
            />
            <RenderPagination
              limit={null}
              count={total}
              loadData={(value) => getChannelsData(search, 10, value)}
              currentPage={currentPage}
              setCurrentPage={(value) => setCurrentPage(value)}
            />
          </>
        )}
      </>
    );
  };
  return (
    <section className={classes.videodetails}>
      <Link to={`${urlLng}/publishers`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Content
      </Link>

      <Box sx={{ gridTemplateColumns: "repeat(2, 1fr)" }}>
        <Item>
          <div className={classes.heading}>
            <h1 className={classes.title}>{data?.name}</h1>
            <Item>
              <Button className={`button-white`} onClick={submitEditPublisher}>
                <SaveIcon />
              </Button>
              <LockButton
                onClick={() => onChangeBlocked(!data?.isBlocked)}
                locked={data?.isBlocked}
                className={`button-white`}
              />

              <Button
                className={`button-white`}
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon />
              </Button>
            </Item>
          </div>
        </Item>
      </Box>

      <RightsTwoBoxesSelector
        selectedRights={data?.rights}
        onChangeRights={onChangeRights}
      />

      <div style={{ width: "100%", marginTop: 45 }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 3fr)" }}>
          <Box component="div" sx={{ pr: 3 }}>
            <TextField
              className={classes.input}
              id="Longitude ID"
              label="Longitude ID"
              variant="outlined"
              fullWidth
              value={data?.longitudeId || ""}
              onChange={(e) => {
                handleChange("longitudeId", e.target.value), e.preventDefault();
              }}
            />
            <TextField
              variant="outlined"
              id="outlined-email-flexible"
              label="Email"
              maxRows={1}
              value={data?.email || ""}
              fullWidth
              className={classes.input}
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </Box>
          <Item>
            <FormControl variant="outlined" className={classes.input} fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Country
              </InputLabel>
              <Select
                labelId="country"
                id="country"
                label="Country"
                fullWidth
                value={data?.country?.id || "Country"}
                onChange={(e) =>
                  handleChange("country", { id: e.target.value })
                }
              >
                {COUNTRIES.map((el) => (
                  <MenuItem key={el.abbreviation} value={el.abbreviation}>
                    {el.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Box>

        <h3 className={classes.h3}>Module Access</h3>

        <div className={classes.root}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) =>
                    handleChange("moduleVideoUpload", e.target.checked)
                  }
                  name="video"
                  color="default"
                  checked={Boolean(data?.moduleVideoUpload)}
                />
              }
              label="Video Upload"
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleChange("moduleSemantic", e.target.checked)
                }
                name="Semantic"
                color="default"
                checked={Boolean(data?.moduleSemantic)}
              />
            }
            label="Semantic"
          />
        </div>

        <h3 className={classes.h3}>Accessed channels</h3>
      </div>

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={data}
        header="Publisher"
        onDelete={deleteConfirm}
      />
      <div className={classes.searching}>
        <FormControl>
          <InputLabel htmlFor="search-publisher">
            Search {searchColumn}
          </InputLabel>
          <Input
            id="search-publisher"
            type="text"
            value={search}
            onChange={handleSearch}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => getChannelsData(search, 10, 0)}>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => reset()}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>
      <div className={classes.tableContainer}>
        <RenderTabs />
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
      >
        <MuiAlert severity="success">{statusSuccess}</MuiAlert>
      </Snackbar>
    </section>
  );
};
