import { getAxiosInstance } from "./api";
import { EmbedCodesP } from "../declarations/pages";

export const createEmbedCode = async (data) => {
  const axios = getAxiosInstance();
  try {
    const response = await axios({
      method: "POST",
      url: "/embed",
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    console.error("[createEmbedCode] error", error.response);
    return error.response.status;
  }
};

export const saveEmbedCode = async (id, data) => {
  const axios = getAxiosInstance();
  try {
    const response = await axios({
      method: "PUT",
      url: `/embed/${id}`,
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    console.error("[createEmbedCode] error", error.response);
    return error.response.status;
  }
};

interface EmbedParameters {
  type?: string;
  perPage: number;
  page: number;
  name?: string;
  sort?: string;
}

export const getEmbed = async ({
  type,
  perPage,
  page,
  name,
  sort = "",
}: EmbedParameters): Promise<EmbedCodesP> => {
  const paginationQuery = `start=${page * perPage}&limit=${perPage}`;
  const axios = getAxiosInstance();

  const url = `embed?${paginationQuery}`;
  const search = [];
  if (type) {
    search.push(`type:${type}`);
  }
  if (name) {
    search.push(`name:${name}`);
  }
  try {
    const response = await axios({
      method: "GET",
      url: `${url}&q=${search.join("_AND_")}&sort=${sort}`,
    });
    return response.data;
  } catch (error) {
    console.error("[getEmbed] error", error);
    return error.response.status;
  }
};

export const deleteEmbed = async (data) => {
  const axios = getAxiosInstance();
  try {
    await axios({
      method: "DELETE",
      url: `/embed/${data.id}`,
    });
    return true;
  } catch (error) {
    console.error("[removeEmbed] error", error);
    return null;
  }
};
