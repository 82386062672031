import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  canvas: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  },
  closeIcon: {
    position: "absolute",
    right: 32,
    top: 32,
    color: "#000",
  },
  block: {
    position: "relative",
    width: "609px",
    background: "#FFFFFF",
    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.25)",
    padding: 32,
    paddingBottom: 48,
    boxSizing: "border-box",

    "& h1": {
      fontWeight: "bold",
      fontSize: "32px",
      lineHeight: "36px",
      marginBottom: 32,
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: "30px",
    },

    "& video-js": {
      width: "100%",
      height: "308px",
    },
  },
  clarification: {
    display: "flex",
    flexDirection: "column",
    fontSize: "16px",
    color: "#000000",
    marginBottom: 32,

    "& span": {
      fontWeight: "bold",
      lineHeight: "16px",
      marginBottom: 16,
    },
    "& p": {
      fontWeight: "300",
      lineHeight: "20px",
      margin: 0,
    },
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "none",
    borderBottom: "1px solid #C4C4C4",
  },
  tabList: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#000",
    },
  },
  tabPanel: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiSvgIcon-root": {
      fill: "#E5E5E5",
      width: 25,
      height: 26,
      "&:hover": {
        backgroundColor: "blue",
      },
    },
    "& .Mui-checked .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 17,
      width: 17,
      position: "absolute",
      backgroundColor: "black",
      zIndex: -1,
      borderColor: "transparent",
    },
    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      borderRadius: 3,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 17,
      width: 18,
      position: "absolute",
      backgroundColor: "#E5E5E5",
      zIndex: -1,
      borderColor: "transparent",
    },
  },
  textArea: {
    marginTop: 24,
    maxHeight: 225,
    minWidth: "100%",
    maxWidth: "100%",
    border: "1px solid #C4C4C4",
    borderRadius: 0,
    padding: 20,
    boxSizing: "border-box",
    color: "#000",
    fontWeight: 300,
    fontSize: 16,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 22,
    width: "100%",

    "& button": {
      width: 160,
      margin: 0,
    },
  },
  menuPaper: {
    maxHeight: "300px",
  },
  input: {
    margin: "12px 0",
    width: "100%",

    "& [data-shrink=true]": {
      color: "#000000",
      fontWeight: "bold",
    },

    "& .MuiInputBase-root": {
      borderRadius: 0,
    },

    "& .Mui-focused": {
      color: "#000",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
  },
  error: {
    color: "red",
    fontSize: "14px",
    marginBottom: "24px",
    paddoing: "10px 0",
  },

  "@media screen and (max-height: 930px)": {
    canvas: {
      alignItems: "flex-start",
    },
  },
  disabled: {
    backgroundColor: "#E4E4E4 !important",
    color: "#fff !important",
  },
});
