import React, { useState, useEffect } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useStyles } from "./hooks/useStyles";
import { EditIcon } from "../../assets/image/edit-icon";
import IconButton from "@material-ui/core/IconButton";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { SortArray } from "../../components/sorter/sorter";
import { RootState } from "../../init/rootReducer";
import { ROLE_ADMIN, SORT_DEFAULT_ICON_CREATE } from "../../init/constants";
import { styleCodeDic } from "../../declarations/pages";

export const PlayersTable = ({
  data,
  resetShort = "",
  handleDeletePlayer,
  urlLng,
  updateData,
}: any) => {
  const [sort, setSort] = useState("");
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT_ICON_CREATE);
  const classes = useStyles();
  const { isAdmin, role } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.title}>
              Title
              <SortArray
                sort={sort}
                type="name"
                setSort={(e) => {
                  updateData(e && `name:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}>ID</TableCell>

            <TableCell className={classes.title}>Embed Count</TableCell>
            <TableCell className={classes.title}>
              Created date
              <SortArray
                sort={sort}
                type="createdDate"
                setSort={(e) => {
                  updateData(e && `createdDate:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}>
              Style
              <SortArray
                sort={sort}
                type="styling"
                setSort={(e) => {
                  updateData(e && `styling:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}></TableCell>
            <TableCell className={classes.title}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.line}>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.row}>{row.name}</TableCell>
              <TableCell className={classes.row} width="15%">
                {row.id}
              </TableCell>
              <TableCell className={classes.row} width="20%">
                {row.embedCodes ? row.embedCodes.length : 0}
              </TableCell>
              <TableCell className={classes.row} width="20%">
                {moment(row.createdDate).format("DD/MM/yyyy, LT")}
              </TableCell>

              <TableCell className={classes.row} width="10%">
                {styleCodeDic[row.styleData.styling]}
              </TableCell>
              <TableCell className={classes.row} width="5%">
                <Link to={`${urlLng}/players/${row.id}`}>
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Link>
              </TableCell>
              <TableCell className={classes.row} width="5%">
                {isAdmin && role === ROLE_ADMIN && (
                  <IconButton onClick={() => handleDeletePlayer(row)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
