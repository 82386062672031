// Video Component
// ===============
//
// This components communicates progress on a single video upload to the user. It's the single most complex
// visual component.

var defaultPreviewAction = require("./default-preview").defaultPreviewAction;

var uiStates = {
  pending: "pending",
  started: "started",
  progress: "progress",
  transcoding: "transcoding",
  preview: "preview",
  error: "error",
};

function UIVideo(params) {
  // Protect against forgetting the new keyword when instantiating objects
  if (!(this instanceof UIVideo)) {
    return new UIVideo(params);
  }

  this.state = uiStates.pending;
  this.percent = 0;
  this.fileName = params.fileName;

  this.transcodingText = params.transcodingText;
  this.transcodingDelayMS = params.transcodingDelayMS;

  this.previewText = params.previewText;
  this.onPreview = params.onPreview;
  this.onDelete = params.onDelete;
  this.onSave = params.onSave;
  this.previewContext = {
    fileName: params.fileName,
    fileSize: params.fileSize,
    playerId: params.playerId, // Maybe null!
    updatePreview: params.updatePreview,
    defaultPreviewAction: defaultPreviewAction,
    videoId: params.videoId,
    accountId: params.accountId,
    rootElement: params.rootElement,
  };
  this.tags = params.tags;

  this.node = document.createElement("div");
}

UIVideo.prototype.states = uiStates;

UIVideo.prototype.render = function render() {
  this.node.innerHTML = "";
  this.node.className = "bcuploader-video is-" + this.state;

  var label = document.createElement("span");
  label.className = "bcuploader-video_label";

  //------------------------------

  var heading = document.createElement("div");
  heading.className = "bcuploader-heading";
  this.node.appendChild(heading);

  var title = document.createElement("h1");
  title.innerHTML = this.fileName;
  title.className = "bcuploader-title";
  heading.appendChild(title);

  var div = document.createElement("div");
  div.className = "bcuploader-btns";

  heading.appendChild(div);

  var cancel = document.createElement("button");
  cancel.className = "bcuploader-button-white";
  cancel.innerHTML = "Cancel upload";

  div.appendChild(cancel);

  cancel.addEventListener("click", async () => {
    this.onDelete(this.previewContext);
  });

  // var edit = document.createElement("button");
  // edit.innerHTML = "Edit";
  // edit.className = "bcuploader-button";
  // div.appendChild(edit);

  var uploading = document.createElement("span");
  uploading.className = "bcuploader-uploading";
  uploading.innerHTML = `Processing Complete`;
  this.node.appendChild(uploading);

  var progressBar = document.createElement("span");
  progressBar.style.width = this.percent + "%";
  progressBar.className = "bcuploader-video_progress-bar";
  this.node.appendChild(progressBar);
  label.innerHTML = this.percent + "%";

  var block = document.createElement("div");
  block.className = "bcuploader-block";
  this.node.appendChild(block);

  var information = document.createElement("span");
  information.className = "bcuploader-information";
  information.innerHTML = `<strong>Source File Name</strong> ${this.fileName}`;

  var informationTwo = document.createElement("span");
  informationTwo.className = "bcuploader-information";
  informationTwo.innerHTML = `<strong>ID</strong> ${this.previewContext.videoId}`;

  block.appendChild(information);
  block.appendChild(informationTwo);

  //------------------------------

  switch (this.state) {
    // switch (this.states.progress) {
    case this.states.progress:
      uploading.innerHTML = `Uploading... ${this.percent}%`;

      break;

    case this.states.transcoding:
      uploading.innerHTML = `Transcoding...`;
      this.node.removeChild(progressBar);

      break;
    case this.states.preview:
      uploading.innerHTML = `Processing Complete`;
      this.node.removeChild(progressBar);

      var name = document.createElement("input");
      name.type = "text";
      name.setAttribute("placeholder", "Name");
      name.classList.add("bcuploader-input");
      name.setAttribute("maxlength", "255");
      this.node.appendChild(name);

      var description = document.createElement("input");
      description.type = "text";
      description.setAttribute("placeholder", "Short Description");
      description.setAttribute("maxlength", "250");
      description.classList.add("bcuploader-input");
      this.node.appendChild(description);

      var tags = document.createElement("input");
      tags.type = "text";
      tags.value = this.tags || "";
      tags.setAttribute("placeholder", "Tags");
      tags.classList.add("bcuploader-input");
      this.node.appendChild(tags);

      var save = document.createElement("button");
      save.className = "bcuploader-button";
      save.innerHTML = "Save";

      save.addEventListener("click", async () => {
        var params = {};
        var nameValue = name.value.trim();
        var descriptionValue = description.value.trim();
        var tagsValue = tags.value.trim();

        if (nameValue) {
          params["name"] = nameValue;
        }

        if (descriptionValue) {
          params["description"] = descriptionValue;
        }

        if (tagsValue) {
          params["tags"] = tagsValue;
        }
        this.onSave(this.previewContext, params);
      });

      var cancel = document.createElement("button");
      cancel.className = "bcuploader-button-white";
      cancel.innerHTML = "Cancel";

      cancel.addEventListener("click", async () => {
        this.onDelete(this.previewContext);
      });

      var block = document.createElement("div");
      block.className = "bcuploader-block-bottons";
      this.node.appendChild(block);

      block.appendChild(cancel);
      block.appendChild(save);
      break;
    case this.states.error:
      label.innerHTML = "Error";
      break;
  }
  // if (label.innerHTML) this.node.appendChild(label);

  return this.node;
};

UIVideo.prototype.setState = function setState(state, percent) {
  // Validate input
  if (typeof uiStates[state] === undefined) {
    throw new Error(
      'Invalid UIVideo state "' +
        state +
        '". ' +
        "Valid states are: " +
        Object.keys(uiStates).join(",")
    );
  }

  this.state = uiStates[state];

  // Perform extra state-specific logic
  switch (this.state) {
    case uiStates.progress:
      this.percent = percent;
      break;
    case uiStates.transcoding:
      setTimeout(
        function () {
          this.setState(uiStates.preview);
        }.bind(this),
        this.transcodingDelayMS
      );
      break;
  }

  this.render();
};

UIVideo.prototype.onclick = function onclick(event) {
  var context = Object.assign({}, this.previewContext, { event: event });
  this.onPreview(context);
};

export { UIVideo };
