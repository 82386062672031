import {
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "./hooks/useStyles";

const TwoBoxesSelectorComponent = ({ items, inputLabel, onToggle }) => {
  const classes = useStyles();
  const onItemClick = (item) => {
    return () => onToggle(item);
  };
  return (
    <>
      <InputLabel
        className={classes.labelPaper}
        id="demo-simple-select-outlined-label"
      >
        {inputLabel}
      </InputLabel>
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items &&
            items.map((item) => (
              <ListItem
                key={item.id}
                role="listitem"
                button
                onClick={onItemClick(item)}
              >
                <ListItemText
                  className={item.checked ? classes.active : ""}
                  id={`transfer-list-item-${item.id}-label`}
                  primary={item.name ? item.name : item.title}
                />
              </ListItem>
            ))}
          <ListItem />
        </List>
      </Paper>
    </>
  );
};

export default TwoBoxesSelectorComponent;
