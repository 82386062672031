import React, { useEffect, useState } from "react";
import { TwoBoxesSelector } from "./two-boxes-selector.container";
import { Publisher } from "../../declarations/pages";
import { getPublishers } from "../../api/publishers";

export const PublishersTwoBoxesSelector = ({
  selectedPublishers,
  onChangePublishers,
}) => {
  const [allSelectedPublishers, setAllSelectedPublishers] =
    useState<Publisher[]>();
  const [allPublishers, setAllPublishers] = useState<Publisher[]>();
  useEffect(() => {
    const get = async () => {
      const { publishers } = await getPublishers(null, null, 1000);
      setAllPublishers(publishers);
    };
    get();
  }, []);

  useEffect(() => {
    if (allPublishers) {
      setAllSelectedPublishers(
        allPublishers.map((publisher) =>
          selectedPublishers &&
          selectedPublishers.findIndex(
            (selected) => publisher.id === selected.id
          ) > -1
            ? {
                ...publisher,
                selected: true,
              }
            : publisher
        )
      );
    }
  }, [selectedPublishers, allPublishers]);

  return (
    <TwoBoxesSelector
      data={allSelectedPublishers}
      entityName="Publishers"
      onChange={onChangePublishers}
    />
  );
};
