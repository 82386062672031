import translationEN from "./assets/localization/en.json";
import translationDE from "./assets/localization/de.json";

export type LANGUAGE =
  | "en"
  | "de"
  | "it"
  | "nl"
  | "es"
  | "fr"
  | "ar"
  | "id"
  | "hu"
  | "br"
  | "tr"
  | "vi"
  | "th"
  | "kr"
  | "ja"
  | "zh";
export const DEFAULT_LANGUAGE: LANGUAGE = "en";

const resources: Record<LANGUAGE, any> = {
  en: translationEN,
  de: translationDE,
  it: null,
  nl: null,
  es: null,
  ar: null,
  id: null,
  hu: null,
  br: null,
  tr: null,
  vi: null,
  th: null,
  kr: null,
  fr: null,
  ja: null,
  zh: null,
};

export const getLanguage = (string) => {
  let language = DEFAULT_LANGUAGE;
  const regex = /\/en|\/br|\/it|\/de|\/nl|\/mx|\/ar|\/es/gm;
  const data = string.match(regex);

  language = data ? data[0] : null;
  return language;
};

export const getTranslate = (language?: LANGUAGE) => {
  return resources[language || DEFAULT_LANGUAGE];
};

export const codeLangToStrDic: Record<LANGUAGE, string> = {
  ar: "Arabic",
  br: "Brazilian Portuguese",
  zh: "Chinese (Simplified)",
  nl: "Dutch",
  en: "English",
  fr: "French",
  de: "German",
  hu: "Hungarian",
  id: "Indonesian (Bahasa)",
  it: "Italian",
  ja: "Japanese",
  kr: "Korean",
  es: "Spanish",
  th: "Thai",
  tr: "Turkish",
  vi: "Vietnamese",
};

export const languageRightList = Object.entries(codeLangToStrDic)
  .filter(
    ([id]) =>
      [
        "en",
        "fr",
        "de",
        "es",
        "ar",
        "it",
        "nl",
        "id",
        "hu",
        "br",
        "tr",
        "vi",
        "th",
        "kr",
        "ja",
        "zh",
        "",
        "",
      ].indexOf(id) !== -1
  )
  .map(([id, name]) => ({ name, id }));
