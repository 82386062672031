import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button, Snackbar } from "@material-ui/core";
import { PublishIcon } from "../../assets/image/publish-icon";
import { EditIcon } from "../../assets/image/edit-icon";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { Link, useHistory, useParams } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { getLanguage } from "../../languages";
import { getErrorsHandler } from "../../errors/playlists";
import { useStyles } from "./hooks/useStyles";
import {
  getPlaylist,
  editPlaylist,
  addVideoPlaylists,
  deleteVideoPlaylists,
} from "../../api/all-playlists";
import { getVideos } from "../../api/videos";
import { PlaylistVideos } from "../../components/playlist-videos";
import { PlaylistSmartVideos } from "../../components/playlist-smart-videos";
import { PlaylistsModal } from "../../components/playlists-modal";
import { AddVideoModal } from "../../components/add-video-modal";
import { EmbedCodeModal } from "../../components/video-embed-modal";
import { EmbedCodeTypes } from "../../declarations/pages";
import { PlaylistTypes } from "../../declarations/pages";
import { ROLE_PUBLISHER } from "../../init/constants";
import { RootState } from "../../init/rootReducer";
import { getChannels } from "../../api/chanels";
import { getPlayers } from "../../api/players";
import { DeleteModal } from "../../components/delete-modal";
import { deletePlaylist } from "../../api/all-playlists";
import { ADDITIONAL_TEXT, LATEST_VIDEO_LIMIT } from "../../init/constants";
import VideoBlock from "../../pages/content/videoBlock/videoBlock";
import { getVideosLatest } from "../../api/videos";

const SORT_DEFAULT = "createdDate:DESC";
export const Playlist = () => {
  const classes = useStyles();
  const { location } = useHistory();
  const history = useHistory();
  const [data, setData] = useState({
    id: "",
    name: "",
    description: "",
    type: "",
    tags: "",
    videos: [],
  });
  const [dataVideos, setDataVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isLoaded, setInitialData] = useState(false);
  const [urlLng, setUrlLng] = useState("/en");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState("");
  const [openAddVideo, setOpenAddVideo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [length, setLength] = useState(0);
  const [statusSuccess, setStatusSuccess] = useState("");
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { role } = useSelector((state: RootState) => state.user);
  const [playlist, setPlaylist] = useState(null);
  const [channels, setChannels] = useState([]);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [dataLatest, setDataLatest] = useState([]);
  const [countLatest, setCountLatest] = useState(0);
  const [pageLatest, setPageLatest] = useState(0);

  useEffect(() => {
    dispatch(userActions.setLink("playlists"));
  }, []);

  useEffect(() => {
    const language = getLanguage(location.pathname);

    setUrlLng(language);
  }, [location]);

  useEffect(() => {
    dispatch(userActions.setLink("playlists"));
    getData();
  }, []);

  useEffect(() => {
    getDataLatest();
  }, [pageLatest]);

  const handleDeleteClick = () => {
    setError("");
    setOpenDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    // eslint-disable-next-line react/prop-types
    const response = await deletePlaylist(data);

    if (typeof response !== "number") {
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      history.push(`${urlLng}/playlists`);
    } else {
      setOpenDeleteModal(true);
      setError(getErrorsHandler(response).title);
    }
  };

  const getData = async () => {
    const data = await getPlaylist(id);
    setData(data);

    setDataVideos(data.videos.sort((a, b) => a.position - b.position));
    if (!isLoaded) {
      setInitialData(true);
    }
  };

  const getDataVideos = async (
    page = 0,
    query = "",
    name = "name",
    perPage = 5
  ) => {
    const { videos, total } = await getVideos(
      page * 5,
      query,
      name,
      perPage,
      SORT_DEFAULT
    );

    setVideos(videos);
    setLength(total);
  };

  const getDataLatest = async () => {
    const dataLatests = await getVideosLatest(pageLatest, LATEST_VIDEO_LIMIT);
    setCountLatest(dataLatests?.count);
    setDataLatest((prev) => [...prev, ...dataLatests.videos]);
  };

  const openModalEmded = async () => {
    setIsOpenModalEmbed(true);
    setPlaylist(data);
    const channelsRes = await getChannels();
    setChannels(channelsRes.channels);
    const playersRes = await getPlayers();
    setPlayers(playersRes.players);
  };

  const editSubmit = async (newData) => {
    const data = mapData(newData);
    const response = await editPlaylist(data);

    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenAlertSuccess(true);
      setOpenModal(false);
      setStatusSuccess("Updated.");
      setData(mapData(newData));
    }
  };

  const mapData = (data) => {
    if (data.type === PlaylistTypes.MANUAL) {
      const videos = (dataVideos || []).map((video, index) => {
        return { videoId: video.id, playlistId: data.id, position: index };
      });
      return {
        ...data,
        ...{ videos },
      };
    } else if (data.type === PlaylistTypes.SMART) {
      const newData = {
        channel: data.channel,
        description: data.description,
        id: data.id,
        name: data.name,
        publisherId: data.publisherId,
        tags: data.tags.join(","),
        tagsType: data.tagsType,
        type: data.type,
        videos: data.videos,
      };

      return {
        ...newData,
      };
    }
  };

  const handleAddVideos = () => {
    setOpenAddVideo(true);
    getDataVideos();
  };

  const handleAddPlaylistModal = async (arrayVideosId) => {
    setLoading(true);
    Promise.all(
      arrayVideosId.map((videoId) => addVideoPlaylists(videoId, id))
    ).then((response) => {
      const checker = (arr) => arr.every(Boolean);
      setLoading(false);
      if (checker(response)) {
        setOpenAlertSuccess(true);
        setOpenAddVideo(false);
        setStatusSuccess("Added.");
        getData();
        localStorage.removeItem("video");
      } else {
        setOpenAlert(true);
      }
    });
  };

  const handleDeletePlaylistVideo = async (videoId) => {
    const response = await deleteVideoPlaylists(videoId, id);

    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenAlertSuccess(true);
      setOpenAddVideo(false);
      setStatusSuccess("Deleted.");
      getData();
    }
  };

  const updatePosition = async () => {
    const videos = (dataVideos || []).map((video, index) => {
      return { videoId: video.id, playlistId: data.id, position: index };
    });

    const response = await editPlaylist({
      ...data,
      ...{ videos },
    });

    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      setOpenAlertSuccess(true);
      setOpenModal(false);
      setStatusSuccess("Position updated.");
    }
  };

  return (
    <section className={classes.playlistManual}>
      <Link to={`${urlLng}/playlists`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to Playlists
      </Link>
      <div className={classes.header}>
        <div className={classes.title}>
          <div className={classes.head}>
            <h1>{data?.name}</h1>
            <span>
              <strong>ID</strong> {data.id}
            </span>
            <span>
              <strong>Type</strong> {data.type}
            </span>
            {data.type === "SMART" && (
              <span>
                <strong>Tags</strong> {data.tags}
              </span>
            )}
          </div>
          <p className={classes.text}>{data.description}</p>
        </div>

        <div>
          {role === ROLE_PUBLISHER && (
            <Button className="button-white" onClick={() => openModalEmded()}>
              <PublishIcon />
            </Button>
          )}
          <Button className="button-white" onClick={() => setOpenModal(true)}>
            <EditIcon />
          </Button>
          <Button className="button-white" onClick={() => handleDeleteClick()}>
            <DeleteIcon />
          </Button>
        </div>
      </div>

      {data.type === PlaylistTypes.MANUAL && (
        <div className={classes.textButton}>
          <Button
            className={`button ${classes.button}`}
            onClick={() => handleAddVideos()}
          >
            Add videos
          </Button>
          Playlist may contain up to 32 Videos
        </div>
      )}

      {isLoaded && data && !data.videos.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          {data.type === PlaylistTypes.MANUAL && (
            <PlaylistVideos
              data={dataVideos}
              setData={setDataVideos}
              updatePosition={updatePosition}
              deletePlaylistVideo={handleDeletePlaylistVideo}
              urlLng={urlLng}
            />
          )}
          {data.type === PlaylistTypes.SMART && (
            <PlaylistSmartVideos data={dataVideos} urlLng={urlLng} />
          )}
        </>
      )}
      {data.type === PlaylistTypes.MANUAL && (
        <VideoBlock
          key="1"
          title="Latest Videos"
          isLinkAllVideo={false}
          type="latest"
          limit={6}
          data={dataLatest}
          count={countLatest}
          changePage={() => {
            setPageLatest(pageLatest + 1);
          }}
          handleAddVideos={handleAddVideos}
        />
      )}
      <PlaylistsModal
        open={openModal}
        setOpen={setOpenModal}
        playlistType="manual"
        edit={true}
        createPlaylist={editSubmit}
        data={data}
        onEdit={editSubmit}
      />
      <AddVideoModal
        open={openAddVideo}
        setOpen={setOpenAddVideo}
        videos={videos}
        count={length}
        getVideos={getDataVideos}
        AddPlaylistModal={handleAddPlaylistModal}
        loading={loading}
        setLoading={setLoading}
      />
      <EmbedCodeModal
        open={isOpenModalEmbed}
        playlist={playlist}
        type={data.type as EmbedCodeTypes}
        setOpen={setIsOpenModalEmbed}
        players={players}
        channels={channels}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={data}
        header="Playlist"
        onDelete={() => handleDeleteConfirm()}
        additionalText={ADDITIONAL_TEXT}
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
      >
        <MuiAlert severity="success">{statusSuccess} Playlist.</MuiAlert>
      </Snackbar>
    </section>
  );
};
