import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  shroud: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: 609,
    background: "#FFFFFF",
    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.25)",
    padding: "32px 32px 48px",
    height: "80%",
    overflow: "auto",
    maxHeight: "800px",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0",
  },
  button: {
    width: 160,
    marginRight: 32,
    marginTop: 32,
    color: "#000 !important",
    backgroundColor: "#E4E4E4 !important",
  },
  buttonAdd: {
    width: 160,
    marginRight: 32,
    marginTop: 32,
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "0",
    marginTop: -12,
    marginLeft: 12,
  },
});
