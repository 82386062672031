// @ts-nocheck
import React, { useEffect } from "react";
import BCUploader from "../../../evaporate-brightcove/bcuploader";
import { TextField } from "@material-ui/core";
import { getEnvVars } from "../../../utils/configHelper";

const Upload = ({ data }) => {
  const { baseUrl } = getEnvVars();
  useEffect(() => {
    BCUploader({
      root: "video-uploader",
      createVideoEndpoint: `${baseUrl}/video-upload/upload`,
      signUploadEndpoint: `${baseUrl}/video-upload/sign`,
      ingestUploadEndpoint: `${baseUrl}/video-upload/ingest`,
      publishedTime:
        data && data.publishedTime ? data.publishedTime : undefined,
      expireTime: data && data.expireTime ? data.expireTime : undefined,
      tags: data && data.tags ? data.tags : [],
      right: data && data.rightId ? data.rightId : null,
    });
  }, [data]);

  return (
    <>
      <div id="video-uploader"></div>
    </>
  );
};

export default Upload;
