import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
    marginTop: "30px",
    marginBottom: "25px",
    boxShadow: "none",
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "16px",
    border: "none",
  },
  row: {
    fontFamily: "Arial",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "15px",
    color: "#000000",
    border: "none",
  },
  line: {
    "& tr:nth-child(odd)": {
      backgroundColor: "#F5F5F5",
    },
  },
  img: {
    width: 70,
    height: 40,
  },
  btn: {
    color: "#000000",
  },
});
