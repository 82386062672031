import React, { useState, useEffect } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { EditIcon } from "../../assets/image/edit-icon";
import { SortArray } from "../../components/sorter/sorter";
import { Link } from "react-router-dom";
const SORT_DEFAULT = "ASCtitle";
export const RigthsTable = ({
  data,
  urlLng,
  onDelete,
  updateData,
  resetShort = "",
}: any) => {
  const classes = useStyles();
  const [sort, setSort] = useState("");
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT);

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);
  return (
    <>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.title}>
                Title
                <SortArray
                  sort={sort}
                  type="title"
                  setSort={(e) => {
                    updateData(e && `title:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title}>
                Rightsholder
                <SortArray
                  sort={sort}
                  type="rightsHolder"
                  setSort={(e) => {
                    updateData(e && `rightsHolder:${e}`), setSort(e);
                  }}
                  setSortOwn={setSortOwn}
                  sortOwn={sortOwn}
                />
              </TableCell>
              <TableCell className={classes.title}></TableCell>
              <TableCell className={classes.title}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell width="45%" className={classes.row}>
                  <Link
                    className={classes.link}
                    to={`${urlLng}/rights/${row.id}`}
                  >
                    {row.title}
                  </Link>
                </TableCell>
                <TableCell width="45%" className={classes.row}>
                  {row.rightsHolder?.title}
                </TableCell>
                <TableCell width="5%" className={classes.row}>
                  <Link to={`${urlLng}/rights/${row.id}/edit`}>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Link>
                </TableCell>
                <TableCell width="5%" className={classes.row}>
                  <IconButton onClick={() => onDelete(row)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
