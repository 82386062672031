export const types = Object.freeze({
  SET_NAME: "SET_NAME",
  SET_LOGIN: "SET_LOGIN",
  SET_USERS: "SET_USERS",
  SET_LINK: "SET_LINK",
  SET_ROLE: "SET_ROLE",
  SET_ROLE_ADMIN: "SET_ROLE_ADMIN",
  SET_PUBLISHER: "SET_PUBLISHER",
  SET_PUBLISHERS: "SET_PUBLISHERS",
  SET_USERNAME: "SET_USERNAME",
  SET_PUBLISHER_LONGITUDE: "SET_PUBLISHER_LONGITUDE",
});
