import { getAxiosInstance } from "./api";

export const getRights = async (
  value,
  type,
  limit = 10,
  start = 0,
  sort = ""
) => {
  const axios = getAxiosInstance();
  const query = value && type ? `&q=${type}:${value}` : "";
  const params = {
    method: "GET",
    url: `/rights?limit=${limit}&start=${start}${query}&sort=${sort}`,
  };

  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getrights] error", error);
    return error.response.status;
  }
};

export const createRights = async (data) => {
  const axios = getAxiosInstance();
  const params = {
    method: "POST",
    url: `/rights`,
    data: JSON.stringify(data),
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getrights] error", error);
    return error.response.status;
  }
};

export const updateRights = async (data) => {
  const axios = getAxiosInstance();
  const params = {
    method: "PUT",
    url: `/rights/${data.id}`,
    data: JSON.stringify(data),
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[getrights] error", error);
    return error.response.status;
  }
};

export const deleteRights = async (id) => {
  const axios = getAxiosInstance();
  const params = {
    method: "DELETE",
    url: `/rights/${id}`,
  };
  try {
    await axios(params);
    return true;
  } catch (error) {
    console.error("[removerights] error", error);
    return error.response.status;
  }
};

export const getRight = async (id) => {
  const axios = getAxiosInstance();
  const params = {
    method: "GET",
    url: `/rights/${id}`,
  };
  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    console.error("[removerights] error", error);
    return error.response.status;
  }
};
