// @ts-ignore
// @ts-nocheck
import React, { ReactElement, useEffect, useState } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../init/rootReducer";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import Card from "./card";
import update from "immutability-helper";
import { EmbedCodeModal } from "../video-embed-modal";
import { DeleteModal } from "../../components/delete-modal";
import { getAllPlaylists } from "../../api/all-playlists";
import { AddPlaylistModal } from "../../components/add-playlist-modal";

export const PlaylistVideos = ({
  data,
  setData,
  updatePosition,
  deletePlaylistVideo,
  urlLng,
}: any): ReactElement => {
  const classes = useStyles();
  const { isAdmin } = useSelector((state: RootState) => state.user);
  const [isOpenModalEmbed, setIsOpenModalEmbed] = useState(false);
  const [video, setVideo] = useState(null);
  const [id, setId] = useState("");
  const [dataPlaylist, setDataPlaylist] = useState({});
  const [openPlaylistModal, setOpenPlaylistModal] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = data[dragIndex];
    setData(
      update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    );
  };

  const onDrop = (index) => {
    updatePosition();
  };

  useEffect(() => {
    data;
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getPlayList = async (query, name = "name", perPage = 10, page = 0) => {
    const { playlists: result } = await getAllPlaylists(
      query,
      name,
      perPage,
      page
    );
    setPlaylists(result);
  };
  const openEmbedCodeModal = async () => {
    handleClose();
    setIsOpenModalEmbed(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pos</TableCell>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell />
              <TableCell />
              {isAdmin && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody className={classes.line}>
            {data.map((card, i) => (
              <Card
                key={card.id}
                index={i}
                id={card.id}
                cardInfo={card}
                moveCard={moveCard}
                isAdmin={isAdmin}
                onDrop={onDrop}
                handleClick={handleClick}
                deletePlaylistVideo={(e) => (
                  setDataPlaylist(e), setOpenDeleteModal(true)
                )}
                setId={setId}
                setOpenPlaylistModal={setOpenPlaylistModal}
                getPlayList={getPlayList}
                openEmbedCodeModal={openEmbedCodeModal}
                setVideo={setVideo}
                handleClose={handleClose}
                anchorEl={anchorEl}
                urlLng={urlLng}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddPlaylistModal
        open={openPlaylistModal}
        setOpen={setOpenPlaylistModal}
        playlists={playlists}
        videoId={id}
        setSearchPlaylist={getPlayList}
      />
      <EmbedCodeModal
        open={isOpenModalEmbed}
        video={video}
        setOpen={setIsOpenModalEmbed}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={dataPlaylist}
        header="Playlist"
        removeVideo
        onDelete={() => (
          deletePlaylistVideo(dataPlaylist.id), setOpenDeleteModal(false)
        )}
      />
    </DndProvider>
  );
};
