import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  shroud: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    width: 400,
    background: "#FFFFFF",
    boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.25)",
    padding: "32px 32px 48px",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0",
  },
  text: {
    fontSize: "14px",
    lineHeight: "22px",
    marginBottom: "24px",
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "49% !important",
    height: "60px !important",
    margin: "0 !important",
  },
  error: {
    color: "red",
    fontSize: "14px",
    marginBottom: "24px",
    paddoing: "10px 0",
  },
});
