import React, { ReactElement, useState, useEffect } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import { Link } from "react-router-dom";
import { SortArray } from "../../components/sorter/sorter";
import { PropsTabs } from "./publisher.interface";

export const PublisherDetailTable = ({
  data,
  resetShort = "",
  urlLng,
  updateData,
}: PropsTabs): ReactElement => {
  const classes = useStyles();
  const [sort, setSort] = useState(resetShort);
  const [sortOwn, setSortOwn] = useState("");
  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.title}>
              Name
              <SortArray
                sort={sort}
                type="name"
                setSort={(e) => {
                  updateData(e && `name:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}>ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.line}>
          {data.map((row, index) => (
            <TableRow key={index}>
              <Link
                to={`${urlLng}/channels/${row.id}`}
                className={classes.link}
              >
                <TableCell className={classes.row} width="50%">
                  {row.name}
                </TableCell>
              </Link>
              <TableCell className={classes.row} width="50%">
                {row.id}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
