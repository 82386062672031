import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../bus/user/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { getLanguage } from "../../languages";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { RotateIcon } from "../../assets/image/rotate-icon";
import { SaveIcon } from "../../assets/image/save-icon";
import { Channel } from "../../declarations/pages";
import { getErrorsHandler } from "../../errors/channels";
import { updateChannel, getChannel, deleteChannel } from "../../api/chanels";
import { DeleteModal } from "../../components/delete-modal";
import { useStyles } from "./hooks/useStyles";
import { RightsTwoBoxesSelector } from "../../components/two-boxes-selector/rights-two-boxes-selector";
import { PublishersTwoBoxesSelector } from "../../components/two-boxes-selector/publishers-two-boxes-selector";
import { MISSING_FIELD } from "../../init/constants";

export const EditChannel = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [urlLng, setUrlLng] = useState("/en");
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const { location } = useHistory();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);

  const [channelData, setChannelData] = useState<Channel>();

  const languages = [
    { value: "ar", title: "Arabic" },
    { value: "nl", title: "Dutch" },
    { value: "en", title: "English" },
    { value: "fr", title: "French" },
    { value: "de", title: "German" },
    { value: "it", title: "Italian" },
    { value: "pt", title: "Portugese" },
    { value: "es", title: "Spanish" },
    { value: "es-mx", title: "Spanish (LATAM)" },
  ];

  useEffect(() => {
    getChannelData();
    dispatch(userActions.setLink("channels"));
  }, []);

  const handleChange = (prop, event) => {
    setChannelData({ ...channelData, [prop]: event.target.value });
  };

  useEffect(() => {
    const language = getLanguage(location.pathname);

    setUrlLng(language);
  }, [location]);

  const onSubmit = async () => {
    if (!channelData?.name) {
      setDisable(true);
    } else {
      const response = await updateChannel(channelData);
      if (typeof response === "number") {
        setOpenAlert(true);
        setError(getErrorsHandler(response).title);
      } else {
        setOpenAlertSuccess(true);
        setTimeout(() => {
          history.push(`${urlLng}/channels`);
        }, 900);
      }
    }
  };

  const deleteData = async (data) => {
    const response = await deleteChannel(data);
    if (typeof response === "number") {
      setOpenAlert(true);
      setError(getErrorsHandler(response).title);
    } else {
      history.push(`${urlLng}/channels`);
    }
  };

  const getChannelData = async () => {
    const data: Channel = await getChannel(id);
    setChannelData(data);
    setLoading(true);
  };

  const onChangeRights = (rights) => {
    setChannelData({ ...channelData, rights });
  };

  const onChangePublishers = (publishers) => {
    setChannelData({ ...channelData, publishers });
  };

  return (
    <section className={classes.playlist}>
      <Link to={`${urlLng}/channels`} className={classes.breadcrumbs}>
        <ArrowBackIcon /> Back to channels
      </Link>
      <div className={classes.heading}>
        <div className={classes.info}>
          <h1 className={classes.title}>{channelData?.name}</h1>
          <span>ID {id}</span>
        </div>
        <div>
          <Button className={`button-white`} onClick={getChannelData}>
            <RotateIcon />
          </Button>
          <Button
            className={`button-white`}
            onClick={() => setOpenDeleteModal(true)}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.inputs}>
          <TextField
            className={classes.input}
            id="title"
            label="Title *"
            variant="outlined"
            onChange={(e) => handleChange("name", e)}
            value={channelData?.name || ""}
            inputProps={{ maxLength: 40 }}
            error={disable && !channelData?.name}
            helperText={disable && !channelData?.name ? MISSING_FIELD : null}
          />
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Language
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Language"
              onChange={(e) => handleChange("language", e)}
              defaultValue={"en"}
              value={channelData?.language?.id || channelData?.language || "en"}
            >
              {languages.map((language) => (
                <MenuItem key={language.value} value={language.value}>
                  {language.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <TextField
          id="short-description"
          label="Short Description"
          multiline
          rows={4}
          variant="outlined"
          className={classes.input}
          onChange={(e) => handleChange("description", e)}
          value={channelData?.description || ""}
          inputProps={{ maxLength: 250 }}
        />
        <RightsTwoBoxesSelector
          selectedRights={channelData?.rights}
          onChangeRights={onChangeRights}
        />
        <PublishersTwoBoxesSelector
          selectedPublishers={channelData?.publishers}
          onChangePublishers={onChangePublishers}
        />
        <div className={classes.root}>
          <Button
            className={`button-white`}
            onClick={() => history.push(`${urlLng}/channels`)}
          >
            Cancel
          </Button>
          <Button className={`button`} onClick={() => onSubmit()}>
            Save
          </Button>
        </div>
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
      <Snackbar open={openAlertSuccess} autoHideDuration={4000}>
        <MuiAlert severity="success">
          Changes for channel have been saved
        </MuiAlert>
      </Snackbar>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        error={error}
        data={{ ...channelData, id }}
        onDelete={(data) => deleteData(data)}
        header="Channel"
      />
    </section>
  );
};
