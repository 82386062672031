import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  link: {
    width: "60px",
    height: "60px",
    background: "#f5f5f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  playlist: {
    marginLeft: "32px",
    marginTop: "38px",
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  block: {
    display: "flex",
    width: "100%",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "16px",
    margin: "16px 0",
    width: "33%",

    "& strong": {
      fontWeight: "bold",
      marginBottom: "13px",
    },
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    textDecoration: "none",
  },
  heading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "32px",
    paddingRight: 22,
    boxSizing: "border-box",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  subTitle: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    marginTop: "48px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    marginTop: "29px",
    "& span": {
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "22px",
      marginTop: 24,
    },
  },
}));
