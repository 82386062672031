import React from "react";
export const DocumentIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 28.5V21C25 20.4696 25.2107 19.9609 25.5858 19.5858C25.9609 19.2107 26.4696 19 27 19H35L41 25V37C41 37.5304 40.7893 38.0391 40.4142 38.4142C40.0391 38.7893 39.5304 39 39 39H35"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34 19V25H40"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37 30H31.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37 34H35"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31 26H30H29"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="25.5" cy="36.5" r="1.5" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 36.5C19 36.5 21.3636 32 25.5 32C29.6364 32 32 36.5 32 36.5C32 36.5 29.6364 41 25.5 41C21.3636 41 19 36.5 19 36.5Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
