import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { getAxiosToken } from "../../api/api";
import { Header } from "../../components/header";
import { Sidebar } from "../../components/sidebar";
import { useStyles } from "./hooks/useStyles";

export const Base = ({ children }: any) => {
  const classes = useStyles();
  const [visibleSideBar, setVisibleSideBar] = useState(false);
  const [isToken, setIsToken] = useState(false);

  useEffect(() => {
    getAxiosToken() ? setIsToken(true) : setIsToken(false);
  }, [getAxiosToken()]);

  return (
    <Container maxWidth="xl">
      <div className={classes.root}>
        <Header
          onSubmit={setVisibleSideBar}
          visibleSideBar={visibleSideBar}
          setIsToken={setIsToken}
        />
        <Sidebar visibleSideBar={visibleSideBar} />

        <main className={classes.content}>
          {isToken ? (
            children
          ) : (
            <div className={classes.position}>
              <div className={`lds-hourglass`}></div>
            </div>
          )}
        </main>
      </div>
    </Container>
  );
};
