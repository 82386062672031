import { Button, TextareaAutosize } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import TabPanel from "@material-ui/lab/TabPanel";
import React, { useState } from "react";
import { useStyles } from "../hooks/useStyles";

type PropsType = {
  value: string;
  embedScript: string;
  error: string;
  disable: boolean;
  isDataDefault?: boolean;
  setOpen: (open: boolean) => void;
  setDisable: (open: boolean) => void;
  updateDefault: (value: boolean) => void;
  generate: () => void;
  generateTitle?: string;
};

export const EmbedTabPanel = ({
  value,
  embedScript,
  error,
  setOpen,
  generate,
  disable,
  setDisable,
  generateTitle = "Generate",
  isDataDefault,
  updateDefault,
}: PropsType) => {
  const classes = useStyles();
  const [isDefaul, setDefault] = useState(isDataDefault);
  return (
    <TabPanel value={value} className={classes.tabPanel}>
      <TextareaAutosize
        className={`textArea ${classes.textArea}`}
        minRows="7"
        maxRows="7"
        disabled
        value={embedScript}
      />
      {error && <Alert severity="error">{error}</Alert>}
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              setDefault(e.target.checked);
              updateDefault(e.target.checked);
            }}
            name="Default"
            color="default"
            checked={Boolean(isDefaul)}
          />
        }
        label="Default"
      />
      <div className={classes.buttons}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          className={`button-white`}
        >
          Close
        </Button>
        <Button
          className={`button-white ${!disable && classes.disabled}`}
          onClick={disable && generate}
        >
          {generateTitle}
        </Button>
        <Button
          className={`button ${!disable && classes.disabled}`}
          onClick={() => {
            disable && navigator.clipboard.writeText(embedScript);
          }}
        >
          Copy
        </Button>
      </div>
    </TabPanel>
  );
};
