import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#000",
  },
  flexContainer: {
    display: "block",
    textAlign: "center",
    justifyContent: "space-between",
  },
  logo: {
    width: 147,
    textAlign: "center",
    marginTop: 21,
  },
  button: {
    color: "#fff",
  },

  "@media screen and (min-width: 768px)": {
    appBar: {
      width: "285px",
      left: 0,
    },
    button: {
      display: "none",
    },
  },
}));
