// Types
import { types } from "./types";

export const semanticArticleActions = Object.freeze({
  deleteVideo: (videoId) => {
    return {
      type: types.DELETE_VIDEO,
      payload: videoId,
    };
  },
  loadData: (data) => {
    return {
      type: types.LOAD_DATA,
      payload: data,
    };
  },
  lockArticle: (data) => {
    return {
      type: types.LOCK,
      payload: data,
    };
  },
  hideArticle: (data) => {
    return {
      type: types.HIDE,
      payload: data,
    };
  },
});
