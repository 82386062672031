import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Search from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  IconButton,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStyles } from "./hooks/useStyles";
import { VideoBlock } from "../video-block";

export const AddVideoModal = ({
  open,
  setOpen,
  videos,
  getVideos,
  count,
  AddPlaylistModal,
  loading,
  setLoading,
}: any) => {
  const classes = useStyles();
  const [id, setId] = useState(null);

  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const [displaySearch, setDisplaySearch] = useState(query || "");
  const [isLoaded, setInitialData] = useState(true);
  const [listVideos, setListVideos] = useState([]);
  const [clearVideos, setClearVideos] = useState(false);

  const close = () => {
    setId(null);
    setOpen(false);
    setDisplaySearch("");
  };
  const onSearch = (e) => {
    if ((e.keyCode === 13 || e.key === undefined) && displaySearch.length > 0) {
      getVideos(0, displaySearch);
    }
    if (
      (e.keyCode === 13 || e.key === undefined) &&
      displaySearch.length == 0
    ) {
      setClearVideos(true);
      getVideos();
    }
  };
  useEffect(() => {
    setListVideos([]);
  }, [loading]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded]);

  return (
    <Modal
      open={open}
      onClose={() => close()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          Add Videos
          <IconButton aria-label="close" onClick={() => close()}>
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <FormControl>
          <InputLabel htmlFor="search-videos">Search videos</InputLabel>
          <Input
            id="search-videos"
            type="text"
            value={displaySearch}
            onChange={(e) => setDisplaySearch(e.target.value)}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onSearch}>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <VideoBlock
          title=""
          count={count}
          videos={videos}
          getVideos={(value) => getVideos(value, displaySearch)}
          playListId={setId}
          getList={setListVideos}
          clearVideos={clearVideos}
          setClearVideos={setClearVideos}
        />
        <Button
          className={`${classes.button} button`}
          variant="contained"
          onClick={() => close()}
        >
          Cancel
        </Button>
        <Button
          className={`${classes.buttonAdd} button`}
          variant="contained"
          onClick={() =>
            listVideos.length !== 0 &&
            (AddPlaylistModal(listVideos), setLoading(true))
          }
        >
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          Add {listVideos.length !== 0 ? `(${listVideos.length})` : null}
        </Button>
      </div>
    </Modal>
  );
};
