import { types } from "./types";

const initialState = {
  name: "",
  isLogin: false,
  users: [],
  activeLink: "",
  role: "",
  isAdmin: false,
  publisher: "",
  userName: "",
  publishers: [],
  longitudeId: null,
};

const adminRole = "admin";

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_NAME:
      return { ...state, name: payload };

    case types.SET_USERNAME:
      return { ...state, userName: payload };

    case types.SET_LOGIN:
      return { ...state, isLogin: payload };

    case types.SET_USERS:
      return { ...state, users: payload };

    case types.SET_LINK:
      return { ...state, activeLink: payload };

    case types.SET_ROLE:
      return {
        ...state,
        role: JSON.parse(localStorage.getItem("role")) || payload,
        isAdmin: payload === adminRole,
      };

    case types.SET_ROLE_ADMIN:
      return { ...state, role: payload };

    case types.SET_PUBLISHER:
      return { ...state, publisher: payload };

    case types.SET_PUBLISHER_LONGITUDE:
      return { ...state, longitudeId: payload };

    case types.SET_PUBLISHERS:
      return { ...state, publishers: payload };

    default:
      return state;
  }
};
