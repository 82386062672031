import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./hooks/useStyles";
import { COUNTRIES } from "../../init/constants";

export const PublishersModal = ({ open, setOpen, type, newPublisher }: any) => {
  const classes = useStyles();

  const [disable, setDisable] = useState(false);
  const [values, setValues] = useState({
    name: false,
    email: false,
    longitudeId: "",
    country: "",
  });

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event });
  };

  const savePublisher = () => {
    if (
      !values.name ||
      !values.email ||
      !values.longitudeId ||
      !values.country
    ) {
      setDisable(true);
    } else {
      setDisable(false);
      newPublisher(values);
      reset();
    }
  };

  const reset = () => {
    setValues({
      name: false,
      email: false,
      longitudeId: "",
      country: "",
    });
  };

  const closeModal = () => {
    setOpen(false);
    setDisable(false);
    reset();
  };
  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          {type} publisher
          <IconButton aria-label="close" onClick={() => closeModal()}>
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <form noValidate autoComplete="off" className={classes.form}>
          <TextField
            required
            className={classes.input}
            id="Publisher name"
            label="Publisher name"
            variant="outlined"
            error={disable && !values.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <TextField
            required
            className={classes.input}
            id="Administrator’s email"
            label="Administrator’s email"
            variant="outlined"
            error={disable && !values.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <TextField
            required
            className={classes.input}
            id="Longitude ID"
            label="Longitude ID"
            variant="outlined"
            error={disable && !values.longitudeId}
            onChange={(e) => handleChange("longitudeId", e.target.value)}
          />
          <FormControl
            error={disable && !values.country}
            required
            variant="outlined"
            className={classes.select}
          >
            <InputLabel id="Country">Country</InputLabel>
            <Select
              labelId="Country"
              id="Country"
              label="Country"
              onChange={(e) => handleChange("country", e.target.value)}
            >
              {COUNTRIES.map((el) => (
                <MenuItem key={el.abbreviation} value={el.abbreviation}>
                  {el.country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={classes.buttons}>
            <Button className={classes.button} onClick={() => closeModal()}>
              Discard
            </Button>
            <Button
              className={`${classes.button} button`}
              onClick={() => savePublisher()}
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
