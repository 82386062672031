import countriesList from "../assets/countries/countries.json";

export const getCountryShortName = (name: string) => {
  const entry = Object.entries(countriesList).find((el) => {
    const [_, full] = el;
    if (full === name) {
      return true;
    }
  });
  if (entry) {
    return entry[0];
  }
};
