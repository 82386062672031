import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  playlist: {
    marginLeft: "32px",
    marginTop: "38px",
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    marginBottom: "29px",
    textDecoration: "none",
  },
  heading: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "32px",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    marginTop: "32px",
  },
  subTitle2: {
    fontSize: "17px",
    lineHeight: "24px",
    marginTop: "2px",
  },
  searching: {
    display: "flex",
    flexDirection: "column",
  },
  select: {
    width: "256px",
    margin: "0 25px",
  },
  button: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "15px",
    display: "flex",
    justifyContent: "space-between",
    width: "100px",

    "&:hover": {
      borderRadius: "0px",
    },
  },
  input: {
    margin: "12px 0",
    width: "352px",
    marginRight: "32px",

    "& .MuiInputBase-root": {
      borderRadius: 0,
    },

    "& .Mui-focused": {
      color: "#000",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
  },
  noResults: {
    padding: "25px",
    textAlign: "center",
  },
  snackbar: {
    "& .MuiPaper-root": {
      borderRadius: "30px",
      backgroundColor: "#fff",
      color: "red",
      padding: "5px 30px",

      "& .MuiSnackbarContent-message": {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  },
  "@media screen and (min-width: 768px)": {
    playlist: {
      marginLeft: "20px",
    },
  },
  dates: {
    width: "100%",
    display: "flex",
  },
}));
