import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  IconButton,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { getPublishers } from "../../api/publishers";
import { getErrorsHandler } from "../../errors/user";
import { useStyles } from "./hooks/useStyles";
import Alert from "@material-ui/lab/Alert";
import { DEFAULT_LANGUAGE } from "../../languages";
import { MISSING_FIELD } from "../../init/constants";

interface State {
  first_name: string;
  last_name: string;
  email: string;
  publisher: string;
  language: string;
  user_id: string;
}

export const UserModal = ({
  open,
  setOpen,
  type,
  onSubmit,
  data,
  error,
  setError,
  variableNameError,
  setVariableNameError,
}: any) => {
  const classes = useStyles();
  const [publishers, setPublishers] = useState([]);
  const [values, setValues] = useState<State>({
    first_name: "",
    last_name: "",
    email: "",
    publisher: "",
    language: DEFAULT_LANGUAGE,
    user_id: null,
  });
  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [isHandlerErrors, setIsHandlerErrors] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getPublishersData();
    if (type === "Edit") {
      setValues({
        first_name: data.givenName,
        last_name: data.familyName,
        email: data.email,
        publisher: data.publisherId,
        language: data.language,
        user_id: data.user_id,
      });
    } else {
      setValues({
        first_name: "",
        last_name: "",
        email: "",
        publisher: "",
        language: DEFAULT_LANGUAGE,
        user_id: null,
      });
    }
  }, [data, type, open]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const getPublishersData = async () => {
    const data = await getPublishers("", "", 1000000, 0);
    setPublishers(data.publishers);
  };

  const createUser = () => {
    setLoading(true);
    setIsHandlerErrors(isEmpty);
    if (isValidateEmail && !isEmpty()) {
      onSubmit(values, (res) => {
        setLoading(false);
        if (res) {
          setOpen(false);
          setShowError(false);
          setVariableNameError([]);
          setValues({
            first_name: "",
            last_name: "",
            email: "",
            publisher: "",
            language: "",
            user_id: null,
          });
        } else {
          setShowError(true);
        }
      });
    } else {
      let numberError = 0;
      isEmpty()
        ? (numberError = 2)
        : !isValidateEmail
        ? (numberError = 1)
        : (numberError = 0);
      setError(getErrorsHandler(numberError).title);
      setVariableNameError([...getErrorsHandler(numberError).name]);
      setLoading(false);
    }
  };
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const isEmpty = () => {
    return !!Object.keys(
      Object.fromEntries(Object.entries(values).filter(([_, v]) => v === ""))
    ).length;
  };

  const handleChangeEmail = (email) => {
    setValues({ ...values, email });

    setIsValidateEmail(validateEmail(email));
  };
  const closeModal = () => {
    setIsHandlerErrors(false);
    setOpen(false);
    setShowError(false);
    setValues({
      first_name: "",
      last_name: "",
      email: "",
      publisher: "",
      language: "",
      user_id: null,
    });
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.shroud}
    >
      <div className={classes.paper}>
        <h2 id="simple-modal-title" className={classes.heading}>
          {type} User
          <IconButton aria-label="close" onClick={closeModal}>
            <HighlightOffIcon />
          </IconButton>
        </h2>
        <form autoComplete="off" className={classes.form}>
          <TextField
            error={isHandlerErrors ? !values.first_name : false}
            className={classes.input}
            id="First-name"
            label="First Name*"
            variant="outlined"
            onChange={handleChange("first_name")}
            value={values.first_name}
            helperText={
              isHandlerErrors && !values.first_name ? MISSING_FIELD : false
            }
          />
          <TextField
            error={isHandlerErrors ? !values.last_name : false}
            className={classes.input}
            id="Last-name"
            label="Last Name*"
            variant="outlined"
            onChange={handleChange("last_name")}
            value={values.last_name}
            helperText={
              isHandlerErrors && !values.last_name ? MISSING_FIELD : null
            }
          />
          <TextField
            error={
              isHandlerErrors
                ? !values.email
                : variableNameError.find((e) => e === "email")
            }
            className={classes.input}
            id="Email"
            label="Email*"
            variant="outlined"
            value={values.email}
            onChange={(e) => handleChangeEmail(e.target.value)}
            helperText={isHandlerErrors && !values.email ? MISSING_FIELD : null}
          />
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Publisher*
            </InputLabel>
            <Select
              error={isHandlerErrors ? !values.publisher : false}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Publisher*"
              onChange={handleChange("publisher")}
              value={values.publisher}
            >
              {publishers.map((item) => (
                <MenuItem key={item.name} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {isHandlerErrors && !values.publisher ? (
              <FormHelperText error>{MISSING_FIELD}</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel id="demo-simple-select-outlined-label">
              Language*
            </InputLabel>
            <Select
              error={isHandlerErrors ? !values.language : false}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Language*"
              onChange={handleChange("language")}
              value={values.language}
              MenuProps={{
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "center",
                  horizontal: "center",
                },
              }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="pt">Portugese</MenuItem>
              <MenuItem value="it">Italian</MenuItem>
              <MenuItem value="de">German</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
              <MenuItem value="mx-ar">Spanish (LATAM)</MenuItem>
            </Select>
            {isHandlerErrors && !values.language ? (
              <FormHelperText error>{MISSING_FIELD}</FormHelperText>
            ) : null}
          </FormControl>
          {showError && <Alert severity="error">{error}</Alert>}
          <div className={classes.buttons}>
            <Button className={classes.buttonDelete} onClick={closeModal}>
              Discard
            </Button>
            <Button
              className={`${classes.button} button`}
              onClick={() => !loading && createUser()}
              variant="contained"
            >
              Submit
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};
