import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  playlist: {
    marginLeft: "32px",
    marginTop: "38px",
  },
  breadcrumbs: {
    display: "flex",
    width: "140px",
    alignItems: "center",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    color: "#000000",
    textDecoration: "none",
  },
  heading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "22px",
    paddingRight: 22,
    boxSizing: "border-box",
  },
  title: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "31px",
  },
  content: {
    width: "60%",
  },
  inputs: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",

    "& .MuiFormControl-root": {
      width: "48%",
    },
  },
  input: {
    margin: "12px 0",
    width: "100%",

    "& .MuiInputBase-root": {
      borderRadius: 0,
    },

    "& .Mui-focused": {
      color: "#000",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
    },
  },
  subTitle: {
    color: "#000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "24px",
    margin: "36px 0",
  },
  root: {
    margin: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& button": {
      border: "none",
      width: "48%",
      margin: "24px 0",
    },
  },
  buttons: {
    width: "48%",
    display: "flex",
    justifyContent: "space-between",
  },
  labelPaper: {
    position: "absolute",
    top: "-7px",
    left: 12,
    backgroundColor: "#fff",
    padding: "0 4px",
    color: "#000",
    fontSize: "13px",
    lineHeight: "13px",
  },
  paper: {
    width: "100%",
    height: 200,
    overflow: "auto",
    boxShadow: "none",
    border: "solid 1px #000",
    boxSizing: "border-box",
    borderRadius: 0,

    "& .MuiListItemIcon-root": {
      display: "none",
    },

    "& .MuiListItem-root": {
      height: 48,
    },
  },
  grid: {
    padding: "0 !important",
    width: "48%",
    position: "relative",
  },
  active: {
    "& .MuiTypography-root": {
      fontWeight: "bold",
    },
  },
  info: {
    display: "flex",
    flexDirection: "column",
    marginTop: "29px",
    "& span": {
      fontWeight: 300,
      fontSize: 14,
      lineHeight: "22px",
      marginTop: 24,
    },
  },
}));
