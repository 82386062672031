import React, { useState, useEffect } from "react";
import {
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useStyles } from "./hooks/useStyles";
import { EditIcon } from "../../assets/image/edit-icon";
import IconButton from "@material-ui/core/IconButton";
import { DeleteIcon } from "../../assets/image/delete-icon";
import { SortArray } from "../../components/sorter/sorter";
import { Link } from "react-router-dom";
import LockButton from "../LockButton";
import { SORT_DEFAULT_ICON_NAME } from "../../init/constants";

export const PublishersTable = ({
  data,
  urlLng,
  modalDelete,
  onDelete,
  updateData,
  onUpdate,
  resetShort = "",
}: any) => {
  const classes = useStyles();
  const [sort, setSort] = useState(resetShort);
  const [sortOwn, setSortOwn] = useState(SORT_DEFAULT_ICON_NAME);

  useEffect(() => {
    if (resetShort === "reset") {
      setSort("");
      setSortOwn("");
    }
  }, [resetShort]);
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.title}>
              Publisher
              <SortArray
                sort={sort}
                type="name"
                setSort={(e) => {
                  updateData(e && `name:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}>Email</TableCell>
            <TableCell className={classes.title}>Longitude ID</TableCell>
            <TableCell className={classes.title}>
              Country
              <SortArray
                sort={sort}
                type="country"
                setSort={(e) => {
                  updateData(e && `country:${e}`), setSort(e);
                }}
                setSortOwn={setSortOwn}
                sortOwn={sortOwn}
              />
            </TableCell>
            <TableCell className={classes.title}></TableCell>
            <TableCell className={classes.title}></TableCell>
            <TableCell className={classes.title}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.line}>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.row}>{row.name}</TableCell>
              <TableCell className={classes.row}>{row.email}</TableCell>
              <TableCell className={classes.row}>{row.longitudeId}</TableCell>
              <TableCell className={classes.row}>
                {row?.country?.name}
              </TableCell>
              <TableCell width="5%" className={classes.row}>
                <Link to={`${urlLng}/publishers/${row.id}`}>
                  <EditIcon />
                </Link>
              </TableCell>
              <TableCell width="5%" className={classes.row}>
                <LockButton
                  locked={row.isBlocked}
                  onClick={() =>
                    onUpdate({ ...row, isBlocked: !row.isBlocked })
                  }
                />
              </TableCell>
              <TableCell width="5%" className={classes.row}>
                <IconButton
                  onClick={() => {
                    onDelete(row), modalDelete(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
