import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { userActions } from "../../bus/user/actions";
import { UserTable } from "../../components/user-table";
import { UserModal } from "../../components/user-modal";
import { DeleteModal } from "../../components/delete-modal";
import {
  Button,
  Snackbar,
  IconButton,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";

import ReplayIcon from "@material-ui/icons/Replay";
import MuiAlert from "@material-ui/lab/Alert";
import {
  getUsers,
  createUser,
  deleteUser,
  blockUser,
  unblockUser,
  updateUser,
} from "../../api/users";
import { getErrorsHandler } from "../../errors/user";
import { RenderPagination } from "../../components/pagination/index";
import { useStyles } from "./hooks/useStyles";
import Search from "@material-ui/icons/Search";

const SORT_DEFAULT = "given_name:ASC";
export const Users = () => {
  const classes = useStyles();
  const history = useHistory();

  const urlParams = new URLSearchParams(history.location.search);
  const query = urlParams.get("query");
  const orderParam = urlParams.get("order");
  const numberPagination = urlParams.get("page");
  const [searchColumn, setSearchColumn] = useState("given_name");
  const [displaySearch, setDisplaySearch] = useState(query || "");

  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoaded, setInitialData] = useState(false);
  const [typeModal, setTypeModal] = useState("Add");
  const [userEdit, setUserEdit] = useState();
  const [userDelete, setUserDelete] = useState({});
  const [error, setError] = useState("");
  const [responseSuccess, setResponseSuccess] = useState("");
  const [updateUserError, setUpdateUserError] = useState("");
  const [resetShort, setResetShort] = useState("");
  const [variableNameError, setVariableNameError] = useState([]);
  const [count, setCount] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [order, setOrder] = useState(orderParam || SORT_DEFAULT);
  const [currentPage, setCurrentPage] = useState(0);
  const [querypaginationNumber, setQueryPaginationNumber] = useState(
    numberPagination || 0
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.setLink("users"));
  }, []);

  const handleChange = (event) => {
    setSearchColumn(event.target.value);
  };

  const reset = () => {
    setSearchColumn("given_name");
    setDisplaySearch("");
    setError("");
    getUsersData("", "", 10, 0, SORT_DEFAULT);
    setOrder(SORT_DEFAULT);
    setResetShort("reset");
  };

  const hadleSearchChange = (e) => {
    setDisplaySearch(e.target.value);
  };

  const openModalType = (type) => {
    setOpenModal(true);
    setTypeModal(type);
  };

  const searchUser = async (value, type) => {
    const array = [];
    if (
      value.length >= 1 &&
      value.length <= 2 &&
      type !== "user_metadata.lang"
    ) {
      setError("The number of characters must be more than 2");
    } else {
      setError("");
      const data = await getUsers(
        value,
        type,
        10,
        Number(querypaginationNumber),
        order
      );

      if (typeof data === "object") {
        data?.users?.forEach((item) => {
          const user = mapUser(item);
          array.push(user);
        });
        setRows(array);
        setCount(data.total);
      } else {
        setError("Internal Server Error");
      }
    }
  };

  const mapUser = (item) => {
    return {
      user_id: item?.user_id,
      givenName: item?.given_name,
      familyName: item?.family_name,
      publisher: item?.app_metadata.organization_name,
      publisherId: item?.app_metadata.organization_id,
      email: item?.email,
      blocked: item?.blocked,
      language:
        item?.user_metadata && item?.user_metadata.lang
          ? item?.user_metadata.lang
          : "gb", // TODO do we need mapping or EN is ok?
    };
  };

  const handleBlockUser = async (data) => {
    const handler = data.blocked ? unblockUser : blockUser;
    const blockedText = data.blocked ? "unblocked" : "blocked";
    const { familyName, givenName } = data;
    const response = await handler(data.user_id);
    if (response) {
      const result = rows.map((item) => {
        if (item.user_id === data.user_id) {
          item.blocked = !item.blocked;
        }
        return item;
      });
      setRows(result);
      setOpenAlertSuccess(true);
      setResponseSuccess(
        `User ${givenName || null} ${
          familyName || null
        } has been ${blockedText}.`
      );
    }
  };

  const handleEditUser = (data) => {
    setUserEdit(data);
    openModalType("Edit");
  };

  const handleDeleteUser = async (data) => {
    setOpenDeleteModal(true);
    setUserDelete(data);
  };

  const onDelete = async (data) => {
    const isDeleted = await deleteUser(data.id);
    if (isDeleted) {
      const result = rows.filter((item) => item.user_id !== data.id);
      setRows(result);
      setOpenDeleteModal(false);
      setOpenAlertSuccess(true);
      setResponseSuccess("User deleted.");
    }
  };

  const onSubmit = async (data, callback) => {
    const index = rows.findIndex((e) => e.user_id === data.user_id);

    const userData = {
      given_name: data.first_name,
      family_name: data.last_name,
      email: data.email,
      organization_id: data.publisher,
      lang: data.language,
      user_id: data.user_id,
    };
    const response = userData.user_id
      ? await updateUser(userData)
      : await createUser(userData);

    if (typeof response !== "number") {
      callback(true);
      setOpenAlertSuccess(true);
      if (userData.user_id) {
        const array = rows;
        array.splice(index, 1, mapUser(response));
        setRows([...array]);
        setUpdateUserError("");
      } else {
        setRows([...rows, mapUser(response)]);
      }
    } else {
      setOpenAlert(true);
      setUpdateUserError(getErrorsHandler(response).title);
      setVariableNameError([...getErrorsHandler(response).name]);
      callback(false);
      console.error("Error creating user:", response);
    }
    getUsersData("", "", 10, 0, SORT_DEFAULT);
    setResponseSuccess(
      `${userData.given_name} has been ${
        userData.user_id ? "updated" : "created"
      }.`
    );
  };

  const getUsersData = async (
    query,
    name = "given_name",
    perPage = 10,
    page = 0,
    orderDefault
  ) => {
    const array = [];
    const data = await getUsers(
      query,
      name,
      perPage,
      Number(page),
      orderDefault ? orderDefault : order
    );
    setQueryPaginationNumber(page);

    data?.users?.forEach((item) => {
      const user = mapUser(item);
      array.push(user);
    });
    if (!isLoaded) {
      setInitialData(true);
    }
    setRows(array);
    setCount(data.total);
  };
  useEffect(() => {
    const params = new URLSearchParams();
    if (isLoaded) {
      params.append("query", displaySearch);
      params.append("page", querypaginationNumber.toString());
      params.append("order", order);
      history.push({ search: params.toString() });
    }
  }, [displaySearch, history, isLoaded, order, querypaginationNumber]);

  useEffect(() => {
    getUsersData("", "", 10, 0, SORT_DEFAULT);
  }, []);

  const onSearch = (e) => {
    if (e.keyCode === 13 || e.key === undefined) {
      searchUser(displaySearch, searchColumn);
    }
  };

  return (
    <section className={classes.section}>
      <div className={classes.heading}>
        <h1 className={classes.title}>All Users</h1>
        <Button
          className={`${classes.buttonAdd} button`}
          variant="contained"
          onClick={() => {
            openModalType("Add");
          }}
        >
          Add User
        </Button>
      </div>
      <div className={classes.searching}>
        <FormControl>
          <InputLabel htmlFor="search-users">Search user</InputLabel>
          <Input
            id="search-users"
            type="text"
            value={displaySearch}
            onChange={hadleSearchChange}
            onKeyDown={onSearch}
            autoFocus={true}
            endAdornment={
              <IconButton>
                <InputAdornment
                  onClick={() => searchUser(displaySearch, searchColumn)}
                  position="end"
                >
                  <Search />
                </InputAdornment>
              </IconButton>
            }
          />
          <FormHelperText className={classes.error}>
            {error ? error : ""}
          </FormHelperText>
        </FormControl>
        <FormControl variant="outlined" className={classes.select}>
          <InputLabel id="demo-simple-select-outlined-label">
            Search by
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="Search by"
            value={searchColumn}
            onChange={handleChange}
          >
            <MenuItem value="given_name">Name</MenuItem>
            <MenuItem value="app_metadata.organization_id">Publisher</MenuItem>
            <MenuItem value="email">Email</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          aria-label="reset"
          className={classes.button}
          onClick={() => reset()}
        >
          <ReplayIcon /> Reset
        </IconButton>
      </div>

      {isLoaded && rows && !rows.length ? (
        <div className={classes.noResults}>No results</div>
      ) : (
        <>
          <UserTable
            data={rows}
            openModalType={(e) => handleEditUser(e)}
            handleBlockUser={(e) => handleBlockUser(e)}
            handleDeleteUser={(e) => handleDeleteUser(e)}
            updateData={(e) => {
              setResetShort(""),
                setOrder(e || SORT_DEFAULT),
                getUsersData(
                  displaySearch,
                  searchColumn,
                  10,
                  Number(querypaginationNumber),
                  e || SORT_DEFAULT
                );
            }}
            resetShort={resetShort}
          />
          <RenderPagination
            limit={null}
            count={count}
            loadData={(value) =>
              getUsersData(displaySearch, searchColumn, 10, value, null)
            }
            currentPage={Number(currentPage)}
            setCurrentPage={(value) => setCurrentPage(value)}
          />
        </>
      )}

      <UserModal
        open={openModal}
        setOpen={setOpenModal}
        type={typeModal}
        data={userEdit}
        onSubmit={(data, type) => onSubmit(data, type)}
        error={updateUserError}
        setError={(e) => setUpdateUserError(e)}
        variableNameError={variableNameError}
        setVariableNameError={(e) => setVariableNameError(e)}
      />
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={userDelete}
        onDelete={(data) => onDelete(data)}
        header="User"
      />

      <Snackbar
        open={openAlertSuccess}
        autoHideDuration={4000}
        onClose={() => setOpenAlertSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="success">{responseSuccess}</MuiAlert>
      </Snackbar>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert severity="error">{error}</MuiAlert>
      </Snackbar>
    </section>
  );
};
